import './styles.scss'
import { FC, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useQuery } from 'react-query'
import { getDashboardSalesByClient } from '../../../api/dashboard'
import moment from 'moment/moment'
import { getNextMonth, getStartMonth } from '../../../utils/format-date'
import FromToDatepicker from '../../../components/from-to-datepicker'
import columnsIndex from './data'
import ClientModal from '../../client-modal'

const SalesByClient: FC = () => {

    const [filters, setFilters] = useState({
        from: moment(getStartMonth(5)).format('YYYY-MM-DD'),
        to: moment(getNextMonth()).format('YYYY-MM-DD'),
    })
    const [modalActive, setModalActive] = useState(false)
    const [modalUserId, setModalUserID] = useState()

    const { data } = useQuery(['sales-by-client', filters], () => getDashboardSalesByClient(filters))

    return (
        <div className="sales-by-client white-card">
            <div className="sales-by-client-head">
                <h4>Sales by client</h4>
                <FromToDatepicker setFilters={setFilters} monthRatio={5} />
            </div>
            <div className="sales-by-client-table">
                <DataTable
                    columns={columnsIndex}
                    data={data?.sales_by_client}
                    responsive
                    pagination={false}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    onRowClicked={(client) => {
                        setModalActive(true)
                        setModalUserID(client.id)
                    }}
                />
            </div>
                <ClientModal active={modalActive} setActive={setModalActive} userId={modalUserId} />
        </div>
    )
}

export default SalesByClient
