import { TableColumn } from 'react-data-table-component'

const columns: TableColumn<any>[] = [
    {
        name: 'FULL NAME',
        cell: (row) => {
            return (
                <div className="logicOfData">
                    {row?.photo ? (
                        <img
                            className="clientsPhoto"
                            style={{ width: '30px', height: '30px' }}
                            src={row?.photo}
                            alt={'client-avatar'}
                        />
                    ) : (
                        <img
                            className="defaultClientsPhoto"
                            src={require('../../assets/images/default_user.png')}
                            alt="img"
                        />
                    )}
                    <p>
                        {row.name} {row.surname}
                    </p>
                </div>
            )
        },
    },
    {
        name: 'ADDRESS',
        selector: (row) => row.address,
    },
    {
        name: 'DATE OF BIRTH',
        sortable: true,
        width: '155px',
        selector: (row) => row.date_of_birth,
    },
]

export default columns
