import './styles.scss'
import React, { FC, useState } from 'react'
import { deleteDocument } from '../../../api/onboarding-documents'
import { showToast } from '../../../utils/toast-function'
import Button from '../../../components/button'
import ModalWrapper from '../../../components/modal-wrapper'
import DocsModalStore from '../../../store/prof-docs-modals'
import { observer } from 'mobx-react-lite'

const DeleteDoc: FC = () => {
    const [isSending, setIsSending] = useState(false)
    const { setShownDeleteModal, isShownDeleteModal, currentDocument } = DocsModalStore

    const deleteDoc = async () => {
        setIsSending(true)
        try {
            await deleteDocument(currentDocument?.id)
            showToast('success', 'Document has been deleted successfully.')
            setShownDeleteModal(false)
        } catch (error: any) {
            showToast('danger', 'Something went wrong. Try again later.')
            throw new Error(error)
        } finally {
            setIsSending(false)
        }
    }
    return (
        <ModalWrapper
            isShown={isShownDeleteModal}
            setIsShown={() => setShownDeleteModal(!isShownDeleteModal)}
            className="doc-modal"
        >
            <div className={'delete-doc-confirmation'}>
                <h4>Are you sure?</h4>
                <p>Do you want to delete this file? This process cannot be undone if you delete</p>
                <div className={'delete-doc-action'}>
                    <Button
                        text={'CANCEL'}
                        onClick={() => setShownDeleteModal(false)}
                        loading={isSending}
                    />
                    <Button
                        text={'delete'}
                        outlined
                        className={'delete-doc-button'}
                        onClick={deleteDoc}
                        loading={isSending}
                    />
                </div>
            </div>
        </ModalWrapper>
    )
}
export default observer(DeleteDoc)
