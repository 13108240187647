// eslint-disable-next-line
const specialRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

export function passwordFormats(password) {
    return [
        {
            title: 'One lowercase character',
            isOkFormat: /(?=.*[a-z])/.test(password),
        },
        {
            title: 'One uppercase character',
            isOkFormat: /(?=.*[A-Z])/.test(password),
        },
        {
            title: 'One number',
            isOkFormat: /(?=.*[0-9])/.test(password),
        },
        {
            title: '8 characters minimum',
            isOkFormat: password.length >= 8,
        },
        {
            title: '16 characters maximum',
            isOkFormat: password.length <= 16,
        },
        {
            title: '1 special character',
            isOkFormat: specialRegex.test(password),
        },
    ]
}
