import './styles.scss'

import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Button from "../button";
import notFoundImg from "../../assets/images/not-found-img.png"

const NotFound: FC = () => {
    return (
        <div className="not-found-layout">
            <div className="error-code">
                <h1>4</h1>
                <img src={notFoundImg} alt="not-found-page" />
                <h1>4</h1>
            </div>
            <h4 className="text-first">Ooooooops!</h4>
            <h5 className="text-second">
                We’re sorry, <br />
                The page you were looking for doesn’t exist
            </h5>
            <Link to={'/'}>
                <Button text={'BACK TO HOME'} />
            </Link>
        </div>
    )
}

export default NotFound
