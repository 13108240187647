import React, { FC } from 'react'
import LocationModalStore from '../../store/location-modal'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'
import { getLocation } from '../../api/maps'
import './styles.scss'
const LocationInput: FC = () => {
    const { isShownModal, setShowLocationModal } = LocationModalStore
    const { data } = useQuery(['location', isShownModal], getLocation)
    return (
        <div className="location-input-group-wrapper">
            <h4>Location</h4>
            <div className="location-input-group" onClick={() => setShowLocationModal(true)}>
                <input value={data?.address || ''} readOnly placeholder="Enter you location" />
                <button type={'button'} className={'choose-button'}>
                    choose on the map
                </button>
            </div>
        </div>
    )
}

export default observer(LocationInput)
