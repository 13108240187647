import { useState } from 'react'
import Icon from '../icon'
import './styles.scss'
import placeholder from '../../assets/images/default_user.png'
import Button from '../button'

const Accordion = (props: any) => {
    const [activeAccordion, setActiveAccordion] = useState(0)
    const handleClick = (id: number) => {
        setActiveAccordion(activeAccordion === id ? 0 : id)
    }
    return (
        <div>
            {props.name.results.map((elem: any) => (
                <div
                    onClick={() => handleClick(elem.id)}
                    className="client-accordion-element cursor-pointer"
                    key={elem.id}
                >
                    <div className="accordion-title" onClick={() => setActiveAccordion(elem.id)}>
                        <div className="accordion-title">
                            <img
                                className="clientsPhoto"
                                src={elem.photo || placeholder}
                                alt={'clients-avatar'}
                            />
                            <p>
                                {elem.name} {elem.surname}
                            </p>
                        </div>
                        <Icon
                            className={activeAccordion === elem.id ? 'icon-rotate' : ''}
                            name={`chevron-down${activeAccordion === elem.id ? '-black' : '-grey'}`}
                        />
                    </div>
                    <div
                        className={`accordion-content ${
                            activeAccordion === elem.id ? 'active-accordion' : ''
                        }`}
                    >
                        <div className="element">
                            <span>Address</span>
                            <p>{elem.address}</p>
                        </div>
                        <div className="element">
                            <span>Date of birth</span>
                            <p>{elem.date_of_birth}</p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <div className="accordion-action">
                                <Button
                                    text={'VIEW DETAILS'}
                                    onClick={() => {
                                        props.toggleModal(true)
                                        props.id(elem.id)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Accordion
