
export const getNextMonth = () => {
    const date = new Date()
    return (new Date(date.getFullYear(), date.getMonth() + 1, 1))
}
export const getPrevMonth = (month) => {

    return (new Date(month.getFullYear(), month.getMonth() - 1, 1))
}
export const getStartMonth = (ratio = 0) => {
    const date = new Date()
    return (new Date(date.getFullYear(), date.getMonth() - ratio, 1))
}