import { FC, useEffect } from 'react'
import Icon from '../icon'
import { DropDownTimeProps } from './interface'
import './styles.scss'
import { observer } from 'mobx-react-lite'
import WorkingHoursSelect from '../../store/working-hours-select'

type Props = DropDownTimeProps

const DropDownTime: FC<Props> = ({ schedule, selectKey, onChange, value, zIndex = 1 }) => {
    const { workingHoursSelect, setCurrentSelect } = WorkingHoursSelect

    const isOpen = workingHoursSelect === selectKey
    const correctTime = schedule.includes(value)
    const openFn = () => {
        setTimeout(() => {
            setCurrentSelect(isOpen ? '' : selectKey)
        }, 1)
    }
    function select(option: string) {
        onChange(option)
        setCurrentSelect('')
    }

    useEffect(() => {
        if (!correctTime && schedule.length) {
            onChange(schedule[0])
        }
    }, [correctTime, schedule?.length])

    return (
        <div className="dropDown-time" style={{ zIndex: zIndex }} onClick={openFn}>
            <div className="selected">
                <span>{value}</span>
                <Icon
                    className={isOpen ? 'rotate' : ''}
                    name="arrow-dropdown"
                    width={24}
                    height={24}
                />
            </div>
            <div className={`options ${isOpen && 'opened'}`}>
                {schedule.map((time: any) => {
                    return (
                        <span
                            key={time}
                            className={value === time ? 'active-option' : ''}
                            onClick={() => select(time)}
                        >
                            {time}
                        </span>
                    )
                })}
            </div>
        </div>
    )
}

export default observer(DropDownTime)
