import './styles.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import React, { FC } from 'react'
import Icon from '../icon'
import { NotificationCardProps } from './interface'
import Button from '../button'
import { useNavigate } from 'react-router-dom'
import { getNotificationMark } from '../../api/notifications'
import moment from 'moment'
import NotificationsCountStore from '../../store/notifications'
import { notificationTypesData } from '../../utils/notification-types-data'

type Props = NotificationCardProps

const NotificationCard: FC<Props> = ({ detailed, data }) => {
    const navigate = useNavigate()
    const { triggerCount } = NotificationsCountStore
    const { text, path, description } = notificationTypesData[data.type]

    const isReadFn = (e: any) => {
        e.stopPropagation()
        if (!data?.is_read) {
            getNotificationMark(data.id).then(() => {
                triggerCount()
            })
        }
    }
    const iconSize = window.matchMedia('(max-width: 768px)').matches ? 'small' : 'large'

    return (
        <div
            className="notification-card"
            aria-disabled={detailed}
            onClick={() => !detailed && navigate(`/notifications/${data?.id}`)}
        >
            <div className="notification-top">
                <div className="notification-title">
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Icon name={`${data.type}-${iconSize}`} />
                        <h3>{text}</h3>
                    </div>

                    {!detailed && (
                        <div
                            className={`circle ${data.is_read && 'inactive'}`}
                            onClick={data.is_read ? undefined : isReadFn}
                        />
                    )}
                </div>

                {detailed && (
                    <div className="notification-hours">
                        {moment.utc(data?.created_at).fromNow()}
                    </div>
                )}
            </div>
            <div className="notification-content">
                {detailed ? (
                    <div className={'full-text_box'}>
                        <p>Dear {data?.professional || data?.room},</p>
                        <p>{description}</p>
                        {data?.message && <p>"{data.message}"</p>}
                        <p className={'linked'}>
                            Best regards,
                            <br />
                            GlowApp
                        </p>
                    </div>
                ) : (
                    <p className={`notification-text`}>{data.message}</p>
                )}
            </div>
            {detailed ? (
                !!path && (
                    <Button
                        onClick={() =>
                            navigate(
                                path +
                                    (data?.type === 'reply' || data?.type === 'review'
                                        ? data?.review
                                        : data?.booking)
                            )
                        }
                        text="view Details"
                        className="details-btn"
                    />
                )
            ) : (
                <div className="notification-hours">{moment.utc(data?.created_at).fromNow()}</div>
            )}
        </div>
    )
}

export default NotificationCard
