import './styles.scss'
import { FC } from 'react'
import { editDocument } from '../../../api/onboarding-documents'
import { showToast } from '../../../utils/toast-function'
import Button from '../../../components/button'
import ModalWrapper from '../../../components/modal-wrapper'
import DocsModalStore from '../../../store/prof-docs-modals'
import { observer } from 'mobx-react-lite'

const PublishDoc: FC = () => {
    const { setShownPublishModal, isShownPublishModal, currentDocument } = DocsModalStore

    const handlePublish = async () => {
        const formData = new FormData()
        // @ts-ignore
        formData.append('published', !currentDocument?.published)
        try {
            await editDocument(currentDocument?.id, formData)
            showToast('success', 'File has been changed successfully')
        } catch (error: any) {
            throw new Error(error)
        } finally {
            setShownPublishModal(false)
        }
    }

    return (
        <ModalWrapper isShown={isShownPublishModal} setIsShown={() => setShownPublishModal(false)}>
            <div className="publish-confirmation">
                <h4>Make the document {currentDocument?.published ? 'unpublic' : 'public'}</h4>

                <p>
                    {currentDocument?.published
                        ? 'If you make this document unpublic, it will not be shown to everyone who views your profile any more'
                        : 'If you make this document public, it will be shown to everyone who views your profile.'}
                </p>
                <div className="publish-action">
                    <Button text={'cancel'} outlined onClick={() => setShownPublishModal(false)} />
                    <Button
                        text={currentDocument?.published ? 'unpublish' : 'publish'}
                        onClick={handlePublish}
                    />
                </div>
            </div>
        </ModalWrapper>
    )
}
export default observer(PublishDoc)
