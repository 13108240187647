import { request } from './api'
import axios from 'axios'
import { user_role } from '../utils/user-type'

export async function getAddresses(lat: number, lng: number) {
    return await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env['REACT_APP_GOOGLE_MAP_KEY']}`,
        { query: `latlng${lat},${lng}` } as any
    )
}

export async function setLocation(
    location: {
        address: string
        lat: number
        lon: number
    },
    role: string
) {
    const full_role = {
        prof: 'professionals',
        room: 'rooms',
    } as any

    return request(`auth/${full_role[role]}/update_location/`, 'POST', location)
}
export async function getLocation() {
    const full_role = {
        prof: 'professionals',
        room: 'rooms',
    } as any
    return request(`auth/${full_role[user_role as string]}/update_location/`, 'GET')
}
