import './styles.scss'
import notFound from '../../assets/images/not-found.svg'
import { NoResultFoundProps } from './interface'
import { FC } from 'react'
import Button from '../button'
import { Link } from 'react-router-dom'

type Props = NoResultFoundProps
const NoResultFound: FC<Props> = ({ forWorksPage = false }) => {
    return (
        <div className="not-found">
            <div className="not-found__img">
                <img src={notFound} alt={'img'} />
            </div>
            <h4>{forWorksPage ? 'No works yet' : 'No results found'}</h4>
            <span>
                {forWorksPage
                    ? 'Fortunately, it is easy to add new ones!'
                    : 'Try changing filters'}
            </span>
            {forWorksPage ? (
                <Link to="/add-work">
                    <Button text={'add work'} />
                </Link>
            ) : null}
        </div>
    )
}
export default NoResultFound
