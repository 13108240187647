import HomeIcon from '../components/menu-icons/home-icon'
import AccountIcon from '../components/menu-icons/account-icon'
import ClientsIcon from '../components/menu-icons/clients-icon'
import CalendarIcon from '../components/menu-icons/calendar-icon'
import TreatmentsIcon from '../components/menu-icons/treatments-icon'
import WorksIcon from '../components/menu-icons/works-icon'
import ReviewsIcon from '../components/menu-icons/reviews-icon'
import { user_role } from './user-type'

export const NavDataFirstBlock: any = [
    {
        title: 'Home',
        link: '/',
        icon: <HomeIcon />,
        active: true,
    },
    {
        title: 'Account',
        link: user_role === 'room' ? '/room/account' : '/account',
        icon: <AccountIcon />,
        active: true,
    },
]

export const NavDataSecondBlock: any = [
    {
        title: 'Clients List',
        link: '/clients-list',
        icon: <ClientsIcon />,
        active: true,
    },
    {
        title: 'Calendar',
        link: '/calendar',
        icon: <CalendarIcon />,
        active: true,
    },
]

export const NavDataThirdBlock: any = [
    {
        title: 'Tariffs',
        link: '/tariffs',
        icon: <TreatmentsIcon />,
        active: user_role === 'room',
    },
    {
        title: 'Team',
        link: '/team',
        icon: <AccountIcon />,
        active: user_role === 'room',
    },
    {
        title: 'Treatments',
        link: '/treatments',
        icon: <TreatmentsIcon />,
        active: user_role === 'prof',
    },
    {
        title: 'My works',
        link: '/my-works',
        icon: <WorksIcon />,
        active: user_role === 'prof',
    },
    {
        title: 'Reviews',
        link: '/reviews',
        icon: <ReviewsIcon />,
        active: true,
    },
]

export const sidebar_blocks = [NavDataFirstBlock, NavDataSecondBlock, NavDataThirdBlock]
