import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { getDashboardActiveBookings } from '../../api/dashboard'
import Button from '../button'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import Loader from '../loader/loader'
import { user_role } from '../../utils/user-type'
import LocationModalStore from '../../store/location-modal'
const CompleteStagesBox: FC = () => {
    const { isShownModal, setShowLocationModal } = LocationModalStore
    const navigate = useNavigate()
    const isRoom = user_role === 'room'

    const { data, isLoading } = useQuery(
        ['active-bookings', isShownModal],
        getDashboardActiveBookings,
        {
            cacheTime: 0,
        }
    )
    const stages = [
        {
            completed: !isRoom || data?.have_amenities,
            text: 'complete your business profile',

            buttonText: 'go to profile',
            action: () => navigate(`/room/account/`),
        },
        {
            completed: data?.have_location,
            buttonText: 'Indicate location',
            text: 'indicate your location',
            action: () => setShowLocationModal(true),
        },
        {
            completed: data?.have_schedule,
            buttonText: 'set up schedule',
            text: 'set up the schedule',
            action: () => navigate('/calendar/working-hours/'),
        },
        {
            completed: data?.have_services,
            buttonText: isRoom ? 'Add tariff' : 'Add treatment',
            text: `publish at least one ${isRoom ? 'tariff' : 'treatment'}`,
            action: () => navigate(isRoom ? '/add-tariff' : '/add-treatment'),
        },
        {
            completed: !data?.stripe_account_data?.onboarding_link,
            buttonText: 'go to stripe',
            text: `${!data?.have_services ? 'and ' : ''}complete payment onboarding`,
            action: () => window.open(data?.stripe_account_data?.onboarding_link, '_blank'),
        },
    ]

    const currentStage = stages.find((stage) => !stage.completed)

    const currentText = stages
        .filter((s) => !s.completed)
        .map((s) => s.text)
        .join(', ')

    return (
        <div className={`dashboard-profile white-card ${currentStage ? 'orange-bg' : 'white-bg'}`}>
            {isLoading && (
                <div className={'dashboard-profile-loader'}>
                    <Loader local />
                </div>
            )}
            <>
                <div className="dashboard-profile-info">
                    <div className={`dashboard-profile-info-top-box`}>
                        <h4>
                            Hello{(data?.title || data?.name) && ','} {data?.title || data?.name}
                        </h4>
                    </div>
                    <div className="dashboard-profile-info-bottom-box">
                        {currentStage ? (
                            <span>To start receiving bookings, {currentText}</span>
                        ) : (
                            <b>{data?.active_bookings} bookings</b>
                        )}

                        <div>
                            <Button
                                text={currentStage?.buttonText || 'View Calendar'}
                                className="view-calendar-btn"
                                onClick={currentStage?.action || (() => navigate('/calendar'))}
                            />
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default CompleteStagesBox
