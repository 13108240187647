import './styles.scss'
import { FC } from 'react'
import { LoaderProps } from './interface'

type Props = LoaderProps
const Loader: FC<Props> = ({ fullHeight, local, transparent = false }) => {
    return (
        <div
            className={`loader ${fullHeight && 'full'} ${local && 'local'} ${transparent && 'transparent'}`}
            style={fullHeight ? { height: '100vh' } : {}}
        >
            <div id="container">
                <svg viewBox="0 0 100 100">
                    <circle id="spinner" cx="50" cy="50" r="45" />
                </svg>
            </div>
        </div>
    )
}
export default Loader
