import { user_role } from '../utils/user-type'
import { request } from './api'

export async function getOnboardingDocsList(filters) { //
    return request(`${user_role}/onboarding-documents/`, 'GET', null, null, filters)
}

export async function getOnboardingDocsCategoryList() { //
    return request(`${user_role}/onboarding-documents/doc-categories/`, 'GET')
}

export async function createDocument(formData) {
    return request(`${user_role}/onboarding-documents/create/`, 'POST', formData)
}

export async function deleteDocument(id) {
    return request(`${user_role}/onboarding-documents/delete/${id}/`, 'DELETE')
}

export async function editDocument(id, formData) {
    return request(`${user_role}/onboarding-documents/update/${id}/`, 'PUT', formData)
}

export async function profileVerification(type, uid, token, data) {
    return request(`${type}/approve/${uid}/${token}/`, 'POST', data)
}
export async function getOnboardingDocuments(type, uid, token) {
    return request(`${type}/approve/${uid}/${token}/`, 'GET')
}