import { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import DropDown from '../../components/drop-down'
import Loader from '../../components/loader/loader'
import { createWork, editWork, getTreatmentNames, getWorkDetail } from '../../api/prof-works'
import PhotoPicker from '../../modules/photo-picker'
import { AddWorkProps } from './interface'
import './styles.scss'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../components/button'
import { filterNames, setClearPhotos } from '../../utils/for-add-work'
import { showToast } from '../../utils/toast-function'

type Props = AddWorkProps

const AddWork: FC<Props> = () => {
    const [descriptionValue, setDescriptionValue] = useState('')
    const [sending, setIsSending] = useState(false)
    const [workData, setWorkData] = useState({
        treatment: null as any,
        work_description: null as any,
        photos: [],
    })

    const { id } = useParams()
    const navigate = useNavigate()

    const { data: treatmentNames, isLoading: treatmentLoading } = useQuery(
        ['treatment_names'],
        () => getTreatmentNames({ category: null })
    )
    const { data, isLoading } = useQuery(['work_detail', id], () => getWorkDetail(id), {
        enabled: !!id,
        retry: false,
        cacheTime: 0,
    })

    function setSubCategory(id: number | undefined) {
        setWorkData({ ...workData, treatment: id })
    }

    async function sendNewWork() {
        let res
        const newWork = { ...workData, work_description: descriptionValue }

        if (!descriptionValue) {
            showToast('danger', 'Description is required.')
        } else if (!newWork.photos.length) {
            showToast('danger', 'Upload at least one photo.')
        } else if (!treatmentNames.length) {
            showToast('danger', 'You need to add at least 1 treatment.')
        } else {
            setIsSending(true)
            try {
                id ? (res = await editWork(id, newWork)) : (res = await createWork(newWork))
                navigate('/my-works')
                setTimeout(() => showToast('success', 'Changes have been saved'), 500)

                return res
            } catch (error: any) {
                console.dir(error)
                showToast('danger', 'Something went wrong')
            } finally {
                setIsSending(false)
            }
        }
    }

    useEffect(() => {
        if (data) {
            setDescriptionValue(data.work_description)
            setWorkData({
                photos: setClearPhotos(data.photos),
                work_description: data.work_description,
                treatment: data.treatment.id,
            })
        } else if (treatmentNames) {
            setWorkData({ ...workData, treatment: treatmentNames[0]?.id })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, treatmentLoading])

    useEffect(() => window.scrollTo(0, 0), [])

    return isLoading || treatmentLoading ? (
        <Loader />
    ) : (
        <section className="add-work container">
            <h2>{id ? 'Edit' : 'Add'} work</h2>
            <div className="add-work_template">
                <div className="filters">
                    <div className="category">
                        <h4>Treatment Name </h4>
                        <span>Choose a name from the list </span>
                        <DropDown
                            forAddWork
                            categories={filterNames(treatmentNames)}
                            setCategory={setSubCategory}
                            defaultCategory={
                                data?.treatment?.subcategory?.title ||
                                treatmentNames[0]?.subcategory?.title
                            }
                            defaultName={
                                <span style={{ color: 'grey' }}>Choose treatment name</span>
                            }
                        />
                    </div>
                </div>
                <div className="description">
                    <h4>Description</h4>
                    <textarea
                        maxLength={100}
                        value={descriptionValue}
                        placeholder={'Type here a description'}
                        onChange={(e) => setDescriptionValue(e.target.value)}
                    />
                    <span>{descriptionValue.length}/100</span>
                </div>
                <div className="photos">
                    <h4>Add photos</h4>
                    <span>You can add maximum 5 photos (the photo ratio of 3:2 is required)</span>
                    <form>
                        <PhotoPicker
                            photos={data?.photos || []}
                            dataPhotos={workData}
                            setDataPhotos={setWorkData}
                        />
                    </form>
                </div>
            </div>
            <div className="buttons">
                <Button
                    text={'cancel'}
                    outlined
                    loading={sending}
                    onClick={() => navigate('/my-works')}
                />
                <Button text={id ? 'save' : 'ADD work'} onClick={sendNewWork} loading={sending} />
            </div>
        </section>
    )
}

export default AddWork
