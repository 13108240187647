import React, { FC, useState } from 'react'
import './styles.scss'
import NotificationDropdownCard from '../topbar-notification-card'
import Button from '../../components/button'
import { useNavigate } from 'react-router-dom'
import { getNotificationMarkAll } from '../../api/notifications'
import { NotificationDropdownProps } from './interface'
import NotificationsCountStore from '../../store/notifications'

type Props = NotificationDropdownProps

const NotificationDropdown: FC<Props> = ({ notifications }) => {
    const [isAlertChecked, setAlertChecked] = useState(
        !!JSON.parse(localStorage.getItem('alertChecked') as string)
    )
    const navigate = useNavigate()

    const allAsRead = () => {
        getNotificationMarkAll().then(() => NotificationsCountStore.triggerCount())
    }

    const handleSwitch = (evt: any) => {
        evt.stopPropagation()
    }
    return (
        <>
            <div className={'notification-dropdown-container'}>
                <div className={'notification-dropdown-container__header'}>
                    <h6>Notification</h6>
                    {!!notifications?.unread_count && (
                        <div>
                            <span>
                                {notifications?.unread_count < 10
                                    ? notifications?.unread_count
                                    : '9+'}{' '}
                                new
                            </span>
                        </div>
                    )}
                </div>

                {notifications?.notifications?.length
                    ? notifications?.notifications?.map((notification: any) => (
                          <NotificationDropdownCard key={notification.id} data={notification} />
                      ))
                    : 'No notifications yet'}

                <div
                    className={'notification-dropdown-container__alert-box'}
                    onClick={(e) => handleSwitch(e)}
                >
                    <div className="notification-dropdown-container__alert">
                        <label className="switch">
                            <input
                                type={'checkbox'}
                                checked={isAlertChecked}
                                onChange={(event: any) => {
                                    setAlertChecked(event.target.checked)
                                    localStorage.setItem(
                                        'alertChecked',
                                        event.target.checked.toString()
                                    )
                                }}
                                className="slider"
                            />
                            <span className="slider"></span>
                        </label>
                        <span className="txt">Notification Alert</span>
                    </div>
                    <span className="mark-as-read" onClick={allAsRead}>
                        Mark all as read
                    </span>
                </div>
                <Button
                    text={'view all notifications'}
                    className={'notification-dropdown-container__btn'}
                    onClick={() => navigate('/notifications')}
                />
            </div>
        </>
    )
}

export default NotificationDropdown
