import { FC, useEffect } from 'react'
import './styles.scss'
import Statistics from '../../modules/dashboards/statistics'
import SalesSummary from '../../modules/dashboards/sales-summary'
import SalesByService from '../../modules/dashboards/sales-by-service'
import SalesByClient from '../../modules/dashboards/sales-by-client'
import NoApprovePage from '../../components/no-approve-page'
import CompleteStagesBox from '../../components/complete-stages-box'
const DashboardPage: FC = () => {
    const isApproved = JSON.parse(localStorage.getItem('approved') as string)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return isApproved ? (
        <div className="dashboard-page container">
            <div className="head-box">
                <CompleteStagesBox />
                <Statistics />
            </div>
            <SalesSummary />
            <div className="bottom-box">
                <SalesByService />
                <SalesByClient />
            </div>
        </div>
    ) : (
        <NoApprovePage />
    )
}

export default DashboardPage
