import React, { FC, useEffect, useState } from 'react'
import { iTextAreaCustom } from './interface'
import './styles.scss'
type Props = iTextAreaCustom
const TextareaCustom: FC<Props> = ({
    error,
    defaultValue = '',
    register,
    register_key,
    onChange,
    required = false,
    rows = 3,
    maxLength = 100,
    className,
}) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        defaultValue && setValue(defaultValue)
    }, [defaultValue])
    return (
        <div className={'custom_textarea_box'}>
            <textarea
                className={`custom_textarea ${
                    (value?.length > maxLength || error) && 'error_textarea'
                } ${className}`}
                rows={rows}
                maxLength={maxLength}
                placeholder={'Enter your description'}
                {...register(register_key, {
                    required: required,
                    onChange: (e: any) => {
                        setValue(e.target.value)
                        onChange && onChange()
                    },
                })}
            />
            <div className="d-flex justify-content-between gap-10">
                <span style={{ fontSize: 12, color: 'var(--palette-secondary-red)' }}>
                    {typeof error === 'string' ? error : ''}
                </span>
                <span style={{ fontSize: 12, color: '#848286' }}>
                    {value?.length || 0}/{maxLength}
                </span>
            </div>
        </div>
    )
}

export default TextareaCustom
