import './styles.scss'
import OnboardingNav from '../../components/onboarding-process-nav'
import Input from '../../components/input'
import { useForm } from 'react-hook-form'
import Button from '../../components/button'
import React, { useEffect, useState } from 'react'
import OnboardingProcessDocuments from '../../modules/onboarding-process-documents'
import { showToast } from '../../utils/toast-function'
import { useQuery } from 'react-query'
import { getProfessionalProfile, updateProfessionalProfile } from '../../api/profiles'
import ModalWrapper from '../../components/modal-wrapper'
import OnboardingModal from '../../components/onboarding-process-modal'
import Icon from '../../components/icon'
import { observer } from 'mobx-react-lite'
import LocationInput from '../../components/location-input'
import TextareaCustom from '../../components/Textarea-custom/text-area-custom'

const OnboardingProcess = () => {
    const [navState, setNavState] = useState(false)
    const [showModal, setShowModal] = useState(true)

    const profileId = localStorage.userID && Number(localStorage.userID)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
    } = useForm()

    const { data: profile } = useQuery(['profileInfo'], () => getProfessionalProfile(profileId))

    const onSubmit = async (data: any) => {
        try {
            await updateProfessionalProfile(profileId, data)
            showToast('success', 'Changes have been saved')
            setNavState(true)
        } catch (error: any) {
            if (error.response?.data?.email?.length) {
                setError('email', {
                    type: 'custom',
                    message: error.response.data.email[0],
                })
            } else {
                setError('name', {
                    type: 'custom',
                    message: 'Something went wrong. Try again later',
                })
            }
        }
    }

    useEffect(() => {
        if (profile) {
            delete profile.professional_profile.photo
            delete profile.professional_profile.user
            delete profile.professional_profile.date_of_birth
            reset(profile)
        }
    }, [profile])

    return (
        <>
            <ModalWrapper isShown={showModal} setIsShown={setShowModal}>
                <OnboardingModal
                    name={profile?.professional_profile?.name}
                    surname={profile?.professional_profile?.surname}
                    setShowModal={() => setShowModal(!showModal)}
                />
            </ModalWrapper>
            <div className={'onboarding-header'}>
                <div className={'container onboarding-header-logo'}>
                    <Icon name={'footer-logo'} />
                </div>
            </div>
            <div className="container onboarding-process">
                <OnboardingNav navState={navState} setNav={() => setNavState(false)} />
                <div hidden={navState}>
                    <h1>Please provide information about you </h1>
                    <form className="onboarding-process-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form">
                            <Input
                                label="Legal Name*"
                                placeholder="Enter your legal name"
                                id={'professional_profile.name'}
                                useForm={register('professional_profile.name', {
                                    required: 'This field is required',
                                })}
                                error={errors?.professional_profile?.name?.message}
                            />
                            <Input
                                label="Email address*"
                                placeholder="Enter your email"
                                readOnly
                                useForm={register('email')}
                                error={errors?.email?.message}
                            />
                            <Input
                                label="Legal Surname*"
                                placeholder="Enter your legal surname"
                                useForm={register('professional_profile.surname', {
                                    required: 'This field is required',
                                })}
                                error={errors?.professional_profile?.surname?.message}
                            />
                            <Input
                                label="Phone number*"
                                placeholder="Enter your phone number"
                                className="basic-info-input"
                                type="number"
                                useForm={register('professional_profile.phone', {
                                    required: 'This field is required',
                                    valueAsNumber: true,
                                })}
                                error={errors?.professional_profile?.phone?.message}
                            />
                            <LocationInput />
                        </div>
                        <div className="form__textarea">
                            <h4>Description</h4>
                            <span>
                                You can write about your professional experience in beauty industry,
                                or your skills. This will be shown in your profile to potential
                                clients.{' '}
                            </span>
                            <TextareaCustom
                                defaultValue={profile?.professional_profile?.description}
                                register={register}
                                register_key={'professional_profile.description'}
                                maxLength={1000}
                            />
                        </div>
                        <Button text={'next'} />
                    </form>
                </div>
                <div hidden={!navState}>
                    <OnboardingProcessDocuments profile={profile?.professional_profile} />
                </div>
            </div>
        </>
    )
}
export default observer(OnboardingProcess)
