import './styles.scss'
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import Icon from '../../components/icon'
import { Document } from './interface'
import { closeByClick } from '../../utils/closing-dropdown-func'
import DocsModalStore from '../../store/prof-docs-modals'

type props = Document
const DropdownAction: FC<props> = ({ data }) => {
    const { setShownPublishModal, setShownDeleteModal, setShownCreateModal } = DocsModalStore

    const [isShown, setShow] = useState(false)
    const ref = useRef() as MutableRefObject<HTMLInputElement>
    const handleShowDropDown = () => {
        setShow(!isShown)
    }
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShow(false)
            }
        }
        closeByClick(checkIfClickedOutside)
    }, [ref])

    return (
        <div ref={ref} className={'DropdownAction'}>
            <button className="action-btn" onClick={handleShowDropDown}>
                <Icon name={'action'} />
            </button>
            {isShown && (
                <div className="action-dropdown-container">
                    {data?.category?.is_publishable && (
                        <span
                            className={'dropDown-btn'}
                            onClick={() => setShownPublishModal(true, data)}
                        >
                            {data?.published ? 'Unpublish' : 'Publish'}
                        </span>
                    )}
                    <span
                        className={'dropDown-btn'}
                        onClick={() => setShownCreateModal(true, data)}
                    >
                        Edit
                    </span>
                    {!data?.category?.issue_expiry_dates && (
                        <span
                            className={'dropDown-btn'}
                            onClick={() => setShownDeleteModal(true, data)}
                        >
                            Delete
                        </span>
                    )}
                </div>
            )}
        </div>
    )
}
export default DropdownAction
