import './styles.scss'

import React, { FC, useEffect } from 'react'
import NotificationCard from '../../components/notifications-card'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
    getNotificationDetails,
    getNotificationMark,
} from '../../api/notifications'
import Loader from '../../components/loader/loader'
import NotificationsCountStore from '../../store/notifications'
import BackButton from '../../components/back-arrow-btn'

const NotificationDetails: FC = () => {
    const { id } = useParams()
    const { data, isLoading } = useQuery(
        ['notificationDetail', id],
        () => getNotificationDetails(id)
    )
    const { triggerCount } = NotificationsCountStore

    useEffect(() => {
        const media = window.matchMedia('(max-width: 768px)')
        if (media.matches) {
            window.scrollTo(0, 60)
        } else {
            window.scrollTo(0, 90)
        }
       getNotificationMark(id).then(triggerCount)
    }, [])
    return (
        <div className="notifications-wrapper container">
            <BackButton>
                <h2>Notifications</h2>
            </BackButton>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="notification-cards">
                    <NotificationCard data={data} detailed />
                </div>
            )}
        </div>
    )
}

export default NotificationDetails
