import './styles/App.scss'
import { Routes, Route } from 'react-router-dom'
import { routing } from './routing'
import React from 'react'
import PageWrapper from './layouts/page-wrapper'
import { routingNoLayout } from './routing/no-layout'
import { user_role } from './utils/user-type'
import ProtectedRoute from './utils/protected'

type types = keyof typeof routing
type noLayoutTypes = keyof typeof routingNoLayout

const App = () => {
    return (
        <>
            <Routes>
                <Route element={<PageWrapper/>}>
                    {Object.keys(routing).map((key: string) => {
                        const rout = routing[key as types]
                        if (rout.role === user_role || rout.role === 'all') {
                            return (
                                <Route
                                    key={key}
                                    path={rout.path}
                                    element={rout.protected ? <ProtectedRoute /> : rout.element}
                                >
                                    {rout.protected ? (
                                        <Route path={rout.path} element={rout.element} />
                                    ) : null}
                                </Route>
                            )} else return null

                    })}
                </Route>
                {Object.keys(routingNoLayout).map((noLayoutKey: string) => {
                    const rout = routingNoLayout[noLayoutKey as noLayoutTypes]
                    if (rout.role === user_role || rout.role === 'all') {
                        return (
                            <Route
                                key={noLayoutKey}
                                path={rout.path}
                                element={rout.protected ? <ProtectedRoute /> : rout.element}
                            >
                                {rout.protected ? (
                                    <Route path={rout.path} element={rout.element} />
                                ) : null}
                            </Route>
                        )} else return null
                })}
            </Routes>
        </>
    )
}

export default App
