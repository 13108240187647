import React, { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Loader from '../../components/loader/loader'
import {
    createService,
    getServiceDetail,
    updateService,
    getTariffTypes,
} from '../../api/treatments-and-tariffs'
import './styles.scss'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../components/button'
import Icon from '../../components/icon'
import Input from '../../components/input'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { showToast } from '../../utils/toast-function'
import TextareaCustom from '../../components/Textarea-custom/text-area-custom'
import Amenity from '../../modules/treatment-room/amenity'
import Tooltip from '../../components/tooltip'

const AddRoomType: FC = () => {
    const [percents, setPercents] = useState(0)
    const [active, setActive] = useState(false)
    const [sending, setIsSending] = useState(false)

    const { tariffId } = useParams()
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
        getValues,
        reset,
        watch,
        trigger,
        clearErrors,
    } = useForm()
    register('categories')
    const date_now = moment().format('YYYY-MM-DD')

    const { data: tariffDetail, isLoading: detailsIsLoading } = useQuery(
        ['tariffs_detail', tariffId],
        () => getServiceDetail(tariffId),
        { enabled: !!tariffId, cacheTime: 0 }
    )

    const { data: categories, isLoading: catLoading } = useQuery(
        ['allCategories'],
        () =>
            getTariffTypes().then((result: any) => {
                result?.forEach((item: any) => {
                    item.checked = tariffDetail?.categories?.some((cat: any) => cat.id === item.id)
                })
                return result
            }),
        {
            cacheTime: 0,
            enabled: !tariffId || !!tariffDetail,
        }
    )
    async function onSubmit(data: any) {
        console.log('iska data', data)
        if (!data?.categories) {
            showToast('danger', 'Field type is required!')
            return null
        }

        data.room_deal.start_date = date_now
        setIsSending(true)

        if (!data.room_deal) {
            data.room_deal = null
        }

        if (tariffDetail?.room_deal && !active) {
            data.room_deal.active = false
        }
        if (active) {
            data.room_deal.active = active
        }

        try {
            tariffId ? await updateService(tariffId, data) : await createService(data)
            setTimeout(() => {
                showToast('success', 'Saved successfully!')
            }, 50)
            navigate(-1)
        } catch (e: any) {
            console.dir(e)
            if (e.response?.data?.type) {
                showToast('danger', 'Field category is required!')
            }
        } finally {
            setIsSending(false)
        }
    }

    function getPercents(e: any) {
        const price = Number(getValues('price'))
        const discount = Number(e.target.value)
        const percent = Math.floor(100 - (discount / price) * 100)
        if (percent >= 0) {
            setPercents(percent)
            clearErrors('room_deal.discount_price')
        } else {
            setPercents(0)
            setError('room_deal.discount_price', {
                type: 'custom',
                message: 'Discount price should be less than original price.',
            })
        }
    }
    const activeCategories = watch('categories')
    const handleChecked = (id: number) => {
        setValue(
            'categories',
            activeCategories.includes(id)
                ? activeCategories.filter((current: any) => current !== id)
                : [...activeCategories, id]
        )
    }
    useEffect(() => {
        if (tariffDetail) {
            reset(tariffDetail)
            setValue(
                'categories',
                tariffDetail?.categories?.map((item: any) => item?.id)
            )
            if (tariffDetail.room_deal) {
                const end = moment(tariffDetail.room_deal.end_date, 'DD/MM/YYYY').format(
                    'YYYY-MM-DD'
                )

                setValue('room_deal.end_date', end)
                setPercents(
                    Math.floor(
                        100 - (tariffDetail.room_deal.discount_price / tariffDetail.price) * 100
                    )
                )
            }
            setActive(!!tariffDetail.room_deal?.active)
            trigger()
        } else {
            setValue('categories', [])
        }
    }, [tariffId, tariffDetail?.id])

    return (
        <>
            {catLoading || detailsIsLoading ? (
                <Loader />
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section className="add-tariff container">
                        <h2>{tariffId ? 'Edit' : 'Add'} Tariff</h2>
                        <div className="add-tariff_template">
                            <div className="tariff-name-container">
                                <div className="tariff_name">
                                    <h4>Tariff name*</h4>

                                    <p>
                                        You can type any name you think describes the room tariff
                                        better
                                    </p>
                                    <Input
                                        placeholder="Type a name"
                                        useForm={register('name', { required: true })}
                                        error={
                                            errors.name
                                                ? errors.name?.message || 'Required field'
                                                : null
                                        }
                                    />
                                </div>
                                <div className="categories_list">
                                    <h4>Treatment category*</h4>
                                    <p>Choose treatment categories you can provide in the room</p>
                                    <div className={'categories-box'}>
                                        {categories?.map(
                                            (val: {
                                                id: number
                                                title: string
                                                checked: boolean
                                            }) => (
                                                <div key={val.id}>
                                                    <Amenity
                                                        data={val}
                                                        handleChecked={handleChecked}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="sub-filters">
                                <div className="sub-category">
                                    <h4>
                                        Price <span>(per hour)</span>
                                    </h4>
                                    <div className="price-input-container">
                                        <div className="price-input">
                                            <div className="price-input__icon">
                                                <Icon name="input-price" />
                                            </div>
                                        </div>
                                        <Input
                                            placeholder="Select price"
                                            type="number"
                                            useForm={register('price', {
                                                required: 'This field is required.',
                                            })}
                                            error={errors.price?.message}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="add-tariff container">
                        <div className="add-tariff_template">
                            <h4>Promo action </h4>
                            <div className="promo-switch">
                                <Icon
                                    onClick={() => setActive(!active)}
                                    name={!active ? 'Switch-off' : 'Switch-on'}
                                />
                                <span>Publish as a Promo action</span>
                            </div>
                            {active ? (
                                <div className="promo-hidden">
                                    <div className="filters">
                                        <div className="category">
                                            <h4>Deal’s name</h4>
                                            <Input
                                                placeholder="Enter a name of deal"
                                                useForm={register('room_deal.deal_title', {
                                                    required: 'This field is required.',
                                                })}
                                                error={errors.room_deal?.deal_title?.message}
                                            />
                                        </div>
                                    </div>
                                    <div className="description">
                                        <h4>Description</h4>
                                        <TextareaCustom
                                            maxLength={100}
                                            required={'This field is required'}
                                            error={errors?.room_deal?.deal_description?.message}
                                            register={register}
                                            register_key={'room_deal.deal_description'}
                                        />
                                    </div>
                                    <div className="promo-inputs">
                                        <div>
                                            <h4>Special price*</h4>
                                            <div className="promo-inputs__left">
                                                <div>
                                                    <p className="promo-inputs__label">New Price</p>
                                                    <div className="price-input-container">
                                                        <div className="price-input">
                                                            <div className="price-input__icon">
                                                                <Icon name="input-price" />
                                                            </div>
                                                        </div>
                                                        <Input
                                                            className="input"
                                                            type={'number'}
                                                            min={1}
                                                            useForm={register(
                                                                'room_deal.discount_price',
                                                                {
                                                                    required:
                                                                        'This field is required',
                                                                    onChange: (e) => getPercents(e),
                                                                }
                                                            )}
                                                            error={
                                                                errors.room_deal?.discount_price
                                                                    ?.message
                                                            }
                                                            placeholder="New price"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="line"> = </div>
                                                <div>
                                                    <p className="promo-inputs__label">Percent</p>
                                                    <div className="promo-percent">{percents}%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="tooltip">
                                                <h4>Promo Action Dates*</h4>
                                                <Tooltip
                                                    text={
                                                        'When creating the promo action, the start day will be automatically set to the current date.'
                                                    }
                                                >
                                                    <Icon name={'get-info'} width={19} />
                                                </Tooltip>
                                            </div>
                                            <div className="promo-inputs__rigth">
                                                <div>
                                                    <p className="promo-inputs__label">End date</p>
                                                    <Input
                                                        width="100%"
                                                        type="date"
                                                        min={watch(date_now)}
                                                        defaultValue={date_now}
                                                        useForm={register('room_deal.end_date', {
                                                            required: 'This field is required',
                                                        })}
                                                        error={errors.room_deal?.end_date?.message}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="buttons">
                            <Button
                                text={'cancel'}
                                type="button"
                                outlined
                                loading={sending}
                                onClick={() => navigate(-1)}
                            />
                            <Button text={tariffId ? 'SAVE' : 'ADD'} loading={sending} />
                        </div>
                    </section>
                </form>
            )}
        </>
    )
}

export default AddRoomType
