import React, { FC } from 'react'
import { RadioInput } from './interface'
import Icon from '../icon'
import './styles.scss'

type Props = RadioInput
const Radio: FC<Props> = ({ value, onChange, checked, text, icon, style }) => {
    return (
        <div>
            <label className="container-radio">
                <div className={style ? style : 'radio-text'}>
                    {icon && <Icon name={icon} />}
                    {text}
                </div>
                <input type="radio" checked={checked} value={value} onChange={onChange} />
                <span className="checkmark"></span>
            </label>
        </div>
    )
}
export default Radio
