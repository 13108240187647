import './styles.scss'
import Input from '../../../components/input'
import Button from '../../../components/button'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { getProfileRoom, updateRoomBusinessProfile, getAmenitiesList } from '../../../api/profiles'
import React, { useEffect, useState } from 'react'
import { showToast } from '../../../utils/toast-function'
import Loader from '../../../components/loader/loader'
import 'react-datepicker/dist/react-datepicker.css'
import Amenity from '../amenity'
import DropzoneBusiness from '../account-business-info-dropzone'
import { observer } from 'mobx-react-lite'
import LocationInput from '../../../components/location-input'
import TextareaCustom from '../../../components/Textarea-custom/text-area-custom'

const BusinessInfo = () => {
    const [sending, setIsSending] = useState(false)
    const [existedAmenities, setExistedAmenities] = useState<any>([])

    const profileId = localStorage.userID && Number(localStorage.userID)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
    } = useForm<any>()

    const { data: roomProfile, status, refetch, isLoading } = useQuery('roomInfo', getProfileRoom)
    const { data: amenities } = useQuery(
        'amenities',
        () =>
            getAmenitiesList().then((result: any) => {
                result?.forEach((item: any) => {
                    item.checked = roomProfile?.amenities.some(
                        (amenity: any) => amenity === item.id
                    )
                })
                return result
            }),
        {
            enabled: !!roomProfile,
        }
    )

    useEffect(() => {
        if (roomProfile) {
            reset(roomProfile)
            setExistedAmenities(roomProfile?.amenities)
        }
    }, [roomProfile])
    const onSubmit = async (profile: any) => {
        if (!existedAmenities.length) {
            showToast('danger', 'You must choose at least one amenity.')
        } else {
            const newProfile = {
                id: roomProfile?.id,
                user: profileId,
                ...profile,
                amenities: existedAmenities,
            }
            setIsSending(true)
            try {
                await updateRoomBusinessProfile(newProfile)
                showToast('success', 'Changes have been saved')
            } catch (error: any) {
                if (error.response.data.title?.length) {
                    setError('title', {
                        type: 'custom',
                        message: error.response.data.title[0],
                    })
                } else {
                    showToast('danger', 'Something went wrong. Try again later')
                }
            } finally {
                setIsSending(false)
            }
        }
    }

    const handleChecked = (id: number) => {
        setExistedAmenities((prev: any) =>
            existedAmenities.includes(id)
                ? prev.filter((current: any) => current !== id)
                : [...prev, id]
        )
    }

    return (
        <div className="room-business-info-wrapper">
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className={'photo-zone'}>
                        <DropzoneBusiness
                            refetch={refetch}
                            roomData={status === 'success' && roomProfile}
                        />
                    </div>

                    <form
                        className="container business-info-room"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className="business-info-room__grid-view">
                            <Input
                                label="Treatment room title*"
                                placeholder="Enter your room title"
                                className="basic-info-input"
                                useForm={register('title', {
                                    required: 'This field is required',
                                })}
                                error={errors.title?.message}
                            />
                            <Input
                                label="Business Email* "
                                placeholder="Enter your last name"
                                className="basic-info-input"
                                useForm={register('business_email', {
                                    required: 'This field is required',
                                })}
                                error={errors.business_email?.message}
                            />
                            <Input
                                label="Business Phone number* "
                                placeholder="Enter your phone number"
                                className="basic-info-input"
                                type={'number'}
                                useForm={register('business_phone_number', {
                                    required: 'This field is required',
                                    valueAsNumber: true,
                                })}
                                error={errors.business_phone_number?.message}
                            />
                            <LocationInput />
                        </div>
                        <div className="basic-info__textarea">
                            <h4>Description</h4>
                            <TextareaCustom
                                defaultValue={roomProfile?.description}
                                register={register}
                                register_key={'description'}
                                maxLength={1000}
                            />
                        </div>
                        <div className="business-info-room__amenities">
                            <h4>Choose amenities that you have*</h4>
                            <div className={'amenities-grid'}>
                                {amenities?.map(
                                    (
                                        val: { id: number; title: string; checked: boolean },
                                        index: number
                                    ) => (
                                        <Amenity
                                            data={val}
                                            key={index}
                                            handleChecked={handleChecked}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                        <Button text={'update'} loading={sending} />
                    </form>
                </>
            )}
        </div>
    )
}
export default observer(BusinessInfo)
