import React from 'react';

function TreatmentsIcon() {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.93025 16.5714H11.3588M19.3588 17.7143V9.71429L13.6445 4H7.93025C7.32404 4 6.74266 4.24082 6.314 4.66947C5.88535 5.09812 5.64453 5.67951 5.64453 6.28571V17.7143C5.64453 18.3205 5.88535 18.9019 6.314 19.3305C6.74266 19.7592 7.32404 20 7.93025 20H17.0731C17.6793 20 18.2607 19.7592 18.6893 19.3305C19.118 18.9019 19.3588 18.3205 19.3588 17.7143V17.7143ZM7.93025 12H13.6445H7.93025ZM7.93025 14.2857H15.9302H7.93025Z" stroke="currentColor" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.6445 4V7.42857C13.6445 8.03478 13.8853 8.61616 14.314 9.04482C14.7427 9.47347 15.324 9.71429 15.9302 9.71429H19.3588" stroke="currentColor" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


    )
}

export default TreatmentsIcon
