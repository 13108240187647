import './styles.scss'
import { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { getServicesList } from '../../api/treatments-and-tariffs'
import columns from './logicOfData'
import DataTable from 'react-data-table-component'
import Loader from '../../components/loader/loader'
import PaginateComponent from '../../components/pagination'
import NoClients from '../../components/noclients-list'
import Input from '../../components/input'
import Button from '../../components/button'
import { Link } from 'react-router-dom'
import AccordionTreatments from '../../components/accordion-treatments'
import trigger from '../../store/trigger'
import { observer } from 'mobx-react-lite'
import { useDebouncedCallback } from 'use-debounce'
import NoApprovePage from '../../components/no-approve-page'
import { paginationText } from '../../utils/pagination-text'

const RoomTariffsListPage: FC = () => {
    const [filters, setFilters] = useState({
        page: 1,
        page_size: 10,
        search: '',
    })

    const { data, isLoading } = useQuery(
        ['tariffsList', filters, trigger.toggleTrigger],
        () => getServicesList({ ...filters }),
        {
            cacheTime: 0,
        }
    )
    const isApproved = JSON.parse(localStorage.getItem('approved') as string)
    const debounceValueFn = useDebouncedCallback((e) => {
        setFilters({ ...filters, page: 1, search: e.target.value })
    }, 600)
    return isApproved ? (
        <div className="tariffsList container">
            <div className="headerWrapper">
                <h2 className="header">Tariffs</h2>
                <Link to={'/add-tariff'}>
                    <Button text={'+ add room tariff '} />
                </Link>
            </div>
            <div className="tariffsList__background">
                <Input
                    type="text"
                    onChange={debounceValueFn}
                    icon="search"
                    placeholder="Search by tariffs name"
                />
                {isLoading ? (
                    <Loader transparent />
                ) : !data.results.length ? (
                    <NoClients warning="No tariffs" />
                ) : (
                    <>
                        <div className="dataTableWrapper">
                            <DataTable
                                columns={columns}
                                data={data.results}
                                responsive
                                // pointerOnHover={true}
                                // highlightOnHover={true}
                                paginationTotalRows={data.total_count}
                                paginationPerPage={data.page_size}
                            />
                        </div>
                        <div className="accordionWrapper">
                            <AccordionTreatments name={data} />
                        </div>

                        <div className="paginationDiv">
                            <div className="paginationDiv__text">
                                {paginationText(data, 'tariffs')}
                            </div>

                            <PaginateComponent
                                onChange={(e: number) =>
                                    setFilters({
                                        ...filters,
                                        page: e,
                                    })
                                }
                                hidden={!data.results.length || data.total_count <= data.page_size}
                                total={data.total_count}
                                defaultCurrentPage={1}
                                defaultPageSize={data.page_size}
                                currentPage={filters.page}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    ) : (
        <NoApprovePage />
    )
}

export default observer(RoomTariffsListPage)
