import './styles.scss'
import EmptyImage from '../../assets/images/empty-chat.svg'
const EmptyChat = () => {
    return <div className='empty-chat'>
        <img src={EmptyImage} alt='empty-chat' />
        <div>
            <span className='empty-chat-title'>No messages yet</span>
            <span className='empty-chat-description'>Type your message to start a dialog</span>
        </div>
    </div>
}
export default EmptyChat
