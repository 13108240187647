import { FC, useState } from 'react'
import './styles.scss'
import FromToDatepicker from '../../../components/from-to-datepicker'
import moment from 'moment'
import { getNextMonth, getStartMonth } from '../../../utils/format-date'
import { Doughnut } from 'react-chartjs-2'

import { optionsCircle } from './data'
import { useQuery } from 'react-query'
import { getDashboardSalesByService } from '../../../api/dashboard'
import { generateHexColors } from '../../../utils/hex-colors'

const SalesByService: FC = () => {
    const isRoomPage = localStorage.userType === 'room'

    const [filters, setFilters] = useState({
        from: moment(getStartMonth()).format('YYYY-MM-DD'),
        to: moment(getNextMonth()).format('YYYY-MM-DD'),
    })

    const { data } = useQuery(['sales-by-service', filters], () => getDashboardSalesByService(filters))

    const colors = generateHexColors(data?.sales_by_service?.datasets?.data?.length)

    const dataCircle = {
        datasets: [
            {
                labels: data?.sales_by_service?.datasets?.labels,
                data: data?.sales_by_service?.datasets?.data,
                backgroundColor: colors,
                borderWidth: 0,
                pointStyle: 'rectRounded',
            },
        ],
    }
    return (
        <div className={'sales-by-service white-card'}>
            <div className="sales-by-service-head">
                <h4>{isRoomPage ? 'Booking by room type' : 'Sales by service'}</h4>
                <FromToDatepicker setFilters={setFilters} />
            </div>
            <>
                {' '}
                <div className="circle-box">
                    {colors.length ? (
                        <Doughnut data={dataCircle} options={optionsCircle} />
                    ) : (
                        <p>No sales in this period.</p>
                    )}
                </div>
                <div className="list">
                    {data?.sales_by_service?.list.map((item: any, index: number) => (
                        <div key={index} className="list-item">
                            <div className="info">
                                <div className="item-circle" style={{ borderColor: colors[index] }} />
                                <p>{item.label}</p>
                            </div>
                            <b>{item.value}%</b>
                        </div>
                    ))}
                </div>
            </>
        </div>
    )
}

export default SalesByService
