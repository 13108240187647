import { useEffect, useState } from 'react'
import './styles.scss'
import ChangePasswordComponent from '../../../modules/change-password'
import PersonalInfoForm from '../../../modules/treatment-room/account-personal-info'
import BusinessInfo from '../../../modules/treatment-room/account-business-info'
import NoApprovePage from '../../../components/no-approve-page'

const RoomAccount = () => {
    const [toggleState, setToggleState] = useState(1)

    const toggleTab = (index: number) => {
        setToggleState(index)
    }
    useEffect(() => window.scrollTo(0, 0), [])
    const isApproved = JSON.parse(localStorage.getItem('approved') as string)
    const tabs = [
        {
            title: 'Personal account',
            component: <PersonalInfoForm />,
        },
        {
            title: 'Business account',
            component: <BusinessInfo />,
        },
        {
            title: 'Change password',
            component: <ChangePasswordComponent />,
        },
    ]

    return isApproved ? (
        <>
            <div className="container">
                <ul className="account-tab-room">
                    {tabs.map((tab, index) => (
                        <li key={index}>
                            <button
                                className={toggleState === index ? 'active' : ''}
                                onClick={() => toggleTab(index)}
                            >
                                {tab.title}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={`tab-content ${toggleState === index && 'active-content'}`}
                >
                    {tab.component}
                </div>
            ))}
        </>
    ) : (
        <NoApprovePage />
    )
}
export default RoomAccount
