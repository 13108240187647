import { request } from './api'

export async function login(data) {
    return request(`auth/business/token/`, 'POST', data)
}

export async function signUp(data) {
    return request(`auth/professionals/create/`, 'POST', data)
}
export async function signUpRoom(data) {
    return request(`auth/rooms/create/`, 'POST', data)
}

export async function changePassword(data) {
    return request(`auth/change_password/`, 'PUT', data)
}
export async function checkResetPassword(uid, token) {
    return request(`auth/reset-password/verify/${uid}/${token}`, 'GET')
}
export async function sendEmail(data) {
    return request(`auth/reset-password/`, 'POST', data)
}
export async function resetPassword(data) {
    return request(`auth/reset-password/complete/`, 'PATCH', data)
}

export async function getAccountVerification(uid, token) {
    return request(`auth/verify/${uid}/${token}/`, 'GET')
}
export async function roomMemberRegistration(email, uid, token, data) {
    return request(`invitation_link/${email}/${uid}/${token}/`, 'POST', data)
}

export async function getReasons(params) {
    return request(`auth/delete-user/reasons/`, 'GET', null, null, params)
}
export async function deleteAccount(data) {
    return request(`auth/delete-user/`, 'POST', data)
}
