import React, { FC } from 'react'
import { sidebar_blocks } from '../../utils/navigation-data'
import { NavLink } from 'react-router-dom'
import LogoutIcon from '../menu-icons/logout-icon'
import LogoutModalStore from '../../store/logout-modal-trigger'
type Props = {
    toggleHamburger?: any
}
const SideBarLinks: FC<Props> = ({ toggleHamburger }) => {
    return (
        <div className="nav">
            <ul>
                {sidebar_blocks.map((item, index) => (
                    <li key={index}>
                        {item.map(
                            (
                                val: {
                                    title: string
                                    link: string
                                    icon: any
                                    active: any
                                },
                                key: any
                            ) =>
                                val.active && (
                                    <NavLink
                                        onClick={toggleHamburger || undefined}
                                        to={val.link}
                                        key={`${index}-${key}`}
                                        className={({ isActive }) =>
                                            isActive ? 'active link' : 'link'
                                        }
                                    >
                                        <div>{val.icon}</div>
                                        <span>{val.title}</span>
                                    </NavLink>
                                )
                        )}
                        {index < 2 && <div className="divider" />}
                    </li>
                ))}
                <li className="settings">
                    <div
                        className="log-out-box"
                        onClick={() => LogoutModalStore.setShowModal(true)}
                    >
                        <LogoutIcon />
                        <button className={'log-out-btn'}>Log Out</button>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default SideBarLinks
