import React from 'react';

function LogoutGrey() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6817 15.4286L20.0017 12M20.0017 12L16.6817 8.57143M20.0017 12H9.71596M16.5731 4L7.43025 4.00229C6.16853 4.00343 5.14453 5.02629 5.14453 6.288V17.7109C5.14453 18.3171 5.38535 18.8984 5.814 19.3271C6.24266 19.7558 6.82404 19.9966 7.43025 19.9966H16.6817"
                stroke="currentColor" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default LogoutGrey
