import './styles.scss'
import 'react-responsive-modal/styles.css'

import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

/** Components **/

/** Store **/
import UserPositionStore from '../../store/user-position'

/** APIs **/
import { setLocation } from '../../api/maps'

import { user_role } from '../../utils/user-type'

const HeaderLocation = () => {
    const isAuth = !!JSON.parse(localStorage.getItem('userID') as string)

    useEffect(() => {
        if (localStorage.address && localStorage.address !== 'undefined') {
            const localPosition = JSON.parse(localStorage.getItem('address') as string)
            UserPositionStore.setUserPosition(localPosition)
            isAuth &&
                setLocation(
                    {
                        address: localPosition.name,
                        lat: localPosition.position.lat,
                        lon: localPosition.position.lng,
                    },
                    user_role as string
                )
        }
    }, [])
    return (
        <>
            <div className="icon">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 21.1886L12.7211 20.376C13.5394 19.4389 14.2754 18.5498 14.9303 17.704L15.4708 16.9909C17.728 13.9497 18.8571 11.536 18.8571 9.75204C18.8571 5.94403 15.7874 2.85718 12 2.85718C8.21254 2.85718 5.14282 5.94403 5.14282 9.75204C5.14282 11.536 6.27197 13.9497 8.52911 16.9909L9.06968 17.704C10.0039 18.9011 10.9813 20.0637 12 21.1898V21.1886Z"
                        stroke="currentColor"
                        strokeWidth="1.14286"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12 12.5715C13.5779 12.5715 14.8571 11.2923 14.8571 9.71432C14.8571 8.13636 13.5779 6.85718 12 6.85718C10.422 6.85718 9.14282 8.13636 9.14282 9.71432C9.14282 11.2923 10.422 12.5715 12 12.5715Z"
                        stroke="currentColor"
                        strokeWidth="1.14286"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
            <div className="mobileIcon">
                <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.99944 16.6571L7.60039 15.9799C8.2823 15.199 8.89563 14.458 9.44135 13.7532L9.89182 13.159C11.7728 10.6247 12.7137 8.61324 12.7137 7.12657C12.7137 3.95324 10.1556 1.38086 6.99944 1.38086C3.84325 1.38086 1.28516 3.95324 1.28516 7.12657C1.28516 8.61324 2.22611 10.6247 4.10706 13.159L4.55754 13.7532C5.33605 14.7508 6.15056 15.7196 6.99944 16.658V16.6571Z"
                        stroke="currentColor"
                        strokeWidth="1.14286"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M7.00009 9.47626C8.31506 9.47626 9.38105 8.41027 9.38105 7.09531C9.38105 5.78034 8.31506 4.71436 7.00009 4.71436C5.68513 4.71436 4.61914 5.78034 4.61914 7.09531C4.61914 8.41027 5.68513 9.47626 7.00009 9.47626Z"
                        stroke="currentColor"
                        strokeWidth="1.14286"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        </>
    )
}

export default observer(HeaderLocation)
