import { FunctionComponent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/button'
import Input from '../../components/input'
import PasswordInput from '../../components/sign-up-password-input'
import { changePassword, resetPassword } from '../../api/auth'
import { showToast } from '../../utils/toast-function'
import './styles.scss'
import { ChangePasswordComponentProps } from './interface'

type Props = ChangePasswordComponentProps

const ChangePasswordComponent: FunctionComponent<Props> = ({
    forResetPass = false,
    userID,
    userToken,
}) => {
    const [isGoodPassword, setIsGoodPassword] = useState(false)
    const [newPassValue, setNewPassValue] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        resetField,
        reset,
    } = useForm()
    const navigate = useNavigate()

    async function changeProfilePass(data: any) {
        let res
        let dataForResetPass = { ...data, uid: userID, token: userToken }
        setIsSending(true)
        try {
            res = forResetPass ? await resetPassword(dataForResetPass) : await changePassword(data)
            reset()
            forResetPass && navigate('/login')
            !forResetPass && showToast('success', 'Changes have been saved')
            setNewPassValue(!newPassValue)
            return res
        } catch (error: any) {
            const errMsg = error.response.data.old_password
            if (errMsg?.length) {
                setError('old_password', {
                    type: 'custom',
                    message: typeof errMsg === 'string' ? errMsg : errMsg[0],
                })
            } else {
                setError(forResetPass ? 'old_password' : 'new_password', {
                    type: 'custom',
                    message: 'Something went wrong. Try again later',
                })
            }
            console.dir(error)
        } finally {
            setIsSending(false)
        }
    }

    const onSubmit = (data: any) => {
        if (!isGoodPassword) {
            setError('new_password', {
                type: 'custom_two',
                message: 'The password does not meet the requirements',
            })
        } else if (data.new_password !== data.confirm_password) {
            resetField('confirm_password')
            setError('new_password', {
                type: 'custom',
                message: "Passwords don't match",
            })
            setError('confirm_password', {
                type: 'custom',
                message: ' ',
            })
        } else {
            changeProfilePass(data)
        }
    }
    return (
        <div className="changePassword container">
            <form onSubmit={handleSubmit(onSubmit)} className={isSending ? 'formSending' : ''}>
                {forResetPass ? null : (
                    <Input
                        label="Current password"
                        placeholder="Enter current password"
                        width="100%"
                        passwordEye
                        autoComplete={'section-blue shipping password'}
                        useForm={register('old_password', {
                            required: 'This field is required',
                        })}
                        error={errors.old_password?.message}
                    />
                )}

                <PasswordInput
                    forChangePassword
                    label="New password*"
                    initValue={newPassValue}
                    placeholder="Enter new password*"
                    passwordEye
                    width="100%"
                    autoComplete={'section-blue shipping password'}
                    register={register}
                    setGoodPassword={setIsGoodPassword}
                    error={errors.new_password?.message}
                />
                <Input
                    type="password"
                    label="Confirm new password"
                    placeholder="Confirm new password"
                    width="100%"
                    autoComplete={'section-blue shipping password'}
                    useForm={register('confirm_password', {
                        required: 'This field is required',
                    })}
                    error={errors.confirm_password?.message}
                />
                <Button text={'update'} loading={isSending} />
            </form>
        </div>
    )
}

export default ChangePasswordComponent
