import './styles.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import React, { FunctionComponent } from 'react'
import Rating from '../rating'
import { MyReview } from './interface'
import { Link } from 'react-router-dom'
import defaultUser from '../../assets/images/default_user.png'
import moment from 'moment'

type Props = MyReview

const ReviewCard: FunctionComponent<Props> = ({ forDetailed, data }) => {
    return (
        <Link to={`/reviews/${data?.id}`} style={{ display: 'flex' }}>
            <div className={`review-card ${forDetailed && 'detailed'}`}>
                <div>
                    <div className="review-padding review-card_top">
                        <div className="review-title">
                            <div className="review-author">
                                <img
                                    className="review-img"
                                    src={data?.client?.photo || defaultUser}
                                    alt="client-photo2"
                                />
                                <h4>
                                    {data?.client?.name} {data?.client?.surname}
                                </h4>
                            </div>
                        </div>
                        <Rating stars={data?.rating} />
                    </div>
                    <div className="review-padding">
                        <span className="review-text">{data?.text}</span>
                        <div className="review-footer">
                            <div>
                                <span>
                                    {moment(data?.creation_date, 'DD/MM/YYYY').format('MM.DD.YY')}
                                </span>
                                <span>{data?.replies_count} replies</span>
                            </div>
                            <span className="status">{data?.published ? 'published' : 'open'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default ReviewCard
