import { TableColumn } from 'react-data-table-component'
import StatusDropDown from '../../../components/status-dropdown'

const columns: TableColumn<any>[] = [
    {
        name: 'FULL NAME',
        minWidth: '200px',
        selector: (row) => row.name,
        cell: (row) => {
            return (
                <div className="logicOfData">
                    <img
                        className="clientsPhoto"
                        style={{ width: '30px' }}
                        src={row.photo || require('../../../assets/images/default_user.png')}
                        alt={'client-avatar'}
                    />
                    <p className="member-name">
                        {row.name} {row.surname}
                    </p>
                </div>
            )
        },
        sortable: true,
    },

    {
        name: 'POSITION',
        selector: (row) => row.position || '-',
        sortable: true,
    },
    {
        name: 'PHONE',
        selector: (row) => row.phone || '-',
        sortable: true,
    },
    {
        name: 'EMAIL',
        selector: (row) => row.user?.email,
    },
    {
        name: 'STATUS',
        width: '140px',
        // center: true,
        cell: (row) => <StatusDropDown data={row} />,
    },
]

export default columns
