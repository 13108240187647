import React, { FunctionComponent } from 'react'
import './styles.scss'
import Icon from '../../components/icon'
import Button from '../../components/button'
import { Link } from 'react-router-dom'
import defaultUser from '../../assets/images/default_user.png'
import Tooltip from '../../components/tooltip'
import UserIcon from '../../components/menu-icons/user-icon'
import LogoutGrey from '../../components/menu-icons/logout2-icon'
import LogoutModalStore from '../../store/logout-modal-trigger'
import TopBarStore from '../../store/topbar'
interface Profile {
    stripe_link?: string
    profile?: {
        name: string
        surname: string
        photo: string
        client_profile: boolean
    }
    room?: {
        name: string
        surname: string
        photo: string
    }
}

type Props = Profile
const ProfileDropdown: FunctionComponent<Props> = ({ stripe_link, profile, room }) => {
    return (
        <>
            <div className={'dropdown-container'}>
                <div className="dropdown-types">
                    <Link className="profile-type" to={'/'}>
                        <img
                            src={room ? room?.photo || defaultUser : profile?.photo || defaultUser}
                            alt={'avatar'}
                            className="dropdown-img"
                        />
                        <div>
                            <h6 className="user-name">
                                {room
                                    ? `${room?.name} ${room.surname}`
                                    : `${profile?.name} ${profile?.surname}`}
                            </h6>
                            <span>Business Account</span>
                        </div>
                    </Link>
                    {profile && (
                        <div className="create-account">
                            {profile?.client_profile ? (
                                <a href="https://glowapp.co.uk/" target="_blank">
                                    <Button
                                        text={'GO to the client’s website'}
                                        className="dropdown-btn"
                                        outlined
                                    />
                                </a>
                            ) : (
                                <>
                                    <Tooltip
                                        text={
                                            'To book another beauty professional and/or book a treatment room, you should have a personal account'
                                        }
                                    >
                                        <Icon name={'question'} />
                                    </Tooltip>

                                    <Button
                                        text={'create a personal account'}
                                        className="dropdown-btn"
                                        outlined
                                        onClick={() => TopBarStore.setMasterModal(true)}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className="divider" />
                <Link to={room ? '/room/account' : '/account'} className="profile-link">
                    <UserIcon />
                    <span className={'profile'}>Profile</span>
                </Link>

                <a
                    href={stripe_link}
                    target={'_blank'}
                    className={`profile-link mt-10 ${!stripe_link && 'disabled'}`}
                >
                    <Icon name={'stripe'} style={{ display: 'flex' }} />
                    <span className={'profile'}>Stripe Account</span>
                </a>

                <div className="divider" />
                <div className="logout-link" onClick={() => LogoutModalStore.setShowModal(true)}>
                    <LogoutGrey />
                    <span className="logout">Log out</span>
                </div>
            </div>
        </>
    )
}

export default ProfileDropdown
