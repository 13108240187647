import { makeAutoObservable, observable } from 'mobx'

class LogoutModalStore {
    isShowModal = false

    constructor() {
        makeAutoObservable(this, {
            isShowModal: observable,
        })
    }

    setShowModal(isExpand: boolean) {
        this.isShowModal = isExpand
    }
}

export default new LogoutModalStore()
