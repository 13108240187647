import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { deleteService } from '../../api/treatments-and-tariffs'
import { showToast } from '../../utils/toast-function'
import Button from '../button'
import Icon from '../icon'
import ModalWrapper from '../modal-wrapper'
import './styles.scss'
import trigger from '../../store/trigger'
const TariffsCrud: FC<any> = ({ row }) => {
    const [isSending, setIsSending] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const deleteTariffsFn = async () => {
        setIsSending(true)
        try {
            await deleteService(row.id)
            showToast('success', 'Tariff is deleted successfully.')
            setModalOpen(false)
            trigger.setToggle()
        } catch (error) {
            showToast('danger', 'Something went wrong. Try again later.')
        } finally {
            setIsSending(false)
        }
    }
    return (
        <div className="tariffsBtns">
            <ModalWrapper isShown={isModalOpen} setIsShown={setModalOpen}>
                <div className="deleteModal">
                    <h2>Are you sure?</h2>
                    <p>This action cannot be undone</p>
                    <div className="delete-btns">
                        <Button
                            text={'cancel'}
                            onClick={() => setModalOpen(false)}
                            loading={isSending}
                        />
                        <Button
                            text={'delete'}
                            outlined
                            onClick={deleteTariffsFn}
                            loading={isSending}
                        />
                    </div>
                </div>
            </ModalWrapper>
            <div className="logicOfData gap-20 align-items-start">
                <Icon onClick={() => setModalOpen(true)} name="trash" hover />

                <Link to={`/edit_tariff/${row.id}`}>
                    <Icon name="pen-black" hover />
                </Link>
            </div>
        </div>
    )
}

export default TariffsCrud
