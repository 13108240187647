import './styles.scss'
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { ModalBookingsProps } from './intarface'
import { formatMinutes } from '../../utils/timeToHours'
import Icon from '../icon'
import notFound from '../../assets/images/not-found.svg'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getProfileRoom } from '../../api/profiles'
import { closeByClick } from '../../utils/closing-dropdown-func'

type Props = ModalBookingsProps

const ModalBookingsComp: FC<Props> = ({ userData, setFilter, filter }) => {
    const [activeBtn, setActiveBtn] = useState(false)
    const statuses = ['booked', 'canceled', 'completed', 'declined', 'all']
    const ref = useRef() as MutableRefObject<HTMLInputElement>
    const isRoom = localStorage.getItem('userType') === 'room'

    const { data: roomProfile } = useQuery(['roomInformation', isRoom], getProfileRoom, {
        enabled: isRoom,
    })

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setActiveBtn(false)
            }
        }
        closeByClick(checkIfClickedOutside)
    }, [ref])
    return (
        <div className="modalBookings">
            <div className="header">
                <h1>Bookings</h1>
                <div ref={ref}>
                    <div className="filter" onClick={() => setActiveBtn(!activeBtn)}>
                        <Icon name={'modal-filter-icon'} />
                        FILTER
                    </div>
                    {activeBtn ? (
                        <div className="filter-content">
                            {statuses.map((status, i) => (
                                <p
                                    key={i}
                                    onClick={() => {
                                        setFilter({
                                            ...filter,
                                            status: status === 'all' ? null : status,
                                        })
                                        setActiveBtn(false)
                                    }}
                                >
                                    {status[0]?.toUpperCase() + status.slice(1)}
                                </p>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
            {userData.bookings.length !== 0 ? (
                <div className="card-container">
                    {userData.bookings.map((card: any) => (
                        <Link key={card.id} to={`/bookings/${card?.id}`}>
                            <div className="card">
                                <div className="sectionImgName">
                                    <div>
                                        {isRoom ? (
                                            <p>{roomProfile?.title}</p>
                                        ) : (
                                            <>
                                                <img
                                                    style={{ width: '30px' }}
                                                    src={card?.professional?.photo}
                                                    alt=""
                                                />
                                                <p>
                                                    {card?.professional?.name}{' '}
                                                    {card?.professional?.surname}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                    <div className={card.status}>{card.status}</div>
                                </div>
                                <div className="sectionInfo">
                                    <div>
                                        <div>
                                            <Icon name={'modal-booking-date'} />{' '}
                                            {moment(card?.date, 'DD/MM/YYYY').format('dddd DD MMM')}
                                        </div>
                                        <div>
                                            <Icon name={'modal-booking-time'} />{' '}
                                            {card.time.split(':').slice(0, 2).join(':')}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <Icon name={'modal-booking-geo'} />{' '}
                                            {formatMinutes(card.total_duration)}
                                        </div>
                                        <div>
                                            <Icon name={'modal-booking-duration'} /> {card.address}
                                        </div>
                                    </div>
                                </div>
                                <div className="sectionPrice">
                                    <p>Total</p> £
                                    {isRoom
                                        ? card.total_room_tariffs_price
                                        : card.total_treatments_price}
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            ) : (
                <div className="background404">
                    <div>
                        <img src={notFound} alt="notFound" />
                    </div>
                    <p>No bookings yet</p>
                </div>
            )}
        </div>
    )
}

export default ModalBookingsComp
