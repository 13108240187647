import './styles.scss'
import React, { FC, useRef, useState } from 'react'

import { GoogleMapsComponentProps } from './interface'

import { GoogleMap, useJsApiLoader, OverlayView, Autocomplete } from '@react-google-maps/api'
import Marker from './marker'
import { getAddresses, getLocation } from '../../api/maps'
import Input from '../../components/input'
import Loader from '../../components/loader/loader'
import { useQuery } from 'react-query'

type props = GoogleMapsComponentProps

const SimpleMap: FC<props> = ({ onSelectAddress }) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env['REACT_APP_GOOGLE_MAP_KEY'] as string,
        libraries: ['places'],
    })
    const [position, setPosition] = useState<any>({
        lat: 51.507359,
        lng: -0.136439,
        current_address: '',
    })
    const { data } = useQuery(['location'], () =>
        getLocation().then((res) => {
            setPosition({
                lat: res?.lat,
                lng: res?.lon,
                current_address: '',
            })
            return res
        })
    )

    const [searchValue, setSearchValue] = useState('')
    const getAddress = async (data_address: any) => {
        const lat = data_address?.latLng.lat()
        const lng = data_address?.latLng.lng()
        const filter_keys = ['premise', 'street_address', 'establishment']
        try {
            const res = (await getAddresses(lat, lng)) as any
            const newRes = res?.data?.results.filter((item: any) => {
                return filter_keys.some((filter) => item.types.includes(filter))
            })
            setPosition({
                ...position,
                current_address: res.data?.plus_code?.compound_code,
                lat: lat,
                lng: lng,
                addresses: newRes,
            })
        } catch (e: any) {
            throw new Error(e)
        }
    }
    const restrictions = {
        country: 'uk',
    }
    const [autoComplete, setAutoComplete] = useState() as any

    const inputRef = useRef(null) as any

    function onLoad(autocomplete: any) {
        setAutoComplete(autocomplete)
    }
    function onPlaceChanged() {
        if (autoComplete !== null) {
            const res = autoComplete.getPlace()
            setSearchValue(res?.formatted_address)
            getAddress({ latLng: res.geometry.location })
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }
    const options = {
        strictBounds: true,
    }
    if (!isLoaded) return <Loader transparent />
    const handleInputChange = (e: any) => {
        setSearchValue(e.target.value)
    }

    const clearSearch = () => {
        setSearchValue('')
    }
    return position?.lat && position?.lng ? (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div style={{ paddingBottom: '10px', width: '100%' }}>
                <Autocomplete
                    onLoad={onLoad}
                    className={'autocomplete-container'}
                    onPlaceChanged={onPlaceChanged}
                    restrictions={restrictions}
                    options={options}
                >
                    <Input
                        className={'input-primary'}
                        style={{ width: '100%' }}
                        type="text"
                        placeholder="Enter your address"
                        ref={inputRef}
                        clear={true}
                        onClick={clearSearch}
                        value={searchValue}
                        onChange={handleInputChange}
                    />
                </Autocomplete>
            </div>
            <GoogleMap
                onClick={async (e: any) => {
                    await getAddress(e)
                }}
                center={position}
                zoom={15}
                mapContainerStyle={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    borderRadius: '5px',
                }}
                options={{
                    zoomControl: true,
                    streetViewControl: true,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    scrollwheel: false,
                }}
            >
                <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                    <Marker
                        onClick={onSelectAddress}
                        locations={position?.addresses}
                        lat={position?.lat}
                        lng={position?.lng}
                        text={position?.current_address || ''}
                    />
                </OverlayView>
            </GoogleMap>
        </div>
    ) : (
        <div>not loaded</div>
    )
}

export default SimpleMap
