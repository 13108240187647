import { LoadingProps } from "./interface"
import { FunctionComponent } from 'react'
import './styles.scss'

type Props = LoadingProps

const LoaderDots: FunctionComponent<Props> = ({ purple = false, relative }) => {
      
  return (
    <div className={`formLoader ${purple? 'formLoader_purple' : ''} ${relative && 'relative'}`}>
      <ul className="formLoading">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  )
}

export default LoaderDots