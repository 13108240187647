import { useState, useEffect } from 'react'
import LocationModalStore from '../../store/location-modal'
import { showToast } from '../../utils/toast-function'

export const usePosition = () => {
    const [position, setPosition] = useState({})
    const [error, setError] = useState(null)

    const onChange = async (data) => {
        const {
            coords: { latitude, longitude },
        } = data
        // getAddresses(latitude, longitude).then((res) => console.log('res', res))
        // Здесь мы могли бы сохранить весь объект position, но для
        // ясности давайте явно перечислим, какие свойства нас интересуют
        setPosition({ lat: latitude, lng: longitude })
        setError('')
    }

    const onError = (error) => {
        setError(error.message)
        showToast('danger', 'Geolocation is denied by the browser')
    }
    const { isShownModal } = LocationModalStore

    useEffect(() => {
        if(!isShownModal) return;
        const geo = navigator.geolocation
        if (!geo) {
            setError('Геолокация не поддерживается браузером')
            return
        }
        // Подписываемся на изменение геопозиции браузера.
        let watcher = geo.watchPosition(onChange, onError)

        // В случае, если компонент будет удаляться с экрана
        // производим отписку от слежки, чтобы не засорять память.
        return () => geo.clearWatch(watcher)
    }, [isShownModal])

    return { position, error }
}
