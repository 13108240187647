import React from 'react';

function UserIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2.85718C12.9093 2.85718 13.7814 3.2184 14.4244 3.86138C15.0673 4.50437 15.4286 5.37644 15.4286 6.28575V8.57146C15.4286 9.48078 15.0673 10.3528 14.4244 10.9958C13.7814 11.6388 12.9093 12 12 12C11.0907 12 10.2186 11.6388 9.57563 10.9958C8.93265 10.3528 8.57143 9.48078 8.57143 8.57146V6.28575C8.57143 5.37644 8.93265 4.50437 9.57563 3.86138C10.2186 3.2184 11.0907 2.85718 12 2.85718V2.85718ZM20 18.8572V18.0252C20 14.3829 15.7874 12 12 12C8.21257 12 4 14.3829 4 18.0252V18.8572C4 19.1603 4.12041 19.451 4.33474 19.6653C4.54906 19.8796 4.83975 20 5.14286 20H18.8571C19.1602 20 19.4509 19.8796 19.6653 19.6653C19.8796 19.451 20 19.1603 20 18.8572Z" stroke="currentColor" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>



    )
}

export default UserIcon
