import { FC, forwardRef, useState } from 'react'
import { getNextMonth, getPrevMonth, getStartMonth } from '../../utils/format-date'
import ReactDatePicker from 'react-datepicker'
import './styles.scss'
import { FromToDatePickerProps } from './interface'
import moment from 'moment'

//components for datePicker
const ExampleCustomInput = forwardRef(({ value, onClick, isFrom }: any, ref: any) => (
    <div className={`custom-date-btn ${isFrom && 'from'}`}>
        <span>{isFrom ? 'from' : 'to'}</span>
        <button className="example-custom-input" onClick={onClick} ref={ref}>
            {value}
        </button>
    </div>
))
type Props = FromToDatePickerProps

const FromToDatepicker: FC<Props> = ({ setFilters, monthRatio= 0 }) => {
    const [startDate, setStartDate] = useState(getStartMonth(monthRatio))
    const [endDate, setEndDate] = useState(getNextMonth())

    const changeStartDate = (date: Date) => {
        setStartDate(date)
        setFilters((filters) => {
            return {...filters, from: moment(date).format('YYYY-MM-DD')}
        })
    }
    const changeEndDate = (date: Date) => {
        setEndDate(date)
        setFilters((filters) => {
            return {...filters, to: moment(date).format('YYYY-MM-DD')}
        })
    }

    return (
        <div className='from_to'>
            <ReactDatePicker
                className={'from_to_datepicker'}
                selected={startDate}
                onChange={(date: any) => changeStartDate(date)}
                customInput={<ExampleCustomInput isFrom />}
                dateFormat="MMMM yyyy"
                monthClassName={() => 'custom-month'}
                maxDate={getPrevMonth(endDate)}
                showMonthYearPicker
            />
            <ReactDatePicker
                selected={endDate}
                onChange={(date: any) => changeEndDate(date)}
                dateFormat="MMMM yyyy"
                customInput={<ExampleCustomInput />}
                maxDate={getNextMonth()}
                showMonthYearPicker
            />
    </div>)
}

export default FromToDatepicker