import React from 'react';

function HomeIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.71484 12.0001L12.0006 1.71436L22.2863 12.0001" stroke="currentColor" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 9.71436V17.7144C4 18.3206 4.24082 18.9019 4.66947 19.3306C5.09812 19.7593 5.67951 20.0001 6.28571 20.0001H17.7143C18.3205 20.0001 18.9019 19.7593 19.3305 19.3306C19.7592 18.9019 20 18.3206 20 17.7144V9.71436" stroke="currentColor" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


    )
}

export default HomeIcon
