import './styles.scss'
import React, { FC } from 'react'
import Icon from '../../components/icon'

type Props = {
    lat: number
    lng: number
    text: string
    locations?: any
    onClick: any
}

const Marker: FC<Props> = ({ locations, onClick }) => {
    // const getPostcode = (data: any) => {
    //     let postcode
    //     data?.address_components?.forEach((entry: any) => {
    //         if (entry.types[0] === 'postal_code') {
    //             postcode = entry.long_name
    //         }
    //     })
    //     return postcode
    // }

    let address = {
        name: null,
        position: null,
    }

    function handleAddress(item: any) {
        address.name = item?.formatted_address
        address.position = item?.geometry?.location
        onClick(address)
    }

    return (
        <div style={{ position: 'absolute', left: 0, top: 0 }}>
            <Icon width={25} height={25} name="marker" />
            {locations ? (
                <div className="locations-list-modal">
                    {locations?.map((item: any, index: number) => (
                        <div
                            onClick={() => {
                                handleAddress(item)
                            }}
                            key={index}
                            className="row"
                        >
                            {item?.formatted_address}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    )
}
export default Marker
