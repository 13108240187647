import './styles.scss'

import React, { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Loader from '../../components/loader/loader'
import NoResultFound from '../../components/no-result-found'
import ReviewCard from '../../components/review-card'
import { reviewContent } from '../../components/review-card/interface'
import { getReviewList } from '../../api/api'
import Tooltip from '../../components/tooltip'
import Icon from '../../components/icon'
import PaginateComponent from '../../components/pagination'
import NoApprovePage from "../../components/no-approve-page";

const MyReviews: FC = () => {
    const [filters, setFilters] = useState({
        page: 1,
        page_size: 5,
    })

    const { data, isLoading: loadingReviews } = useQuery(
        ['reviews', filters],
        () => getReviewList({ ...filters }),
        {
            cacheTime: 0,
        }
    )
    const isApproved = JSON.parse(localStorage.getItem('approved') as string)
    useEffect(() => {
        const media = window.matchMedia('(max-width: 768px)')
        if (media.matches) {
            window.scrollTo(0, 60)
        } else {
            window.scrollTo(0, 90)
        }
    }, [])

    return (
        isApproved ?
        (<div className="container my-review-wrapper">
            <h2 className="">
                My reviews{' '}
                <Tooltip
                    text={
                        'All reviews are automatically published to your profile in 24 hours. If a review is positive, no action is required from your side. If a review is negative, you have 24 hours to convert a damaging review into a positive one. Please try to address the client’s concern as quickly as possible within 24 hours.'
                    }
                >
                    <Icon name={'get-info'} width={19} />
                </Tooltip>
            </h2>
            {loadingReviews ? (
                <Loader />
            ) : (
                <>
                    {!data.results.length ? (
                        <NoResultFound />
                    ) : (
                        <div className=" review-cards">
                            {data?.results.map((review: reviewContent) => (
                                <ReviewCard key={review.id} data={review} />
                            ))}
                        </div>
                    )}
                </>
            )}
            <PaginateComponent
                onChange={(e: number) =>
                    setFilters({
                        ...filters,
                        page: e,
                    })
                }
                hidden={!data?.results.length || data?.total_count <= data?.page_size}
                total={data?.total_count}
                defaultCurrentPage={1}
                defaultPageSize={data?.page_size}
                currentPage={filters?.page}
            />
        </div>) : (
                <NoApprovePage/>
            )
    )
}

export default MyReviews
