import defaultUser from '../../assets/images/default_user.png'
import moment from 'moment'

export const render_message = (data: any, index: any, user_email: string) => {
    const { message, from } = data

    const me = from?.email === user_email

    const isToday = moment().isSame(moment(message?.created_time), 'd')

    return (
        <div className={`message-block ${me && 'right'}`} key={index}>
            <img
                src={from?.photo ? from?.photo : defaultUser}
                className="avatar-user"
                alt="avatar"
            />
            <div className="message">
                <span className="username">{`${from?.name || ''} ${from.surname || ''}`}</span>
                <span className="message-text">{message?.message_text}</span>
                <span className="time">
                    {message?.created_time
                        ? moment(`${message?.created_time}Z`).format(
                              isToday ? 'LT' : 'MMMM Do YYYY, h:mm a'
                          )
                        : '-'}
                </span>
            </div>
        </div>
    )
}
