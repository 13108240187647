import './styles.scss'
import 'react-responsive-modal/styles.css'
import 'react-responsive-modal/styles.css'
import 'react-datepicker/dist/react-datepicker.css'

import Icon from '../../components/icon'
import Input from '../../components/input'
import Button from '../../components/button'
import Loader from '../../components/loader/loader'

import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Multiselect } from 'multiselect-react-dropdown'

import Dropzone from '../account-basic-info-dropzone'

import AvatarComponent from '../../components/avatar-editor'
import { showToast } from '../../utils/toast-function'

import moment from 'moment'

import { useQuery } from 'react-query'

import { getLanguages } from '../../api/api'
import { getProfessionalProfile, updateProfessionalProfile } from '../../api/profiles'

import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'
import LocationInput from '../../components/location-input'
import TextareaCustom from '../../components/Textarea-custom/text-area-custom'
import DropDown from '../../components/drop-down'

const BasicInfoForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        getValues,
        setError,
    } = useForm<any>()

    const navigate = useNavigate()
    const profileId = localStorage.userID && Number(localStorage.userID)

    const { data: languages } = useQuery(['languages'], () => getLanguages())
    // const { data: radiusList } = useQuery(['radius'], () => getRadius()) waiting from Aman api: radius

    const { data, status, refetch, isLoading } = useQuery(['profileInfo'], () =>
        getProfessionalProfile(profileId)
    )
    const USER = { ...data?.professional_profile }
    useEffect(() => {
        delete USER.id
        delete USER.user
        USER.email = data?.email
    }, [isLoading])

    useEffect(() => {
        if (USER) {
            reset(USER)
            const date = USER?.date_of_birth
                ? moment(USER?.date_of_birth, 'DDMMYYYY').format('YYYY-MM-DD')
                : ''
            setValue('date_of_birth', date)
        }
    }, [data])

    const [isEditorOpen, setEditorOpen] = useState(false)
    const [image, setImage] = useState('')
    const [forUpdatePhoto, setUpdatePhoto] = useState(false)
    const [sending, setIsSending] = useState(false)
    const hiddenFileInput = useRef() as any
    const [radius, setRadius] = useState(null) as any
    const onSubmit = async (data: any) => {
        let res
        const language = data.language?.map((item: any) => item.id)
        data.date_of_birth = data.date_of_birth || null
        const treatment_cat = data.treatment_cat?.map((category: any) => category.id)
        const newProfile = { email: data.email, professional_profile: {} }
        delete data.email
        delete data.photo
        newProfile.professional_profile = {
            ...data,
            language: language,
            treatment_cat: treatment_cat,
        }
        setIsSending(true)
        try {
            res = await updateProfessionalProfile(profileId, newProfile)
            showToast('success', 'Changes have been saved')
            setIsSending(false)
            return res
        } catch (error: any) {
            if (error.response.data?.professional_profile) {
                Object.keys(error.response.data?.professional_profile).forEach((item: any) => {
                    return setError(item, {
                        message: error.response.data?.professional_profile[item][0],
                    })
                })
            } else {
                return toast.error('Something went wrong...')
            }
        } finally {
            setIsSending(false)
        }
    }

    function handleClick() {
        hiddenFileInput.current.click()
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const fileUploaded = event.target.files && event.target.files[0]
        if (fileUploaded) {
            const imgURL = URL.createObjectURL(fileUploaded)
            setImage(imgURL)
            setEditorOpen(true)
        }
    }

    function closeAvatarEditor() {
        hiddenFileInput.current.type = 'text'
        hiddenFileInput.current.type = 'file'
        setEditorOpen(false)
    }

    useEffect(() => {
        const media = window.matchMedia('(max-width: 768px)')
        if (media.matches) {
            window.scrollTo(0, 60)
        } else {
            window.scrollTo(0, 90)
        }
    }, [])

    return (
        <>
            <div className="account-basic-info-wrapper">
                {isEditorOpen ? (
                    <AvatarComponent
                        isRoom={false}
                        refetch={refetch}
                        img={image}
                        closeFn={closeAvatarEditor}
                        userID={profileId}
                        updatePhotoFn={() => setUpdatePhoto(!forUpdatePhoto)}
                    />
                ) : null}

                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className={'photo-zone'}>
                            <Dropzone refetch={refetch} data={status === 'success' && data} />
                            <input
                                ref={hiddenFileInput}
                                type="file"
                                onChange={handleChange}
                                style={{ display: 'none' }}
                            />
                            <div className="userProfile_photo" onClick={handleClick}>
                                {USER.photo ? (
                                    <img src={USER.photo} alt="userImg" />
                                ) : (
                                    <Icon name="user-placeholder-business" width={64} height={64} />
                                )}
                                <div className="hover_info">
                                    <p>Upload photo</p>
                                </div>
                                <div className="icon_for_photo">
                                    <Icon name={'photo_white'} />
                                </div>
                            </div>
                        </div>

                        <form className="container basic-info" onSubmit={handleSubmit(onSubmit)}>
                            <div className="basic-info__grid-view">
                                <Input
                                    label="First name*"
                                    placeholder="Enter your first name"
                                    className="basic-info-input"
                                    useForm={register('name', {
                                        required: 'This field is required',
                                    })}
                                    error={errors.name?.message}
                                />
                                <Input
                                    label="Email*"
                                    placeholder="Enter your email"
                                    className="basic-info-input"
                                    useForm={register('email', {
                                        required: 'This field is required',
                                    })}
                                    error={errors.email?.message}
                                />
                                <Input
                                    label="Last name*"
                                    placeholder="Enter your last name"
                                    className="basic-info-input"
                                    useForm={register('surname', {
                                        required: 'This field is required',
                                    })}
                                    error={errors.surname?.message}
                                />
                                <div className="language-field">
                                    <h4>Language</h4>
                                    <Multiselect
                                        options={languages}
                                        selectedValues={getValues('language')}
                                        displayValue="title"
                                        placeholder="Choose languages"
                                        hidePlaceholder
                                        onRemove={(e) => {
                                            setValue('language', e)
                                        }}
                                        onSelect={(e: any) => {
                                            setValue('language', e)
                                        }}
                                        customCloseIcon={
                                            <Icon name={'close'} width={15} height={15} />
                                        }
                                        {...register('language', {})}
                                        className="multiselect"
                                    />
                                </div>
                                <Input
                                    label="Date of birth"
                                    placeholder="Enter your date of birth"
                                    type="date"
                                    autoComplete="off"
                                    className="basic-info-input"
                                    style={{ minHeight: 46 }}
                                    useForm={register('date_of_birth', {
                                        required: false,
                                    })}
                                    error={errors.date_of_birth?.message}
                                />

                                <Input
                                    label="Radius*"
                                    placeholder="Choose radius"
                                    className="basic-info-input"
                                    type={'number'}
                                    min={2}
                                    max={10}
                                    useForm={register('radius', {
                                        required: 'This field is required',
                                        valueAsNumber: true,
                                    })}
                                    error={errors.radius?.message}
                                />
                                {/*<div className="language-field">*/}
                                {/*    <h4>Radius</h4>*/}
                                {/*    <DropDown*/}
                                {/*        categories={[1, 2, 3, 4]}*/}
                                {/*        setCategory={(filter: number | undefined) =>*/}
                                {/*            'setRadius(filter)'*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*</div>*/}

                                <Input
                                    label="Phone number*"
                                    placeholder="Enter your phone number"
                                    className="basic-info-input"
                                    type={'number'}
                                    useForm={register('phone', {
                                        required: 'This field is required',
                                        valueAsNumber: true,
                                    })}
                                    error={errors.phone?.message}
                                />
                                <LocationInput />
                            </div>
                            <div className="basic-info__textarea">
                                <h4>Description</h4>
                                <TextareaCustom
                                    defaultValue={data?.professional_profile?.description}
                                    register={register}
                                    register_key={'description'}
                                    maxLength={1000}
                                />
                            </div>
                            <Button text={'update'} loading={sending} type={'submit'} />
                        </form>
                    </>
                )}
                <div className="container">
                    <button
                        className="delete-account"
                        onClick={() => {
                            navigate('/account/delete')
                        }}
                    >
                        Delete my account
                    </button>
                </div>
            </div>
        </>
    )
}
export default observer(BasicInfoForm)
