import React, { FC } from 'react'
import DropDownTime from '../drop-down-time'
import Icon from '../icon'
import { schedule_times } from '../../utils/schedule-times'
import { iWorkingHoursTime } from './interface'
import './styles.scss'

type Props = iWorkingHoursTime
const WorkingHoursTime: FC<Props> = ({
    isActiveDay,
    deleteTimeSlots,
    addTimeSlots,
    setValue,
    day_index,
    index,
    time_slot,
    previous_time,
}) => {
    const start_time_index = schedule_times.findIndex((time) => time === time_slot.start_time)
    const current_time_index = schedule_times.findIndex((time) => time === previous_time)
    const startSchedule = schedule_times.slice(
        previous_time ? current_time_index + 2 : 0,
        schedule_times.length - 2
    )
    const endSchedule = schedule_times.slice(start_time_index + 2, 100)
    if (!startSchedule.length) {
        setTimeout(() => {
            deleteTimeSlots(index)
        }, index + 1)
    }

    return (
        <div className="day_time">
            {!!startSchedule.length && (
                <>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {!day_index && !index && <span className={'label'}>From</span>}
                        {isActiveDay && (
                            <DropDownTime
                                schedule={startSchedule}
                                onChange={(value) => {
                                    setValue(`${day_index}.time_slots.${index}.start_time`, value)
                                }}
                                selectKey={`${day_index}-${index}start`}
                                zIndex={100 - day_index - index * 2}
                                key={`${index}start`}
                                value={time_slot.start_time}
                            />
                        )}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {!day_index && !index && (
                            <span className={'label'} style={{ color: 'transparent' }}>
                                F
                            </span>
                        )}
                        {isActiveDay && (
                            <Icon
                                className={'minus-icon'}
                                style={{ cursor: 'default' }}
                                name={'minus'}
                            />
                        )}
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {!day_index && !index && <span className={'label'}>To</span>}

                        {isActiveDay && (
                            <DropDownTime
                                schedule={endSchedule}
                                onChange={(value) =>
                                    setValue(`${day_index}.time_slots.${index}.end_time`, value)
                                }
                                selectKey={`${day_index}-${index}end`}
                                zIndex={100 - 1 - day_index - index * 2}
                                key={`${index}end`}
                                value={time_slot.end_time}
                            />
                        )}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {!day_index && !index && (
                            <span className={'label'} style={{ color: 'transparent' }}>
                                F
                            </span>
                        )}
                        {isActiveDay &&
                            (index ? (
                                <Icon name={'trash'} hover onClick={() => deleteTimeSlots(index)} />
                            ) : (
                                <Icon
                                    style={
                                        addTimeSlots
                                            ? {}
                                            : {
                                                  pointerEvents: 'none',
                                                  userSelect: 'none',
                                                  cursor: 'default',
                                                  opacity: '0.5',
                                              }
                                    }
                                    name={'plus_not_circle'}
                                    hover
                                    onClick={addTimeSlots}
                                />
                            ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default WorkingHoursTime
