import React from 'react';

function ClientsIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7148 7.42871H9.71484M17.7148 12.0001H9.71484H17.7148ZM17.7148 16.5716H9.71484H17.7148Z" stroke="currentColor" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.28625 8.57136C6.85767 8.57136 7.4291 7.99993 7.4291 7.4285C7.4291 6.85707 6.85767 6.28564 6.28625 6.28564C5.71482 6.28564 5.14453 6.85707 5.14453 7.4285C5.14453 7.99993 5.71482 8.57136 6.28625 8.57136ZM6.28625 13.1428C6.85767 13.1428 7.4291 12.5714 7.4291 11.9999C7.4291 11.4285 6.85767 10.8571 6.28625 10.8571C5.71482 10.8571 5.14453 11.4285 5.14453 11.9999C5.14453 12.5714 5.71482 13.1428 6.28625 13.1428ZM6.28625 17.7142C6.85767 17.7142 7.4291 17.1428 7.4291 16.5714C7.4291 15.9999 6.85767 15.4285 6.28625 15.4285C5.71482 15.4285 5.14453 15.9999 5.14453 16.5714C5.14453 17.1428 5.71482 17.7142 6.28625 17.7142Z" fill="currentColor"/>
        </svg>

    )
}

export default ClientsIcon
