import { FC, useMemo, useState } from 'react'
import { SalesSummaryProps } from './interface'
import './styles.scss'
import { useQuery } from 'react-query'
import { getDashboardSalesSummary } from '../../../api/dashboard'
import moment from 'moment/moment'
import { getNextMonth, getStartMonth } from '../../../utils/format-date'
import FromToDatepicker from '../../../components/from-to-datepicker'
import { Line } from 'react-chartjs-2'
import { dataChart, options, plugins } from './data'
type Props = SalesSummaryProps

const SalesSummary: FC<Props> = () => {
    const [filters, setFilters] = useState({
        from: moment(getStartMonth(5)).format('YYYY-MM-DD'),
        to: moment(getNextMonth()).format('YYYY-MM-DD'),
    })

    const { data: summarySales } = useQuery(['sales-summary', filters], () => getDashboardSalesSummary(filters))

    dataChart.labels = summarySales?.sales_summary.labels
    const data = useMemo(() => {
        dataChart.labels = summarySales?.sales_summary.labels
        dataChart.datasets = dataChart.datasets.map((dataset, index) => {
            return { ...dataset, ...summarySales?.sales_summary.datasets[index] }
        })
        return dataChart
    }, [summarySales])

    return (
        <div className="sales-summary white-card">
            <div className="sales-summary-head">
                <h4>Sales summary</h4>
                <FromToDatepicker setFilters={setFilters} monthRatio={5} />
            </div>

            <div className={'sales-summary-chart-box'}>
                <div className="summary-chart">
                    <Line data={data} options={options as any} plugins={plugins as any} />
                </div>
            </div>
        </div>
    )
}
export default SalesSummary
