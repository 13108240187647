import { makeAutoObservable, observable } from 'mobx'

class TopBarStore {
    userDropdown = false
    notificationsDropdown = false
    masterModal = false
    constructor() {
        makeAutoObservable(this, {
            userDropdown: observable,
            notificationsDropdown: observable,
            masterModal: observable
        })
    }

    setShowUserDropdown = (value: boolean) => {
        this.userDropdown = value
    }
    setShowNotificationsDropdown = (value: boolean) => {
        this.notificationsDropdown = value
    }

    setMasterModal = (value: boolean) => {
        this.masterModal = value
    }
}

export default new TopBarStore()
