import './styles.scss'
import { FC, useState } from 'react'
import { ClientModalDown } from './intarface'
import statTotal from '../../assets/icons/stats-total.svg'
import statCompleted from '../../assets/icons/stats-completed.svg'
import statCanceled from '../../assets/icons/stats-canceled.svg'
import statDeclined from '../../assets/icons/stats-declined.svg'
import Icon from '../icon'

type Props = ClientModalDown

const ClientListModalDown: FC<Props> = ({ userData }) => {
    const [stats, setStats] = useState(false)

    return (
        <>
            <div className="show-hide" onClick={() => setStats(!stats)}>
                {!stats ? 'Show statistics' : 'Hide statistics'}
                <Icon name={!stats ? 'modal-arrow' : 'modal-arrow-up'} />
            </div>

            <div
                className={
                    stats ? 'modalDownWrapper' : 'modalDownWrapper active'
                }
            >
                <h4>Statistics about the client</h4>
                <div className="stats">
                    <div className="stats__nameImg">
                        <img src={statTotal} alt="statTotal" />
                        <p>Total bookings</p>
                    </div>
                    <div>{userData.statistics.total}</div>
                </div>
                <div className="stats">
                    <div className="stats__nameImg">
                        <img src={statCompleted} alt="statCompleted" />
                        <p>Completed</p>
                    </div>
                    <div>{userData.statistics.completed}</div>
                </div>
                <div className="stats">
                    <div className="stats__nameImg">
                        <img src={statCanceled} alt="statCanceled" />
                        <p>Canceled</p>
                    </div>
                    <div>{userData.statistics.canceled}</div>
                </div>
                <div className="stats">
                    <div className="stats__nameImg">
                        <img src={statDeclined} alt="statDeclined" />
                        <p>Declined by client</p>
                    </div>
                    <div>{userData.statistics.declined}</div>
                </div>
            </div>
        </>
    )
}

export default ClientListModalDown
