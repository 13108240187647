import './styles.scss'
import { FC, useState } from 'react'
import { useQuery } from 'react-query'
import DataTable from 'react-data-table-component'
import { getTeamList, sendInvitation } from '../../../api/api'
import Loader from '../../../components/loader/loader'
import Input from '../../../components/input'
import PaginateComponent from '../../../components/pagination'
import columns from './logicOfData'
import NoClients from '../../../components/noclients-list'
import TeamAccordion from '../../../modules/treatment-room/team-accordion'
import Button from '../../../components/button'
import ModalWrapper from '../../../components/modal-wrapper'
import { showToast } from '../../../utils/toast-function'
import { useForm } from 'react-hook-form'
import NoApprovePage from '../../../components/no-approve-page'
import { paginationText } from '../../../utils/pagination-text'
import { useDebouncedCallback } from 'use-debounce'

const Team: FC = () => {
    const { register, handleSubmit } = useForm<any>()
    const [filters, setFilters] = useState({
        page: 1,
        page_size: 10,
        search: null as any,
    })
    const [isShown, setShow] = useState(false)
    const isApproved = JSON.parse(localStorage.getItem('approved') as string)
    const { data, isLoading } = useQuery(
        ['clientsList', filters],
        () => getTeamList({ ...filters }),
        {
            cacheTime: 0,
        }
    )

    const onSubmit = async (data: any) => {
        try {
            const form = {
                email: data.email,
            }
            await sendInvitation(form)
            showToast('success', 'Invitation sent successfully')
        } catch (error: any) {
            const err = error.response.data.detail
            showToast('danger', err)
        } finally {
            setShow(false)
        }
    }
    const debounceValueFn = useDebouncedCallback((e) => {
        setFilters({ ...filters, page: 1, search: e.target.value })
    }, 600)
    return isApproved ? (
        <>
            <ModalWrapper isShown={isShown} setIsShown={() => setShow(!isShown)}>
                <div className="add-member-modal">
                    <h5>Add team member</h5>
                    <span>Invite one new team member’s email address to join </span>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            label={'Email address'}
                            type={'email'}
                            placeholder={'Enter email address'}
                            useForm={register('email', {
                                required: 'This field is required',
                            })}
                        />
                        <div className="action-btns">
                            <Button
                                text={'CANCEL'}
                                outlined
                                type={'reset'}
                                onClick={() => setShow(false)}
                            />
                            <Button text={'SEND'} />
                        </div>
                    </form>
                </div>
            </ModalWrapper>
            <div className="team ">
                <div className="team-top container">
                    <h2 className="header">Team</h2>
                    <div>
                        <Button text={'+ ADD a TEAM MEMBER'} onClick={() => setShow(!isShown)} />
                    </div>
                </div>

                <div className="team__background container">
                    <Input
                        icon="search"
                        placeholder="Search by team member’s name or email"
                        onChange={debounceValueFn}
                    />
                    {isLoading ? (
                        <Loader transparent />
                    ) : !data.results.length ? (
                        <NoClients warning="No team member" />
                    ) : (
                        <>
                            <div className="dataTableWrapper">
                                <DataTable
                                    columns={columns}
                                    data={data.results}
                                    responsive
                                    paginationTotalRows={data.total_count}
                                    paginationPerPage={data.page_size}
                                    highlightOnHover={true}
                                />
                            </div>
                            <div className="accordionWrapper">
                                <TeamAccordion data={data} />
                            </div>

                            <div className="paginationDiv">
                                <div className="paginationDiv__text">
                                    {paginationText(data, 'team members')}
                                </div>

                                <PaginateComponent
                                    onChange={(e: number) =>
                                        setFilters({
                                            ...filters,
                                            page: e,
                                        })
                                    }
                                    hidden={
                                        !data.results.length || data.total_count <= data.page_size
                                    }
                                    total={data.total_count}
                                    defaultCurrentPage={1}
                                    defaultPageSize={data.page_size}
                                    currentPage={filters.page}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    ) : (
        <NoApprovePage />
    )
}

export default Team
