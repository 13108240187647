import './styles.scss'
import React, { FC } from 'react'
import Button from '../button'
import { OnboardingProcess } from './interface'
import { useNavigate } from 'react-router-dom'

type Props = OnboardingProcess
const OnboardingModal: FC<Props> = ({ name, surname, completed, isRoom, setShowModal }) => {
    const navigate = useNavigate()
    const handleNavigate = () => {
        !isRoom && navigate('/onboarding-process')
        setShowModal && setShowModal()
    }
    return (
        <div className={'onboarding-modal'}>
            <h5>
                Hey , {name} {surname}
            </h5>
            {completed ? <h3>Onboarding is completed!</h3> : <h3>Thanks for signing up!</h3>}
            {!completed ? (
                <p>
                    To start using your account, please complete the Onboarding process by clicking
                    the button below:{' '}
                </p>
            ) : (
                <p>
                    To start using your account, please wait until your onboarding documents will be
                    approved{' '}
                </p>
            )}
            {completed ? (
                <Button text={'GO TO MAIN PAGE'} onClick={() => navigate('/')} />
            ) : (
                <Button text={'complete onboarding'} onClick={handleNavigate} />
            )}
            <div className={'line'} />
            <span>Thanks,</span>
            <span>The GlowApp Team</span>
        </div>
    )
}
export default OnboardingModal
