import { FC, useState } from 'react'
import './styles.scss'
import Icon from '../../components/icon'
import Button from '../../components/button'
import { useParams } from 'react-router-dom'
import { getOnboardingDocuments, profileVerification } from '../../api/onboarding-documents'
import { showToast } from '../../utils/toast-function'
import ModalWrapper from '../../components/modal-wrapper'
import { OnboardingProps } from './interface'
import { useQuery } from 'react-query'

type Props = OnboardingProps
const OnboardingVerification: FC<Props> = ({ forRoom = false }) => {
    const [showApproved, setShowApproved] = useState(false)
    const [showReject, setShowReject] = useState(false)
    const [sending, setIsSending] = useState(false)

    const { uid, token } = useParams()
    const { data } = useQuery('docs', () =>
        getOnboardingDocuments(forRoom ? 'room' : 'prof', uid, token)
    )

    const handleSubmit = async (res: boolean) => {
        setIsSending(true)
        const data = {
            approved: res,
        }
        try {
            await profileVerification(forRoom ? 'room' : 'prof', uid, token, data)
            res ? setShowApproved(true) : setShowReject(true)
        } catch (error: any) {
            showToast('danger', 'Something went wrong. Try again later')
        } finally {
            setIsSending(false)
        }
    }
    const profile = data ? data?.professional_profile : {}
    return (
        <>
            <ModalWrapper isShown={showApproved} setIsShown={() => setShowApproved(!showApproved)}>
                <div className="verification-res">
                    <h4>You have just approved the onboarding process</h4>
                    <span>The candidate has a full access to their account now</span>
                </div>
            </ModalWrapper>

            <ModalWrapper isShown={showReject} setIsShown={() => setShowReject(!showReject)}>
                <div className="verification-res">
                    <h4>You have just rejected the onboarding process</h4>
                    <span>The candidate has no access to their account now</span>
                </div>
            </ModalWrapper>
            <div>
                <div className="verification-top">
                    <Icon name={'logo-business'} width={84} height={84} />
                    <h2>Onboarding process</h2>
                </div>
                <div className="verification-profile">
                    <h3 className="verification-name">Information about the candidate</h3>
                    <div className="info-block">
                        <span>Legal Name</span>
                        <p>{profile?.name}</p>
                    </div>
                    <div className="info-block">
                        <span>Legal Surname</span>
                        <p>{profile?.surname}</p>
                    </div>
                    <div className="info-block">
                        <span>Phone number</span>
                        <p>{profile?.phone}</p>
                    </div>
                    <div className="info-block">
                        <span>Email address</span>
                        <p>{data ? data?.email : ''}</p>
                    </div>
                    <div className="info-block">
                        <span>Location</span>
                        <p>{profile?.address}</p>
                    </div>
                    <div className="info-block">
                        <span>Description</span>
                        <p>{profile?.description}</p>
                    </div>
                    <h3 className="verification-name">Onboarding documents</h3>
                    <div>
                        {profile?.document_categories?.map((item: any, index: any) => (
                            <div key={index}>
                                <div className="verification-document_title">{item?.title}</div>
                                <div>
                                    {item?.documents.map((ti: any) => (
                                        <a
                                            href={ti.document}
                                            target="_blank"
                                            className="verification-document_a"
                                            rel="noreferrer"
                                        >
                                            {ti.file_name
                                                ? ti.file_name
                                                : `${item.title}.${
                                                      /[.]/.exec(ti.document)
                                                          ? /[^.]+$/.exec(ti.document)
                                                          : undefined
                                                  }`}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="container verification-btn">
                    <Button text={'APPROVE'} onClick={() => handleSubmit(true)} loading={sending} />
                    <Button
                        text={'REJECT'}
                        outlined
                        onClick={() => handleSubmit(false)}
                        loading={sending}
                    />
                </div>
            </div>
        </>
    )
}

export default OnboardingVerification
