import './styles.scss'
import { FC, useState } from 'react'
import { ClientProps } from './interface'
import Icon from '../icon'
import CustomCheckbox from '../custom-checkbox'
import Button from '../button'
import { getCreatePersonalAccount } from '../../api/profiles'

type Props = ClientProps

const MasterModal: FC<Props> = ({ active, setActive }) => {
    const [activeCheckbox, setActiveCheckbox] = useState(false)
    return active ? (
        <div className={active ? 'masterModal active' : 'masterModal'} onClick={() => setActive(false)}>
            <div
                className={active ? 'masterModal__content active' : 'masterModal__content'}
                onClick={(e) => e.stopPropagation()}
            >
                <div>
                    <div className="header">
                        <h2>Create a personal account </h2>
                        <Icon onClick={() => setActive(false)} name="x" hover width={40} />
                    </div>
                    <div className="checkbox-text">
                        <div>
                            <input
                                className="checkbox"
                                onChange={() => setActiveCheckbox(!activeCheckbox)}
                                type="checkbox"
                                required
                            />
                            <CustomCheckbox active={activeCheckbox} width={30} />
                        </div>
                        <p>
                            By submitting this you agree to have a personal account as a client with the same Login name
                            and Password.
                        </p>
                    </div>
                    <div className="link-text">
                        *To use your personal account further please login from the Client’s website:
                        <span>
                            <a href="http://www.glowapp.co.uk./"> www.glowapp.co.uk.</a>{' '}
                        </span>
                    </div>
                    <div className="btns">
                        <div>
                            <Button outlined text={'cancel'} onClick={() => setActive(false)} />
                        </div>

                        <Button
                            onClick={() => {
                                getCreatePersonalAccount()
                                setActive(false)
                            }}
                            disabled={!activeCheckbox}
                            text={'approved'}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default MasterModal
