import { makeAutoObservable, observable } from 'mobx'

class LocationModalStore {
    isShownModal = false
    canClose = true
    constructor() {
        makeAutoObservable(this, {
            isShownModal: observable,
            canClose: observable,
        })
    }
    setShowLocationModal = (bool: boolean) => {
        this.isShownModal = bool
    }
    setCanClose = (bool: boolean) => {
        this.canClose = bool
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new LocationModalStore()
