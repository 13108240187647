import './style.scss'

import React, { FunctionComponent } from 'react'
import Header from '../sidebar'

import { LayoutProps } from './interface'
import Footer from '../footer'
import 'react-toastify/dist/ReactToastify.css'
import TopBar from '../topbar'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { handleClickOutside } from '../../utils/click-outside'
import Sidebar from '../../store/sidebar'

type Props = LayoutProps

const PageWrapper: FunctionComponent<Props> = () => {
    return (
        <div className="layout" onClick={handleClickOutside}>
            <Header />
            <div className={`layout-content ${Sidebar.expander && 'big'}`}>
                <TopBar />
                <div>
                    <Outlet />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default observer(PageWrapper)
