import { formatMinutes } from "./timeToHours"

function createArr () {
    let mins = 0
    const arr = []
    while(mins < 480) {
        mins += 30  
        arr.push(mins)
    }
    return arr
}

export const duration = createArr().map((mins) => (
    {id: mins, title: formatMinutes(mins)}
))