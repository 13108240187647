import { FunctionComponent } from 'react'
import { CardSliderInterFace } from './interface'
import Slider from 'react-slick'
import Icon from '../icon'
import './styles.scss'

type Props = CardSliderInterFace

function Arrow(props: any) {
    const { name, onClick } = props
    return (
        <div onClick={onClick} className={`custom-arrow custom-${name}`}>
            <Icon hover name={name} />
        </div>
    )
}

const CardSlider: FunctionComponent<Props> = ({ children, className }) => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: `card_slider ${className}`,
        variableWidth: false,
        centerMode: false,
        arrows: true,
        nextArrow: <Arrow name={'arrow-next'} />,
        prevArrow: <Arrow name={'arrow-prev'} />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                },
            },
        ],
    }

    return (
        <div onClick={(e) => e.preventDefault()}>
            <Slider {...settings}>{children}</Slider>
        </div>
    )
}

export default CardSlider
