import { TableColumn } from 'react-data-table-component'

const columnsIndex: TableColumn<any>[] = [
    {
        name: 'FULL NAME',
        cell: (row) => {
            return (
                <div className="logicOfData">
                    {row.photo ? (
                        <img className="clientsPhoto" style={{ width: '30px' }} src={row.photo} alt={'client-avatar'} />
                    ) : (
                        <img
                            className="defaultClientsPhoto"
                            src={require('../../../assets/images/default_user.png')}
                            alt="img"
                        />
                    )}
                    <p>
                        {row.name} {row.surname}
                    </p>
                </div>
            )
        },
    },
    {
        name: 'TOTAL',
        minWidth: '100px',
        maxWidth: '100px',
        center: true,
        sortable: true,
        selector: (row) => `${row.total}£`,
    },
]

export default columnsIndex
