import './styles.scss'
import LogoChat from '../../assets/images/chat-logo.svg'

import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

/** Components **/
import Icon from '../../components/icon'
import { render_message } from './message'

/** Store **/
import ChatStore from '../../store/chat'
import UserStore from '../../store/user'

/** APIs **/
import { getMessages, sendMessage } from '../../api/chat'

/** Utils **/
import { scrollToBottom } from './utils'
import Loader from '../../components/loader/loader'
import EmptyChat from './empty-chat'

const Chat = ({ booking_data }: { booking_data: object }) => {
    const ws_api = process.env['REACT_APP_WS_URL']
    const { client, id, status: booking_status } = booking_data as any

    const hideFooter = booking_status === 'declined' || booking_status === 'canceled'

    const { isShownChat, setShowChat } = ChatStore
    const { user } = UserStore

    const [message, setMessage] = useState('')
    const [status, setStatus] = useState(false)
    const [messages, setMessages] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const ws = useRef<any>(null)

    useEffect(() => {
        ws.current = new WebSocket(`${ws_api}${id}/${user?.email}/`)

        const openWebSocket = () => {
            ws.current.onopen = () => {
                setLoading(true)
                setStatus(true)
                ws.current.send(getMessages(client?.user_email, id))
            }
            ws.current.onclose = () => {
                setStatus(false)
            }
        }
        ws.current.onerror = (e: any) => {
            // window.location.href = `/bookings/${id}`
            setStatus(false)
        }
        openWebSocket()

        return () => {
            ws.current.close()
        }
    }, [id])

    const messageStrategy = (parsed: any) => {
        const types = {
            get_paginated_booking_messages: () => {
                setMessages(parsed?.content?.messages.reverse())
                setLoading(false)
                scrollToBottom()
            },
            send_booking_message: () => {
                setMessages((prev: any) => [...prev, parsed?.content])
                scrollToBottom('smooth' as any)
            },
        } as any
        return types[parsed?.type]()
    }

    useEffect(() => {
        if (ws.current !== null) {
            ws.current.onmessage = (event: any) => {
                const data = JSON.parse(event.data)
                if (data.message?.error_code) {
                    console.log('ERROR', data.message)
                } else {
                    messageStrategy(data.message)
                }
            }
        }
    }, [ws?.current?.readyState])

    useEffect(() => {
        scrollToBottom()
    }, [isShownChat])

    return (
        <div className="chat-container">
            {isShownChat ? (
                <div className="chat-wrapper">
                    <div className="header">
                        <div>
                            <img src={LogoChat} alt="logo-chat" />
                        </div>
                        <div onClick={() => setShowChat(false)} className="close-button">
                            <Icon name="chevron-down-white" />
                        </div>
                    </div>
                    <div
                        style={
                            hideFooter
                                ? {
                                    borderBottomLeftRadius: 14,
                                    maxHeight: 'calc(100vh - 55px)',
                                } as any
                                : null
                        }
                        className="body">
                        {loading ? (
                            <Loader transparent={true} />
                        ) : messages?.length ? (
                            messages?.map((item: any, index: number) => render_message(item, index, user?.email))
                        ) : (
                            <EmptyChat />
                        )}
                        <div id="bottom-chat" />
                    </div>
                    {hideFooter ? null : <form className="footer">
                        <input
                            type="text"
                            id="chat-textarea"
                            value={message.trimStart()}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type your message..."
                        />
                        <button
                            disabled={!message?.length}
                            style={!message?.length ? {opacity: 0.8, cursor: 'not-allowed'} : {}}
                            onClick={(e) => {
                                e.preventDefault()
                                ws.current.send(sendMessage(client?.user_email, message, id))
                                ws.current.send(getMessages(client?.user_email, id))
                                setMessage('')
                            }}
                            type="submit"
                            className="send-button"
                        >
                            <Icon name="chevron-right-grey" />
                        </button>
                    </form>}
                </div>
            ) : null}
            <div
                onClick={() => {
                    setShowChat(!isShownChat)
                    ws.current.send(getMessages(client?.user_email, id))
                }}
                className="chat-button"
            >
                <Icon name={'message-white'} />
            </div>
        </div>
    )
}
export default observer(Chat)
