import './styles.scss'
import { FC, useEffect, useState } from 'react'
import Icon from '../icon'
import { prop } from './interface'

type Props = prop
const UploadInput: FC<Props> = ({
    onchange,
    fileTxt,
    useForm,
    explanation,
    error,
    index,
    catId,
    clearClick,
    fileType,
    style,
    ...props
}) => {
    const [currentName, setCurrentName] = useState<any>('Choose the file')

    useEffect(() => {
        if (fileTxt) setCurrentName(fileTxt)
    }, [fileTxt])
    return (
        <>
            <div className="upload-doc-wrapper" style={style}>
                <span className="explanation">{explanation}</span>
                <div className="d-flex w-100 align-items-center gap-10">
                    <label
                        form={'doc' + index}
                        className={`doc-upload  ${error ? 'input-primary-error' : ''}`}
                    >
                        <span className="upload-placeholder">{currentName}</span>

                        <span className={'upload-txt'}>upload</span>

                        <input
                            type={'file'}
                            {...props}
                            {...useForm}
                            hidden
                            accept={fileType}
                            id={'doc' + index}
                        />
                    </label>
                    {!!clearClick && (
                        <Icon
                            name={'remove-doc'}
                            onClick={(e) => {
                                setCurrentName('Choose the file')
                                e.preventDefault()
                                clearClick()
                            }}
                        />
                    )}
                </div>
                <span className={'error-msg'}>{error}</span>
            </div>
        </>
    )
}
export default UploadInput
