const token = localStorage.getItem('token')

export const getMessages = (email, id) => {
    const query = {
        skip: 0,
        limit: 500,
        booking_id: id,
        with_email: email,
        command: 'get_paginated_booking_messages',
        access_token: token,
    }
    return JSON.stringify(query)
}

export const sendMessage = (email, message, id) => {
    const query = {
        booking_id: id,
        to_email: email,
        message_text: message,
        command: 'send_booking_message',
        access_token: token,
    }
    return JSON.stringify(query)
}
