import { FC } from 'react'
import './styles.scss'
import { NoApprovePageProps } from './interface'
import noApprove from '../../assets/images/no-approve.svg'
import Icon from '../icon'
type Props = NoApprovePageProps

const NoApprovePage: FC<Props> = () => {

    return <div className='no-approve container'>
        <img src={noApprove} alt='no-approve' />
        <h3>Not approved yet</h3>
        <p>Our team will aprove your account very soon. Fortunately, it doesn’t take long time!</p>
        <div className={'bottom-text'}>We are happy to see you here! <Icon name={'heart'} width={24} /></div>
    </div>
}

export default NoApprovePage