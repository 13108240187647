import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../components/button'
import Input from '../../components/input'
import { login } from '../../api/auth'
import { SignInFormProps } from './interface'
import './styles.scss'
import ModalWrapper from '../../components/modal-wrapper'
import ForgotPassword from '../forgot-password-modal'
import { observer } from 'mobx-react-lite'

type Props = SignInFormProps

const SignInForm: FC<Props> = observer(({ setLogin }) => {
    const [isLoading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const {
        register,
        handleSubmit,
        resetField,
        setError,
        formState: { errors },
    } = useForm()

    const onSubmit = async (data: any) => {
        setLoading(true)
        let res
        try {
            res = await login(data)
            if (res.user_type === 'room' || res.approved || res.completed) {
                document.location.href = '/'
            } else {
                res.user_type === 'prof' && (document.location.href = '/onboarding-process')
            }
            return res
        } catch (error: any) {
            resetField('password')
            error.response?.status === 401
                ? setError('email', {
                      type: 'custom',
                      message: 'E-mail or password is incorrect',
                  })
                : setError('email', {
                      type: 'custom',
                      message: 'Something went wrong',
                  })
        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            <div className="signIn-container">
                {/*<Icon className={'arrow-back'} name="arrow-back-login" onClick={() => navigate(-1)} />*/}
                <div className="mobile_info">
                    <h2>Log in</h2>
                    <p>
                        Haven’t joined us yet?{' '}
                        <span onClick={() => setLogin(false)}>Create an account</span>
                    </p>
                </div>
                <form
                    className={`signIn ${isLoading && 'loading'}`}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Input
                        label="E-mail*"
                        type="email"
                        placeholder="Enter your e-mail address*"
                        useForm={register('email', {
                            required: 'This field is required',
                        })}
                        error={errors.email?.message}
                    />
                    <Input
                        label="Password"
                        placeholder="Enter your password"
                        passwordEye
                        useForm={register('password', {
                            required: 'This field is required',
                        })}
                        error={errors.password?.message}
                    />
                    <div className="remember-forgot justify-content-end">
                        <p className="forgot_password" onClick={() => setShowModal(true)}>
                            Forgot your password?
                        </p>
                    </div>
                    <Button text="log in" loading={isLoading} />
                </form>
            </div>
            <ModalWrapper isShown={showModal} setIsShown={setShowModal}>
                <ForgotPassword setShowModal={setShowModal} isShownModal={showModal} />
            </ModalWrapper>
        </>
    )
})

export default SignInForm
