import { FC, useState } from 'react'
import { MasterWorksProps } from './interface'
import './styles.scss'
import Reactions from '../reactions'
import CardSlider from '../card-slider'
import Icon from '../icon'
import { Link } from 'react-router-dom'
import ModalWrapper from '../modal-wrapper'
import Button from '../button'
import { deleteWork } from '../../api/prof-works'
import { showToast } from '../../utils/toast-function'

type Props = MasterWorksProps

const MasterWorksCard: FC<Props> = ({ work, deleteWorkListener }) => {
    const [isShownOptions, setShownOptions] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const [isSending, setIsSending] = useState(false)

    const deleteThisWork = async () => {
        let res
        setIsSending(true)
        try {
            res = await deleteWork(work.id)
            showToast('success', 'Work is deleted succesfully.')
            setModalOpen(false)
            deleteWorkListener((prev: any) => !prev)
            return res
        } catch (error) {
            console.dir(error)
            showToast('danger', 'SomeThing went wrong. Try again later.')
        } finally {
            setIsSending(false)
        }
    }

    return (
        <>
            <ModalWrapper isShown={isModalOpen} setIsShown={setModalOpen}>
                <div className="deleteModal">
                    <h2>Are you sure?</h2>
                    <p>This action cannot be undone</p>
                    <div className="delete-btns">
                        <Button
                            text={'cancel'}
                            onClick={() => setModalOpen(false)}
                            loading={isSending}
                        />
                        <Button
                            text={'delete'}
                            outlined
                            onClick={deleteThisWork}
                            loading={isSending}
                        />
                    </div>
                </div>
            </ModalWrapper>
            <div className="masterWorksCard">
                <CardSlider className="masterWorksCard_slider">
                    {work.photos.map((img: any) => (
                        <img key={img.id} className="slider_img" src={img.photo} alt="slider_img" />
                    ))}
                </CardSlider>
                <div className="masterWorksCard_title">
                    <b>
                        {work?.treatment.subcategory
                            ? work?.treatment?.subcategory?.title
                            : work?.treatment?.name}
                    </b>
                    <div className="edit-work" onMouseLeave={() => setShownOptions(false)}>
                        <Icon
                            name="three-dots"
                            hover
                            onClick={() => setShownOptions(!isShownOptions)}
                        />
                        <div className={`edit-work_options ${isShownOptions && 'isShown'}`}>
                            <Link to={`/edit-work/${work.id}`}>
                                <span onClick={() => setShownOptions(!isShownOptions)}>Edit</span>
                            </Link>
                            <span onClick={() => setModalOpen(true)}>Delete</span>
                        </div>
                    </div>
                </div>
                <div className="masterWorksCard_description">
                    <div className="text">
                        <p>{work.work_description}</p>
                    </div>

                    <Reactions data={work} />
                </div>
            </div>
        </>
    )
}

export default MasterWorksCard
