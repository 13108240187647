import { FC, useState } from 'react'

import './styles.scss'
import CustomCheckbox from '../../components/custom-checkbox'

interface IOptions {
    setValue: any
    data: {
        id: number
        description: string
    }
}
type Props = IOptions
const DeleteAccountOptions: FC<Props> = ({ setValue, data }) => {
    const [active, setActive] = useState(false)

    const handleClick = () => {
        console.log('RENDER OPTION')
        setValue(active ? null : data.id)
        setActive(!active)
    }
    return (
        <>
            <div className="delete-option" onClick={handleClick}>
                <CustomCheckbox width={25} active={active} />
                <span>{data?.description}</span>
            </div>
        </>
    )
}

export default DeleteAccountOptions
