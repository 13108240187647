import React, { FC } from 'react'
import './styles.scss'
import Switch from '../../components/switch-input'
import { iTimeSlot, iWorkingDay } from './interface'
import WorkingHoursTime from '../../components/working-hours-time-select'

const WorkingHoursDay: FC<iWorkingDay> = ({ watch, setValue, day_index, day }) => {
    const time_slots = watch(`${day_index}.time_slots`)
    const isActiveDay = watch(`${day_index}.active`)
    const last_time_slot = Number(time_slots?.at(-1).end_time.split(':')[0])
    const addTimeSlots = () => {
        time_slots.push({ start_time: '', end_time: '' })
        setValue(`${day_index}.time_slots`, time_slots)
    }
    const deleteTimeSlots = (index: number) => {
        time_slots.splice(index, 1)
        setValue(`${day_index}.time_slots`, time_slots)
    }

    return (
        <div
            className={'workingHoursDay'}
            style={{
                zIndex: 100 - day_index,
                position: 'relative',
                background: day_index % 2 ? 'rgba(158,119,211,0.05)' : 'transparent',
            }}
        >
            <div className="day_container">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {!day_index && <span className={'label'}>Day</span>}
                    <div className="day_name">
                        <Switch
                            defaultChecked={day.active}
                            onChange={(e) => setValue(`${day_index}.active`, e)}
                        />
                        <span className={'weekday_name'}>{day.weekday}</span>
                    </div>
                </div>
                <div className="day_schedule">
                    {time_slots?.map((time_slot: iTimeSlot, index: number) => (
                        <WorkingHoursTime
                            isActiveDay={isActiveDay}
                            deleteTimeSlots={deleteTimeSlots}
                            addTimeSlots={last_time_slot < 22 ? addTimeSlots : null}
                            key={`${day_index}${time_slot.id || `new-${index}`}`}
                            setValue={setValue}
                            day_index={day_index}
                            index={index}
                            time_slot={time_slot}
                            previous_time={index ? time_slots[index - 1]?.end_time : ''}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WorkingHoursDay
