import React, { FC, useEffect, useState } from 'react'
import { iSwitch } from './interface'
import './styles.scss'
const Switch: FC<iSwitch> = ({ onChange, defaultChecked = false }) => {
    const [isAlertChecked, setAlertChecked] = useState(defaultChecked)

    useEffect(() => {
        setAlertChecked(defaultChecked)
    }, [defaultChecked])
    return (
        <label className="switch-input">
            <input
                type={'checkbox'}
                checked={isAlertChecked}
                onChange={() => {
                    onChange(!isAlertChecked)
                    setAlertChecked(!isAlertChecked)
                }}
            />
            <span className="slider"></span>
        </label>
    )
}

export default Switch
