import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import Icon from '../icon'
import { DropDownProps } from './interface'
import './styles.scss'
import { closeByClick } from '../../utils/closing-dropdown-func'

type Props = DropDownProps

const DropDown: FC<Props> = ({
    categories,
    setCategory,
    defaultCategory,
    forAddWork = false,
    zIndex = 1,
    defaultName,
    notAllOption = false,
}) => {
    const [activeFilter, setActiveFilter] = useState(defaultCategory || defaultName)
    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef() as MutableRefObject<HTMLInputElement>
    function select(option: string, id: number | undefined) {
        setActiveFilter(option)
        setIsOpen(false)
        setCategory(id)
    }

    function isActiveOption(name: string) {
        if (activeFilter === name) {
            return 'active_option'
        }
        return ''
    }
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false)
            }
        }
        closeByClick(checkIfClickedOutside)
    }, [ref])
    const IDstring = categories.map((cat) => cat.id).join('-') // для обновления эффекта
    useEffect(() => {
        if (forAddWork) {
            const cat = categories?.find((item) => item.title === defaultCategory)
            select(cat?.title || defaultName || '', cat?.id)
        }
    }, [IDstring, defaultCategory])
    return (
        <div
            className={`dropDown cursor-pointer ${!categories?.length && 'disabled'}`}
            style={{ zIndex: zIndex }}
            ref={ref}
            onClick={() => setIsOpen(!isOpen)}
        >
            <div className="selected">
                <span className={activeFilter === 'All' ? 'all-option' : ''}>{activeFilter}</span>
                <Icon
                    className={isOpen ? 'rotate' : ''}
                    name="arrow-dropdown"
                    width={24}
                    height={24}
                />
            </div>
            <div className={`options ${isOpen && 'opened'}`}>
                {!forAddWork && !notAllOption ? (
                    <span
                        className={isActiveOption('All')}
                        onClick={() => select('All', undefined)}
                    >
                        All
                    </span>
                ) : null}
                {categories?.map((filter: any) => {
                    return (
                        <span
                            key={filter.id}
                            className={isActiveOption(filter.title)}
                            onClick={() => select(filter.title, filter.id)}
                        >
                            {filter.title}
                        </span>
                    )
                })}
            </div>
        </div>
    )
}

export default DropDown
