import './styles.scss'

import React, {FC, useEffect, useRef} from 'react'
import ReplyCard from '../../components/review-reply'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Button from '../../components/button'
import { useQuery } from 'react-query'
import { createReply, getReviewDetails } from '../../api/api'
import { useParams } from 'react-router-dom'
import { replies } from '../../components/review-reply/interface'
import { showToast } from '../../utils/toast-function'
import Loader from '../../components/loader/loader'
import Tooltip from '../../components/tooltip'
import Icon from '../../components/icon'
import ReviewCard from '../../components/review-card'
import BackButton from '../../components/back-arrow-btn'

const MyReviewDetails: FC = () => {
    const { id } = useParams()
    const { data, isLoading, refetch } = useQuery(['reviewDetails'], () => getReviewDetails(id))
    const ref = useRef() as any
    const createReplyFunc = async (e: any) => {
        e.preventDefault()
        const replyBody = {
            review: data?.review?.id,
            message: e.target[0].value,
        }
        try {
            await createReply(replyBody)
            showToast('success', 'Your reply has been sent')
            refetch()
            ref.current.value=''
        } catch (error: any) {
            throw new Error(error)
        }
    }

    useEffect(() => {
        const media = window.matchMedia('(max-width: 768px)')
        if (media.matches) {
            window.scrollTo(0, 60)
        } else {
            window.scrollTo(0, 90)
        }
    }, [])
    return (
        <>
            <div className="review-details-wrapper container">
                <div className="head-title">
                    <BackButton />
                    <h2>
                        My reviews{' '}
                        <Tooltip
                            text={
                                'All reviews are automatically published to your profile in 24 hours. If a review is positive, no action is required from your side. If a review is negative, you have 24 hours to convert a damaging review into a positive one. Please try to address the client’s concern as quickly as possible within 24 hours.'
                            }
                        >
                            <Icon name={'get-info'} width={19} />
                        </Tooltip>
                    </h2>
                </div>
                <div>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <ReviewCard forDetailed data={data?.review} />
                            <div className="replies" style={{ marginBottom: '20px' }}>
                                {data?.replies.map((reply: replies, i: number) => (
                                    <ReplyCard data={reply} key={i} />
                                ))}
                            </div>
                            <form
                                className={`reply-editor ${data?.review?.published && 'disabled'}`}
                                onSubmit={createReplyFunc}
                            >
                                <div className="reply-editor_title">
                                    <span>Text a reply</span>
                                </div>
                                <textarea name="reply"  ref={ref}/>
                                <div className="reply-editor_bottom">
                                    <Button text={'send'} />
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default MyReviewDetails
