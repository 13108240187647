import './styles.scss'

import React, { FC, useEffect, useState } from 'react'
import Button from '../../components/button'
import PaginateComponent from '../../components/pagination'
import NotificationCard from '../../components/notifications-card'
import { useQuery } from 'react-query'
import {
    getNotificationMarkAll,
    getNotificationsList
} from '../../api/notifications'
import Loader from '../../components/loader/loader'
import NoResultFound from '../../components/no-result-found'
import { notification } from '../../components/notifications-card/interface'
import NotificationsCountStore from '../../store/notifications'
import { observer } from 'mobx-react-lite'

const Notifications: FC = () => {
    const [filters, setFilters] = useState({
        page: 1,
        page_size: 10,
    })

    const { notifications, triggerCount } = NotificationsCountStore

    const { data, isLoading } = useQuery(
        ['notifications', filters, notifications],
        () => getNotificationsList({ ...filters })
    )

    const isReadFn = () => {
        getNotificationMarkAll().then(triggerCount)
    }

    useEffect(() => {
        const media = window.matchMedia('(max-width: 768px)')
        if (media.matches) {
            window.scrollTo(0, 60)
        } else {
            window.scrollTo(0, 90)
        }
    }, [])
    return (
        <>
            <div className="notifications-wrapper container">
                <h2>Notifications</h2>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {!data.results.length ? (
                            <NoResultFound />
                        ) : (
                            <div className="notification-cards">
                                {data?.results.map((notice: notification) => (
                                    <NotificationCard
                                        data={notice}
                                        key={notice.id}
                                    />
                                ))}
                            </div>
                        )}
                        <Button
                            text="Mark all as read"
                            outlined
                            className="allread-btn"
                            onClick={isReadFn}
                            disabled={!notifications}
                        />
                    </>
                )}
            </div>
            <PaginateComponent
                onChange={(e: number) =>
                    setFilters({
                        ...filters,
                        page: e,
                    })
                }
                hidden={
                    data?.total_count <= data?.page_size
                }
                total={data?.total_count}
                defaultCurrentPage={1}
                defaultPageSize={data?.page_size}
                currentPage={filters?.page}
            />
        </>
    )
}

export default observer(Notifications)
