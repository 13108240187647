import React, { FC, useEffect, useState } from 'react'
import './styles.scss'
import Input from '../../components/input'
import Button from '../../components/button'
import ModalWrapper from '../../components/modal-wrapper'
import CustomCheckbox from '../../components/custom-checkbox'
import DeleteAccountOptions from '../../modules/delete-account'
import { useNavigate } from 'react-router-dom'
import { user_role } from '../../utils/user-type'
import { useQuery } from 'react-query'
import { deleteAccount, getReasons } from '../../api/auth'
import { useForm } from 'react-hook-form'
import { LogOut } from '../../api/api'
import { showToast } from '../../utils/toast-function'
import Loader from '../../components/loader/loader'

const DeleteAccount: FC = () => {
    const [isShown, setShown] = useState(false)
    const [isOther, setIsOther] = useState(false)

    const navigate = useNavigate()

    const { data: options, isLoading } = useQuery('delete-reasons', () =>
        getReasons({ user_type: user_role })
    )

    const {
        handleSubmit,
        setValue,
        register,
        formState: { errors },
    } = useForm()
    const handleShow = () => {
        setShown(!isShown)
    }

    const onSubmit = (data: any) => {
        deleteAccount(data)
            .then(() => LogOut())
            .catch(() => {
                showToast('danger', 'Something went wrong. Try again later')
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        register('reasons')
    }, [])

    return isLoading ? (
        <Loader />
    ) : (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="container delete-account-page">
                <div className="delete-account-page__top">
                    <h4>We are sad to see you go....</h4>
                    <h6>Before you go, please tell us how can we improve.</h6>
                </div>

                <div className="delete-account-page__select">
                    <h6>Why are you deleting your account? </h6>
                    <div className="select-box">
                        {options?.map((option: any, i: number) => (
                            <DeleteAccountOptions
                                setValue={(e: any) => setValue(`reasons.${i}`, e)}
                                key={i}
                                data={option}
                            />
                        ))}

                        <div className="delete-option" onClick={() => setIsOther(!isOther)}>
                            <div className={'checkbox-container'}>
                                <CustomCheckbox width={25} active={isOther} />
                            </div>
                            <span>Other</span>
                        </div>
                        <Input
                            placeholder={'Please specify'}
                            useForm={register('other_reason')}
                            className={`delete-account-other ${!isOther && 'd-none'}`}
                        />
                    </div>
                    <Input
                        placeholder={'Please let us know'}
                        useForm={register('feedback')}
                        label={'What could we do to improve?'}
                        className="delete-account-input"
                        type={'text'}
                    />
                    <Input
                        label={'Confirm Password*'}
                        useForm={register('confirm_password', {
                            required: 'This filed is required',
                        })}
                        placeholder={'Enter your password*'}
                        autoComplete={'new-password'}
                        type={'password'}
                        error={errors?.confirm_password?.message}
                        className="delete-account-input"
                    />
                </div>

                <div className="delete-account-page__bottom">
                    <span>Permanently delete my account</span>
                    <div className={'btns'}>
                        <Button text={'back'} type={'button'} onClick={() => navigate(-1)} />
                        <Button
                            text={'delete'}
                            outlined
                            type={'button'}
                            className="delete-account-button"
                            onClick={handleShow}
                        />
                    </div>
                </div>
            </form>
            <ModalWrapper
                isShown={isShown}
                setIsShown={handleShow}
                className="delete-account-modal"
            >
                <div className="modal-body">
                    <h3>Are you sure?</h3>
                    <p>
                        The account will no longer be available, and all data in the account will be
                        permanently deleted.
                    </p>
                    <div className="btn-group">
                        <Button text={'cancel'} type={'button'} onClick={handleShow} />
                        <Button
                            text={'delete'}
                            outlined
                            className="delete-account-button-modal"
                            onClick={handleSubmit(onSubmit)}
                        />
                    </div>
                </div>
            </ModalWrapper>
        </>
    )
}

export default DeleteAccount
