import { makeAutoObservable, observable, runInAction } from 'mobx'

class UserStore {
    user: any

    constructor() {
        makeAutoObservable(this, {
            user: observable,
        })
    }
    setUser = (payload: object) => {
        runInAction(() => {
            this.user = payload
        })
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new UserStore()
