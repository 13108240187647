import { FC } from 'react'
import Icon from '../icon'
import { CheckboxProps } from './interface'

type Props = CheckboxProps

const CustomCheckbox: FC<Props> = ({ onClick, checked, width, active }) => {
    return (
        <Icon
            onClick={onClick}
            name={checked || active ? `checkbox-on` : `checkbox-off`}
            width={width}
        />
    )
}

export default CustomCheckbox
