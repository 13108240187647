import './styles.scss'
import DataTable from 'react-data-table-component'
import Button from '../../components/button'
import { FC, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { getOnboardingDocsList } from '../../api/onboarding-documents'
import { DataRowContent } from '../account-basic-info/inreface'
import AddDocument from '../account-onboarding-modal-actions/account-onboarding-add-doc'
import PaginateComponent from '../../components/pagination'
import DeleteDoc from '../account-onboarding-modal-actions/account-onboarding-delete-doc'
import PublishDoc from '../account-onboarding-modal-actions/account-onboarding-publish-doc'
import Loader from '../../components/loader/loader'
import NoClients from '../../components/noclients-list'
import { columns, tableData } from './table-data'
import ProfDocAccordionElement from '../../components/prof-docs-accordion-element'
import DocsModalStore from '../../store/prof-docs-modals'
import { observer } from 'mobx-react-lite'
import { paginationText } from '../../utils/pagination-text'

const OnboardingDocs: FC = () => {
    const { setShownCreateModal, isShownCreateModal, currentDocument } = DocsModalStore

    const [filters, setFilters] = useState({
        page: 1,
        page_size: 10,
    })

    const [activeAccordion, setActiveAccordion] = useState(0)

    const { data: docsList, isLoading } = useQuery(
        ['docsList', filters, isShownCreateModal, !!currentDocument],
        () => getOnboardingDocsList(filters)
    )

    const data = useMemo(() => tableData(docsList), [docsList])

    return (
        <div className="onboarding-wrapper">
            <div className="container">
                <div className="data-table-wrapper">
                    <div className="add-docs">
                        <Button
                            text={'+ ADD DOCUMENT'}
                            className="add-documents-btn"
                            onClick={() => setShownCreateModal(true)}
                        />
                    </div>
                    {!docsList && isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            {!docsList?.results?.length ? (
                                <NoClients warning={'No documents'} />
                            ) : (
                                <>
                                    <div className="data-table-border">
                                        <DataTable
                                            highlightOnHover={true}
                                            columns={columns}
                                            data={data}
                                            responsive
                                            pointerOnHover={true}
                                            paginationTotalRows={data.total_count}
                                            paginationPerPage={data.page_size}
                                        />
                                    </div>
                                    <div className={'accordion-mobile'}>
                                        {docsList?.results?.map((doc: DataRowContent) => (
                                            <ProfDocAccordionElement
                                                key={doc.id}
                                                setActiveAccordion={setActiveAccordion}
                                                activeAccordion={activeAccordion}
                                                document={doc}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <div className="paginationDiv">
                        {docsList?.results ? (
                            <div className="paginationDiv__text">
                                {paginationText(docsList, 'documents')}
                            </div>
                        ) : (
                            <div />
                        )}

                        <PaginateComponent
                            onChange={(e: number) =>
                                setFilters({
                                    ...filters,
                                    page: e,
                                })
                            }
                            hidden={
                                !docsList?.results?.length ||
                                docsList?.total_count <= docsList?.page_size
                            }
                            total={docsList?.total_count}
                            defaultCurrentPage={1}
                            defaultPageSize={docsList?.page_size}
                            currentPage={filters.page}
                        />
                    </div>
                </div>
            </div>

            <AddDocument />
            <PublishDoc />
            <DeleteDoc />
        </div>
    )
}
export default observer(OnboardingDocs)
