import { request } from './api'

//-------------------PROFESSIONAL-------------------
export async function getProfessionalProfile(id) {
    return request(`auth/professionals/${id}/`, 'GET')
}
export async function updateProfessionalProfile(id, data) {
    return request(`auth/professionals/update/${id}/`, 'PUT', data)
}

export async function createCardPhotos(data) {
    return request(`prof-card-photo/create`, 'POST', data)
}
export async function deleteCardPhoto(id) {
    return request(`prof-card-photo/delete/${id}/`, 'DELETE')
}
export async function editCardPhoto(id, data) {
    return request(`prof-card-photo/update/${id}/`, 'PUT', data)
}

//-------------------ROOM-------------------
export async function getProfileRoom() {
    return request(`auth/rooms/profile/`, 'GET')
}
export async function updateRoomProfile(id, data) {
    return request(`room/team-members/update/${id}/`, 'PUT', data)
}

export async function getAmenitiesList() {
    return request(`amenity/list/`, 'GET')
}
export async function updateOnboarding(data) {
    return request(`onboarding_process/room/`, 'PUT', data)
}

export async function createCardPhotosRoom(formData) {
    return request(`room-card-photo/create/`, 'POST', formData)
}
export async function deleteCardPhotoRoom(id) {
    return request(`room-card-photo/delete/${id}/`, 'DELETE')
}
export async function editCardPhotoRoom(id, data) {
    return request(`room-card-photo/update/${id}/`, 'PUT', data)
}
export async function updateRoomBusinessProfile(data) {
    return request(`auth/rooms/profile/update/`, 'PUT', data)
}
export async function getCreatePersonalAccount() {
    return request(`create-personal-account/`, 'GET')
}
