import React, { FC } from 'react'
import Icon from '../icon'
import './style.scss'
import Button from '../button'
import { ModalCookieProps } from './interface'
type Props = ModalCookieProps
const Cookie: FC<Props> = ({ isShown, setIsShown, notClose }) => {
    const handleShow = () => {
        localStorage.setItem('cookie', String(true))
        setIsShown(false)
    }
    const type = localStorage.getItem('onboarding')
    return type === 'true' ? null : (
        <div className="cookie-container" style={isShown ? {} : { display: 'none' }}>
            <div>
                <Icon name="cookie" />
            </div>
            <div className="list">
                <div className="title">Our website use cookies. </div>
                <div className="text">
                    By continuing navigating, we assume your permission to deploy cookies as
                    detailed in our Privacy Policy.
                </div>
            </div>
            <div className="button_decline">
                <Button text={'Decline'} outlined type={'submit'} onClick={handleShow} />
            </div>
            <div className="button">
                <Button
                    text={
                        <span className="button_text">
                            <Icon name="cookies-save" />
                            SAVE cookies
                        </span>
                    }
                    type={'submit'}
                    style={{ width: '220px' }}
                    onClick={handleShow}
                />
            </div>
            <div className="close">{!notClose && <Icon name="close" onClick={handleShow} />}</div>
        </div>
    )
}

export default Cookie
