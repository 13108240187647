import { FC, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Loader from "../../components/loader/loader";
import {getAccountVerification} from "../../api/auth";

const AccountVerification: FC = () => {
    const { userID, token } = useParams()

    const { data } = useQuery('verification', () =>
        getAccountVerification(userID, token)
    )
    const navigate = useNavigate()

    useEffect(() => {
        if (data && data.success) {
            navigate('/login')
        }
    }, [data])

    return <Loader fullHeight />
}

export default AccountVerification
