import { request } from './api'

export async function getProfessionalWorks(filters) {
    return request(`prof_works/my_works/`, 'GET', null, null, filters)
}
export async function getWorkDetail(id) {
    return request(`prof_works/my_works/${id}/`, 'GET')
}
export async function getCategories() {
    return request(`categories/my_categories/`, 'GET')
}
export async function getTreatmentNames(filters) {
    return request(`treatment_names/`, 'GET', null, null, filters)
}
export async function createWork(data) {
    return request('prof_works/create/', 'POST', data)
}
export async function editWork(id, data) {
    return request(`prof_works/update/${id}/`, 'PUT', data)
}
export async function deleteWork(id) {
    return request(`prof_works/delete/${id}/`, 'DELETE')
}

export async function changeWorkReaction(id, data) {
    return request(`work_reaction/create/${id}/`, 'POST', data)
}