export const optionsCircle = {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 60,
        legend: { display: false },
        tooltips: {
            callbacks: {
                label(tooltipItem, data) {
                    const label =
                            data.datasets[0].labels[tooltipItem.index] ||
                            '',
                        value = data.datasets[0].data[tooltipItem.index]
                    return ` ${label} : ${value}%`
                }
            },
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            // shadowColor: tooltipShadow,
            backgroundColor: '#fff',
            titleFontColor: '#000',
            bodyFontColor: '#000'
        }
    }


