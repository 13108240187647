import React, { FunctionComponent } from 'react'
import './style.scss'
import Icon from '../../components/icon'

const Footer: FunctionComponent = () => {
    return (
        <>
            <footer className="footer-box container">
                <div>
                    COPYRIGHT © <span>{new Date().getFullYear()}</span> GlowApp. Company Number
                    13783911
                </div>
                <div className="right-footer">
                    Made with{' '}
                    <Icon name={'heart'} height={20} width={20} hover className={'heart-icon'} />
                </div>
            </footer>
        </>
    )
}
export default Footer
