import './styles.scss'

import { useQuery } from 'react-query'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import React, { FunctionComponent, useEffect, useState } from 'react'

/** Modules **/
import ProfileDropdown from '../../modules/topbar-profile-dropdown'
import NotificationDropdown from '../../modules/topbar-notification-dropdown'

/** Components **/
import ModalWrapper from '../../components/modal-wrapper'
import ArrowIcon from '../../components/menu-icons/arrow-icon'
import CalendarIcon from '../../components/menu-icons/calendar-icon'
import OnboardingModal from '../../components/onboarding-process-modal'
import NotificationIcon from '../../components/menu-icons/notification-icon'

/** Store **/
import UserStore from '../../store/user'
import TopBarStore from '../../store/topbar'
import NotificationsCountStore from '../../store/notifications'

/** APIs **/
import { getRoomMemberProfile } from '../../api/api'
import { getProfessionalProfile } from '../../api/profiles'
import { getLastNotifications } from '../../api/notifications'

import { showToast } from '../../utils/toast-function'

import defaultUser from '../../assets/images/default_user.png'
import HeaderLocation from '../../components/header-location'
import LocationModalStore from '../../store/location-modal'
import { user_role } from '../../utils/user-type'
import MasterModal from '../../components/master-modal'
import { getDashboardActiveBookings } from '../../api/dashboard'

const TopBar: FunctionComponent = () => {
    const {
        setShowNotificationsDropdown,
        setShowUserDropdown,
        notificationsDropdown,
        userDropdown,
        setMasterModal,
        masterModal,
    } = TopBarStore
    const { isShownModal, setShowLocationModal } = LocationModalStore
    const { setCount, refetchTrigger } = NotificationsCountStore

    const [showApproveModal, setApproveShowModal] = useState(false)
    const navigate = useNavigate()
    const handleShowNotification = (e: any) => {
        e.stopPropagation()

        setShowNotificationsDropdown(!notificationsDropdown)
        setShowUserDropdown(false)
    }

    const handleShowProfile = (e: any) => {
        e.stopPropagation()
        setShowUserDropdown(!userDropdown)
        setShowNotificationsDropdown(false)
    }

    const completed = JSON.parse(localStorage.getItem('completed_onboarding') as string)
    const isAlertChecked = JSON.parse(localStorage.getItem('alertChecked') as string)
    const approved = JSON.parse(localStorage.getItem('approved') as string)
    const profileId = localStorage.userID && Number(localStorage.userID)
    const isRoom = user_role === 'room'
    const { data: stripe } = useQuery(['active-bookings'], getDashboardActiveBookings, {
        cacheTime: 0,
    })
    const { data } = useQuery(
        'profileInfo',
        () =>
            getProfessionalProfile(profileId).then((res) => {
                localStorage.setItem('approved', String(res.professional_profile.approved))
                localStorage.setItem(
                    'completed_onboarding',
                    String(res.professional_profile.completed)
                )
                return res
            }),
        {
            enabled: !isRoom,
        }
    )

    const { data: roomProfile } = useQuery(
        'memberInfo',
        () =>
            getRoomMemberProfile(profileId).then((res) => {
                localStorage.setItem('approved', String(res.room_member_profile.approved))
                localStorage.setItem(
                    'completed_onboarding',
                    String(res.room_member_profile.completed)
                )
                return res
            }),
        {
            enabled: isRoom,
        }
    )

    const currentProfile = isRoom ? roomProfile?.room_member_profile : data?.professional_profile

    useEffect(() => {
        UserStore?.setUser(isRoom ? roomProfile : data)
    }, [roomProfile, data])

    const { data: notificationData } = useQuery(
        ['notifications', refetchTrigger],
        getLastNotifications,
        {
            refetchInterval: 5000,
            refetchIntervalInBackground: true,
        }
    )
    useEffect(() => {
        setApproveShowModal(!completed && !isRoom)
    }, [])

    useEffect(() => {
        setCount(notificationData?.unread_count || 0)
        if (notificationData?.unread_count && isAlertChecked) {
            showToast(
                'success',
                `You have ${notificationData?.unread_count} new notification${
                    notificationData?.unread_count > 1 ? 's' : ''
                }!`
            )
        }
    }, [notificationData?.unread_count, isAlertChecked])

    useEffect(() => {
        if (!completed && !isRoom) {
            navigate('/onboarding-process/')
        }
        if (completed && !approved) {
            navigate('/')
        }
    }, [completed, approved])
    return (
        <>
            {!completed && (
                <ModalWrapper
                    isShown={!isRoom && showApproveModal}
                    setIsShown={() => null}
                    notClose
                >
                    <OnboardingModal
                        isRoom={isRoom}
                        name={data?.professional_profile?.name}
                        surname={data?.professional_profile?.surname}
                        setShowModal={() => null}
                    />
                </ModalWrapper>
            )}

            <div className="topbar">
                <div className="topbar-left">
                    <div className="mobile-forBurger"></div>
                    {!isRoom && (
                        <div
                            onClick={() => {
                                setShowLocationModal(!isShownModal)
                            }}
                            className="topbar-location"
                        >
                            <HeaderLocation />
                        </div>
                    )}
                </div>
                <div className="topbar-user-info">
                    <div className="icon-bg" onClick={() => navigate(`/calendar`)}>
                        <CalendarIcon />
                    </div>
                    <div className={'notification-dropdown'}>
                        <div className="icon-bg" onClick={handleShowNotification}>
                            <NotificationIcon />
                            {!!notificationData?.unread_count && (
                                <div className="badge">
                                    <span>
                                        {notificationData?.unread_count < 100
                                            ? notificationData?.unread_count
                                            : '99+'}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div>
                            {notificationsDropdown && (
                                <NotificationDropdown notifications={notificationData} />
                            )}
                        </div>
                    </div>
                    <div className="profile-dropdown">
                        <div className="user-bg" onClick={handleShowProfile}>
                            <span className="user-name">
                                {currentProfile?.name} {currentProfile?.surname}{' '}
                            </span>
                            <div>
                                <img
                                    src={currentProfile?.photo || defaultUser}
                                    alt={'avatar'}
                                    className="user-avatar"
                                />
                            </div>
                            <span className="status" />
                            <ArrowIcon />
                        </div>
                        <div>
                            {userDropdown && (
                                <ProfileDropdown
                                    stripe_link={stripe?.stripe_account_data?.dashboard_link}
                                    room={roomProfile?.room_member_profile}
                                    profile={data?.professional_profile}
                                />
                            )}
                            <MasterModal
                                active={masterModal}
                                setActive={(val) => setMasterModal(val)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default observer(TopBar)
