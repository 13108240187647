
function hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    if (alpha) {
        return `rgb(${r},${g},${b},${alpha})`
    } else {
        return `rgb(${r},${g},${b})`
    }
}

export const generateHexColors = (num = 1) => {
    const colors = []
    for (let i = 0; i < num; i++) {
        const letters = '0123456789ABCDEF'.split('')
        let color = '#'
        for (let x = 0; x < 6; x++) {
            color += letters[Math.floor(Math.random() * 16)]
        }
        colors.push(hexToRGB(color, 0.6))
    }
    return colors
}