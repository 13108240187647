import './styles.scss'

import React, { FunctionComponent } from 'react'
import Icon from '../icon'
import Rating from 'react-rating'

import { RatingProps } from './interface'
import { observer } from "mobx-react-lite";
import Review from "../../store/review";

type Props = RatingProps

const RatingComponent: FunctionComponent<Props> = observer(
  ({ stars, count, readonly=true, width=12.3 }) => {
    return (
      <div className="rating-wrapper">
        <Rating
          readonly={readonly}
          initialRating={stars}
          onChange={(e)=> Review.setReview(e)}
          emptySymbol={
            <Icon
              width={width}
              style={{ marginRight: 2.7 }}
              name={'rating_star_empty'}
            />
          }
          fullSymbol={
            <Icon
              width={width}
              style={{ marginRight: 2.7 }}
              name={'rating_star_full'}
            />
          }
        />
        <span className="stars-count">{count}</span>
      </div>
    )
  }

)

export default RatingComponent
