export const notificationTypesData = {
    appointment: {
        text: 'New appointment',
        description: 'Congratulations! You have a new appointment!',
        path: '/bookings/',
    },
    message: {
        text: 'New message',
        description: 'You have a new message in your inbox.',
        path: '/bookings/',
    },
    reminder: {
        text: 'Upcoming Appointment Reminder',
        description: 'Just a friendly reminder you have an appointment soon. ',
        path: '/bookings/',
    },
    rescheduled: {
        text: 'Rescheduled appointment',
        description: 'Your appointment has been rescheduled.',
        path: '/bookings/',
    },
    thank: {
        text: 'Thank you for visiting',
        description:
            'Thank you for using our platform to provide a beauty treatment! We are delighted to further collaborate with you!',
        path: '/bookings/',
    },
    cancelled: {
        text: 'Cancelled appointment',
        description: 'Your appointment has been cancelled.',

        path: '/bookings/',
    },
    reply: {
        text: 'Reply',
        description: 'You have a new reply on the review:',
        path: '/reviews/',
    },
    review: {
        text: 'New Review',
        description: 'You have a new review:',
        path: '/reviews/',
    },
}
