import { TableColumn } from 'react-data-table-component'
import Icon from '../../components/icon'
import TariffsCrud from '../../components/tariffs-crud'
import React from 'react'
import Tooltip from '../../components/tooltip'

// import { formatMinutes } from '../../utils/timeToHours'

const columns: TableColumn<any>[] = [
    {
        name: 'ROOM TARIFF',
        sortable: true,
        selector: (row) => (row?.name ? row.name : row.type?.title),
    },
    {
        name: 'TREATMENTS',
        width: '175px',
        cell: (row) => {
            return (
                <div
                    className={'gap-5'}
                    style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}
                >
                    {row.categories.map((cat: any) => (
                        <div className="icon-container" key={cat.id}>
                            <Tooltip text={cat.title}>
                                <div id={cat.title} data-tip="Chair" className="icon">
                                    <Icon hover name={cat.icon} />
                                </div>
                            </Tooltip>
                        </div>
                    ))}
                </div>
            )
        },
    },
    {
        name: 'PRICE/hr',
        sortable: true,
        center: true,
        maxWidth: '150px',
        selector: (row) => `£${row.price}`,
    },
    {
        name: 'PROMO',
        center: true,
        width: '90px',
        cell: (row) => (
            <Icon style={{ cursor: 'default' }} name={row.deal ? 'check-mark-black' : 'minus'} />
        ),
    },
    {
        name: 'ACTION',
        maxWidth: '100px',
        center: true,
        cell: (row) => {
            return <TariffsCrud row={row} />
        },
    },
]

export default columns
