import './styles.scss'
import no_location from '../../assets/icons/no-location.svg'

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'

/** Components **/
import SimpleMap from '../google-map'
import Button from '../../components/button'
import Loader from '../../components/loader/loader'
import RadioInput from '../../components/radio-input'
import { usePosition } from '../google-map/usePosition'
import ModalWrapper from '../../components/modal-wrapper'

import { getAddresses, setLocation } from '../../api/maps'
import { showToast } from '../../utils/toast-function'

/** Store **/
import LocationModalStore from '../../store/location-modal'
import UserPosition from '../../store/user-position'

import { user_role } from '../../utils/user-type'
import UserPositionStore from '../../store/user-position'

const LocationModal = () => {
    const { isShownModal, setShowLocationModal } = LocationModalStore
    const [activeRadio, setActiveRadio] = useState(1)
    const [modalMap, setModalMap] = useState(false)
    const loc = usePosition() as any
    const [haveProblem, setProblem] = useState(true)
    const { data } = useQuery(
        ['location', loc],
        () => getAddresses(loc?.position?.lat, loc?.position?.lng).then((res) => res.data),
        {
            enabled: isShownModal && !!loc?.position?.lat && !!loc?.position?.lng && !loc?.error,
        }
    )
    const isAuth = !!JSON.parse(localStorage.getItem('userID') as string)
    const filter_keys = ['premise', 'street_address', 'establishment']
    const newAddress = data?.results.filter((item: any) => {
        return filter_keys.some((filter) => item.types.includes(filter))
    })
    const handleClose = () => {
        // setCanClose(false)
        setShowLocationModal(false)
    }
    const setMyLocation = async (e: any) => {
        try {
            isAuth &&
                (await setLocation(
                    {
                        address: e.name,
                        lat: e.position.lat,
                        lon: e.position.lng,
                    },
                    user_role as string
                ))
            localStorage.setItem('address', JSON.stringify(e))
            UserPosition.setUserPosition(e)
            handleClose()
        } catch (err: any) {
            showToast('danger', 'Something went wrong. Try again later')
            throw new Error(err)
        } finally {
            setModalMap(false)
        }
    }

    useEffect(() => {
        if (isShownModal && (loc.error || !loc?.position?.lat || !loc?.position?.lng)) {
            setActiveRadio(2)
        } else {
            setActiveRadio(1)
        }
    }, [isShownModal, loc])

    useEffect(() => {
        if (isShownModal) {
            if (data?.error_message) {
                setProblem(true)
            } else {
                setProblem(false)
            }
        }
    }, [isShownModal, data])
    return (
        <>
            <ModalWrapper
                className={'no-location-modal'}
                isShown={isShownModal}
                setIsShown={handleClose}
            >
                {!data && !loc?.error ? (
                    <Loader />
                ) : (
                    <>
                        <div className="no-location-modal d-flex justify-content-center align-items-center flex-column">
                            <img src={no_location} alt="location" />
                        </div>
                        <div>
                            {haveProblem ? (
                                <>
                                    <h2 className="text-h2">Google maps have some problems</h2>
                                    <p>You can refresh your address later.</p>
                                </>
                            ) : UserPositionStore?.userPosition?.name ? (
                                <div className={'d-flex flex-column align-items-start'}>
                                    <div className="modal_title">Current address:</div>
                                    <div className="current-address">
                                        {UserPositionStore?.userPosition?.name}
                                    </div>
                                </div>
                            ) : (
                                <h2 className={`no-location-title my-20`}>
                                    Please confirm your location
                                </h2>
                            )}
                        </div>
                        {!haveProblem && (
                            <div className="w-100">
                                <div
                                    className={`w-100 mt-20 ${!loc || loc.error ? 'disabled' : ''}`}
                                >
                                    {newAddress?.length && (
                                        <>
                                            <span className="modal_title">
                                                {UserPositionStore?.userPosition?.name
                                                    ? 'Change location:'
                                                    : 'My location:'}
                                            </span>
                                            <span className={'current-address'}>
                                                <RadioInput
                                                    style={'current-address'}
                                                    text={
                                                        newAddress[0]?.formatted_address ||
                                                        'My current location'
                                                    }
                                                    checked={activeRadio === 1}
                                                    value={1}
                                                    onChange={() => setActiveRadio(1)}
                                                />
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div className={`w-100 show-map-container `}>
                                    <span
                                        className={'show-map-btn cursor-pointer'}
                                        onClick={() => setModalMap(true)}
                                    >
                                        <RadioInput
                                            style={'show-map-btn cursor-pointer'}
                                            text={'CHOOSE ON THE MAP'}
                                            checked={activeRadio === 2}
                                            value={2}
                                            onChange={() => {
                                                setModalMap(true)
                                                setActiveRadio(2)
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="mt-20 d-flex justify-content-end">
                            {UserPositionStore?.userPosition?.name && (
                                <Button
                                    text={'cancel'}
                                    outlined
                                    onClick={() => {
                                        setShowLocationModal(false)
                                    }}
                                />
                            )}
                            {haveProblem ? (
                                <Button
                                    text={'ok'}
                                    outlined
                                    onClick={() => {
                                        setShowLocationModal(false)
                                    }}
                                />
                            ) : (
                                <Button
                                    text={'submit'}
                                    disabled={haveProblem || !loc || loc.error}
                                    onClick={() => {
                                        setMyLocation({
                                            name: data?.plus_code?.compound_code,
                                            ...loc,
                                        })
                                    }}
                                />
                            )}
                        </div>
                    </>
                )}
            </ModalWrapper>
            {modalMap && (
                <ModalWrapper
                    noClose
                    style={{
                        width: '100%',
                        height: 'calc(100% - 50px)',
                        marginBottom: 50,
                    }}
                    setIsShown={() => setModalMap(!modalMap)}
                    isShown={modalMap}
                    className="modal-icon-close-location"
                >
                    <SimpleMap onSelectAddress={(e: any) => setMyLocation(e)} />
                </ModalWrapper>
            )}
        </>
    )
}

export default observer(LocationModal)
