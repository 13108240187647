import './styles.scss'
import { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'
import DataTable from 'react-data-table-component'
import { getClientsList } from '../../api/api'
import Loader from '../../components/loader/loader'
import Input from '../../components/input'
import PaginateComponent from '../../components/pagination'
import columns from './logicOfData'
import NoClients from '../../components/noclients-list'
import Accordion from '../../components/accordion'
import ClientModal from '../../modules/client-modal'
import NoApprovePage from '../../components/no-approve-page'
import { paginationText } from '../../utils/pagination-text'

const ClientsListPage: FC = () => {
    const [modalActive, setModalActive] = useState(false)
    const [modalUserId, setModalUserID] = useState()
    const [filters, setFilters] = useState({
        page: 1,
        page_size: 10,
        search: '',
    })
    const isApproved = JSON.parse(localStorage.getItem('approved') as string)
    const { data, isLoading } = useQuery(
        ['clientsList', filters],
        () => getClientsList({ ...filters }),
        {
            cacheTime: 0,
        }
    )

    const debounceValueFn = useDebouncedCallback((e) => {
        setFilters({ ...filters, page: 1, search: e.target.value })
    }, 600)

    return isApproved ? (
        <>
            <div className="clientsList container">
                <h2 className="header">Clients List</h2>
                <div className="clientsList__background">
                    <Input
                        type="text"
                        icon="search"
                        onChange={debounceValueFn}
                        placeholder="Search by client’s name, address or date of birth"
                    />
                    {isLoading ? (
                        <Loader transparent />
                    ) : !data.results.length ? (
                        <NoClients warning="No clients" />
                    ) : (
                        <>
                            <div className="dataTableWrapper">
                                <DataTable
                                    columns={columns}
                                    data={data.results}
                                    responsive
                                    paginationTotalRows={data.total_count}
                                    paginationPerPage={data.page_size}
                                    pointerOnHover={true}
                                    highlightOnHover={true}
                                    onRowClicked={(client) => {
                                        setModalActive(true)
                                        setModalUserID(client.id)
                                    }}
                                />
                            </div>
                            <div className="accordionWrapper">
                                <Accordion
                                    id={setModalUserID}
                                    toggleModal={setModalActive}
                                    name={data}
                                />
                            </div>

                            <div className="paginationDiv">
                                <div className="paginationDiv__text">
                                    {paginationText(data, 'clients')}
                                </div>

                                <PaginateComponent
                                    onChange={(e: number) =>
                                        setFilters({
                                            ...filters,
                                            page: e,
                                        })
                                    }
                                    hidden={
                                        !data.results.length || data.total_count <= data.page_size
                                    }
                                    total={data.total_count}
                                    defaultCurrentPage={1}
                                    defaultPageSize={data.page_size}
                                    currentPage={filters.page}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="modalClient">
                <ClientModal active={modalActive} setActive={setModalActive} userId={modalUserId} />
            </div>
        </>
    ) : (
        <NoApprovePage />
    )
}

export default ClientsListPage
