import { Pagination } from 'antd'
import './styles.scss'

interface props {
    currentPage: number
    defaultCurrentPage: number
    defaultPageSize: number
    total: number
    onChange?: any
    hidden?: boolean
}

export default function PaginateComponent({
    currentPage,
    defaultCurrentPage,
    defaultPageSize,
    total,
    onChange,
    hidden,
}: props) {
    function itemRender(current: any, type: string, originalElement: any) {
        if (type === 'prev') {
            return <div className="customBtn">Previous</div>
        }
        if (type === 'next') {
            return <div className="customBtn">Next</div>
        }
        return originalElement
    }
    if (hidden) {
        return null
    } else {
        return (
            <Pagination
                current={currentPage}
                defaultCurrent={defaultCurrentPage}
                defaultPageSize={defaultPageSize}
                total={total}
                size="default"
                showSizeChanger={false}
                itemRender={itemRender}
                onChange={onChange}
            />
        )
    }
}
