import React from 'react';

function ReviewsIcon() {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0725 18.8767C18.1217 18.8767 22.2154 15.1236 22.2154 10.8573C22.2154 6.591 18.1217 3.42871 13.0725 3.42871C8.0234 3.42871 3.92969 6.887 3.92969 11.1533C3.92969 12.8184 4.55369 14.3613 5.6154 15.6219L4.50112 20.8573L8.97654 18.0607C10.2734 18.6044 11.6663 18.8819 13.0725 18.8767Z" stroke="currentColor" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default ReviewsIcon
