import './styles.scss'
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { statuses } from '../../utils/team-status'
import { IStatus } from './interface'
import Icon from '../icon'
import { updateStatus } from '../../api/api'
import { showToast } from '../../utils/toast-function'
import { closeByClick } from '../../utils/closing-dropdown-func'
import UserStore from '../../store/user'

type Props = IStatus

const StatusDropDown: FC<Props> = ({ data }) => {
    const [isShown, setShow] = useState(false)
    const [statusLocal, setStatus] = useState('')
    const ref = useRef() as MutableRefObject<HTMLInputElement>
    useEffect(() => {
        setStatus(data.status)
    }, [data])

    const handleChangeStatus = async (status: string) => {
        try {
            const form = {
                status: status,
            }
            await updateStatus(data.id, form)
            setShow(false)
            setStatus(status)
            showToast('success', 'Status updated successfully')
        } catch (error) {
            showToast('danger', 'SomeThing went wrong. Try again later.')
        }
    }
    const userID = JSON.parse(localStorage.getItem('userID') as string)
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShow(false)
            }
        }
        closeByClick(checkIfClickedOutside)
    }, [ref])
    const disabled = userID === data.user.id || !UserStore?.user?.room_member_profile?.is_admin
    return (
        <>
            <div className={`${disabled && 'disabled'}`} ref={ref}>
                {statuses.map(
                    (item, i) =>
                        statusLocal?.toLowerCase() === item.text && (
                            <div
                                key={i}
                                style={{ background: `${item.color}` }}
                                className="status-team"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShow(!isShown)
                                }}
                            >
                                {statusLocal[0]?.toUpperCase() + statusLocal.slice(1)}
                                {!disabled && <Icon name={'arrow-down-white'} />}
                            </div>
                        )
                )}

                {isShown && (
                    <div className="action-dropdown-container-team">
                        {statuses.map((stat, index) => (
                            <span
                                key={index}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleChangeStatus(stat.text)
                                }}
                            >
                                {stat.text[0].toUpperCase() + stat.text.slice(1)}
                            </span>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}
export default StatusDropDown
