import React, { FC, useEffect, useState } from 'react'
import './styles.scss'
import BackButton from '../../components/back-arrow-btn'
import WorkingHoursDay from '../../modules/working-hours-day'
import { useQuery } from 'react-query'
import { getSchedule, updateSchedule } from '../../api/calendar'
import Loader from '../../components/loader/loader'
import { iWorkingHoursDay } from '../../modules/working-hours-day/interface'
import { useForm } from 'react-hook-form'
import { observer } from 'mobx-react-lite'
import WorkingHoursSelect from '../../store/working-hours-select'
import Button from '../../components/button'
import { useNavigate } from 'react-router-dom'
import { showToast } from '../../utils/toast-function'
import ModalWrapper from '../../components/modal-wrapper'

const WorkingHoursPage: FC = () => {
    const [modalShown, setModalShown] = useState(false)
    const { data, isLoading, refetch } = useQuery('schedule', getSchedule)
    const { workingHoursSelect, setCurrentSelect } = WorkingHoursSelect
    const navigate = useNavigate()
    const { handleSubmit, setValue, reset, watch } = useForm()

    const onSubmit = async (data: any) => {
        try {
            await updateSchedule(data).then(() => refetch())
            navigate(-1)
            showToast('success', 'Changes saved successfully')
        } catch (e: any) {
            showToast('danger', 'Something went wrong. Try again later.')
            throw new Error(e)
        }
    }

    useEffect(() => {
        if (data?.every((day: any) => !day.time_slots.length)) {
            const newData = data.map((day: any) => {
                return {
                    ...day,
                    time_slots: [
                        {
                            start_time: '10:00',
                            end_time: '19:00',
                        },
                    ],
                }
            })
            updateSchedule(newData).then(() => refetch())
        } else {
            reset(data)
        }
    }, [data])
    return isLoading ? (
        <Loader transparent />
    ) : (
        <section
            className={'workingHours_page container'}
            onClick={
                workingHoursSelect
                    ? (e) => {
                          e.stopPropagation()
                          setCurrentSelect('')
                      }
                    : undefined
            }
        >
            <div className="workingHours_title">
                <BackButton />
                <h2>Working Hours</h2>
            </div>
            <div className="workingHours_card">
                {data?.map((day: iWorkingHoursDay, index: number) => (
                    <WorkingHoursDay
                        watch={watch}
                        setValue={setValue}
                        key={day.id}
                        day={day}
                        day_index={index}
                    />
                ))}
                <div className="workingHours_btns">
                    <Button text={'cancel'} outlined onClick={() => navigate(-1)} />
                    <Button text={'save'} onClick={() => setModalShown(true)} />
                </div>
            </div>
            <ModalWrapper isShown={modalShown} setIsShown={setModalShown}>
                <div style={{ maxWidth: '550px' }}>
                    <h2>Working hours</h2>
                    <p style={{ color: 'var(--palette-secondary-500)', paddingTop: 10 }}>
                        Please note, there might be some bookings you must complete according to the
                        previous schedule
                    </p>
                    <div className="workingHours_btns" style={{ paddingRight: 0 }}>
                        <Button text={'cancel'} outlined onClick={() => setModalShown(false)} />
                        <Button text={'confirm'} onClick={handleSubmit(onSubmit)} />
                    </div>
                </div>
            </ModalWrapper>
        </section>
    )
}

export default observer(WorkingHoursPage)
