import { FC, useState } from 'react'
import { LoginPageProps } from './interface'
import './styles.scss'
import Icon from '../../components/icon'
import Button from '../../components/button'
import SignUpForm from '../../modules/sign-up-form'
import SignInForm from '../../modules/sign-in-form'
import SignUpFormTeam from '../../modules/sign-up-form-team'

type Props = LoginPageProps

const LoginPage: FC<Props> = ({ loginPage = false, room, team }) => {
    const [isLogin, setIsLogin] = useState(loginPage)
    const [isOkRegister, setIsOkRegister] = useState(false)
    return (
        <>
            <div className="loginPage">
                <div className={`loginPage_info ${isLogin && 'move-to-rigth'}`}>
                    <img src="/images/login-bg.jpg" alt="login-bg" />
                    <div className="info_container">
                        <div className="information for_sign-up">
                            <h2>Welcome!</h2>
                            <Icon name="logo-login" />
                            <p>Already have an account?</p>
                            <Button
                                outlined
                                text="LOG IN"
                                onClick={() => {
                                    setIsLogin(true)
                                    setIsOkRegister(false)
                                }}
                            />
                        </div>
                        <div className="information for_sign-in">
                            <h2>{isOkRegister ? 'Registration successful' : 'Hello!'}</h2>
                            <Icon name="logo-login" />
                            <p>
                                {isOkRegister
                                    ? 'Please check your e-mail.'
                                    : 'Haven’t joined us yet?'}
                            </p>
                            <Button
                                outlined
                                text={isOkRegister ? 'switch to register' : 'CREATE AN ACCOUNT'}
                                onClick={() => setIsLogin(false)}
                            />
                        </div>
                    </div>
                </div>
                <div className={`loginPage_form ${isLogin ? 'move-to-left' : 'stay-right'}`}>
                    {team ? (
                        <SignUpFormTeam setLogin={setIsLogin} setOkRegister={setIsOkRegister} />
                    ) : (
                        <SignUpForm
                            setLogin={setIsLogin}
                            setOkRegister={setIsOkRegister}
                            room={room}
                        />
                    )}
                    <SignInForm setLogin={setIsLogin} />
                </div>
            </div>
        </>
    )
}

export default LoginPage
