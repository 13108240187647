import React, { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import Icon from '../../components/icon'
import './styles.scss'
import ChangePasswordComponent from '../../modules/change-password'
import { checkResetPassword } from '../../api/auth'
import { useQuery } from 'react-query'
import Loader from '../../components/loader/loader'
import Button from '../../components/button'
import ModalWrapper from '../../components/modal-wrapper'
import ForgotPassword from '../../modules/forgot-password-modal'

const ForgotPasswordPage: FC = () => {
    const [showModal, setShowModal] = useState(false)

    const { uid, token } = useParams()
    const { data, isLoading } = useQuery(
        ['check', uid, token],
        () => checkResetPassword(uid, token),
        {
            enabled: !!uid && !!token,
        }
    )
    return isLoading ? (
        <Loader />
    ) : (
        <div className="forgot-password-page">
            <div className={`loginPage_info move-to-rigth`}>
                <img src="/images/login-bg.jpg" alt="login-bg" />
                <div className="info_container">
                    <div className="information for_sign-up">
                        <h2>Password Recovery</h2>
                        <Icon name="logo-login" />
                    </div>
                </div>
            </div>
            <ModalWrapper isShown={showModal} setIsShown={setShowModal}>
                <ForgotPassword setShowModal={setShowModal} isShownModal={showModal} />
            </ModalWrapper>
            {data?.success ? (
                <ChangePasswordComponent forResetPass userID={uid} userToken={token} />
            ) : (
                <>
                    <p style={{ textAlign: 'center' }}>
                        Link is invalid. You can try to reset password again.
                    </p>
                    <div className="w-100 d-flex justify-content-center">
                        <Button text={'try again'} onClick={() => setShowModal(true)} />
                    </div>
                </>
            )}
        </div>
    )
}

export default ForgotPasswordPage
