import './styles.scss'

import React, { FC, useEffect, useState } from 'react'
import Icon from '../../components/icon'
import { formatMinutes } from '../../utils/timeToHours'
import user from '../../assets/images/default_user.png'
import Button from '../../components/button'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { declineBooking, getBookingDetails } from '../../api/api'
import ModalWrapper from '../../components/modal-wrapper'
import Loader from '../../components/loader/loader'
import Chat from '../../modules/chat'
import { showToast } from '../../utils/toast-function'
import { user_role } from '../../utils/user-type'
import ChatStore from '../../store/chat'

const BookingDetails: FC = () => {
    const search = useLocation().search
    const openChat = new URLSearchParams(search).get('open-chat')

    const { setShowChat } = ChatStore

    const isRoom = user_role === 'room'
    const [isModalShown, setModalShown] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const { booking_id } = useParams()
    const { data, isLoading, refetch } = useQuery(
        ['booking-detail', booking_id, isRoom],
        () => getBookingDetails(booking_id),
        { cacheTime: 0 }
    )

    const navigate = useNavigate()

    useEffect(() => {
        if (openChat) {
            setShowChat(true)
        }
        return () => setShowChat(false)
    }, [openChat, booking_id])

    const declineBookingFn = async () => {
        setIsSending(true)
        try {
            await declineBooking(data.id)
            setModalShown(false)
            showToast('success', 'Booking has been declined successfully')
            refetch()
        } catch (error: any) {
            showToast(
                'danger',
                (error?.response?.data?.length && error?.response?.data[0]) ||
                    'Something went wrong. Try again later'
            )
            throw new Error(error)
        } finally {
            setIsSending(false)
        }
    }
    return isLoading ? (
        <Loader />
    ) : (
        <>
            {data?.status !== 'completed' && !isRoom ? <Chat booking_data={data} /> : null}
            <div className="booking-wrapper container">
                <div className="head">
                    <Icon name={'arrow-back-login'} width={40} onClick={() => navigate(-1)} />
                    <h2 className="booking-wrapper__header">About Visit</h2>
                </div>

                <div key={1} className="booking-wrapper__card">
                    <div className="sectionImgName">
                        <div className="imgHeader">
                            <img src={data?.client.photo || user} alt="avatar" />
                            <div className="name_and_status">
                                <p>
                                    {data?.client.name} {data?.client.surname}
                                </p>
                                <div className={data?.status}>{data?.status}</div>
                            </div>
                        </div>
                    </div>
                    <div className="sectionInfo">
                        <div>
                            <div className="sectionInfo__sub">
                                <Icon name={'modal-booking-date'} /> {data?.date || 'none'}
                            </div>
                            <div className="sectionInfo__sub">
                                <Icon name={'modal-booking-time'} /> {data?.time}
                            </div>

                            <div className="sectionInfo__sub">
                                <Icon name={'modal-booking-geo'} />{' '}
                                {formatMinutes(data?.total_duration)}
                            </div>

                            <div className="sectionInfo__sub address">
                                <Icon name={'modal-booking-duration'} />{' '}
                                {data?.address || 'No address'}
                            </div>
                        </div>
                    </div>
                    <div className="sectionOnePrice">
                        {isRoom ? (
                            <div className="treatment-item">
                                <div className="treatment-item-info">
                                    <p>
                                        {data?.room_tariffs.name || data?.room_tariffs.type.title}
                                    </p>
                                    <span>{formatMinutes(data?.total_duration)}</span>
                                </div>
                                <div className="treatment-item-price">
                                    £
                                    {(data?.room_tariffs.deal
                                        ? data?.room_tariffs.deal.discount_price
                                        : data?.room_tariffs.price) || 0}
                                </div>
                            </div>
                        ) : (
                            data?.treatments.map((treatment: any) => (
                                <div key={treatment?.id} className="treatment-item">
                                    <div className="treatment-item-info">
                                        <p>{treatment.name || treatment.subcategory.title}</p>
                                        <span>{formatMinutes(treatment.duration)}</span>
                                    </div>
                                    <div className="treatment-item-price">
                                        £
                                        {(treatment.deal
                                            ? treatment.deal.discount_price
                                            : treatment.price) || 0}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    <div className="sectionPrices">
                        <p>Total</p> £
                        {isRoom ? data?.total_room_tariffs_price : data?.total_treatments_price}
                    </div>
                </div>

                {data?.status === 'booked' && (
                    <>
                        <div className="booking-wrapper__undertext">
                            <p>Cancellation Policy:</p>
                            <div>
                                <p>
                                    Cancellations are allowed up to 24 hours before the scheduled
                                    time.
                                </p>
                            </div>
                        </div>
                        <div className="booking-wrapper__btn2">
                            <Button
                                text="cancel"
                                disabled={!data?.can_cancel}
                                outlined
                                onClick={() => setModalShown(true)}
                            />
                        </div>
                    </>
                )}
                <ModalWrapper isShown={isModalShown} setIsShown={setModalShown}>
                    <div className="deleteModal">
                        <h2>Are you sure?</h2>
                        <p>This action cannot be undone</p>
                        <div className="delete-btns">
                            <Button
                                text={'back'}
                                loading={isSending}
                                onClick={() => setModalShown(false)}
                            />
                            <Button
                                text={'cancel booking'}
                                loading={isSending}
                                outlined
                                onClick={declineBookingFn}
                            />
                        </div>
                    </div>
                </ModalWrapper>
            </div>
        </>
    )
}

export default BookingDetails
