import './styles.scss'
import React, { FC } from 'react'
import Button from "../button";
import {LogOut} from "../../api/api";

interface LogOutInterface{
    setShow: ()=> void
}
type Props = LogOutInterface

const LogOutModal: FC<Props> = ({setShow}) => {
    return (
        <div>
            <div className="modal-to-logOut">
                <h3>Are you sure to Log Out?</h3>
                <div className="btns">
                    <Button text={'Log out'} outlined onClick={() => LogOut()} />
                    <Button
                        text={'Back'}
                        onClick={setShow}
                    />
                </div>
            </div>
        </div>
    )
}
export default LogOutModal
