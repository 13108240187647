import { FC, useEffect, useState } from 'react'
import { IAmenity } from './interface'
import CustomCheckbox from '../../../components/custom-checkbox'
import './styles.scss'
type Props = IAmenity

const Amenity: FC<Props> = ({ data, handleChecked }) => {
    const [active, setActive] = useState(data.checked)
    useEffect(() => {
        setActive(data.checked)
    }, [data.checked])
    return (
        <div
            key={data.id}
            onClick={() => {
                setActive(!active)
                handleChecked(data.id)
            }}
            className={'amenity cursor-pointer'}
        >
            <CustomCheckbox width={25} active={active} />

            <span>{data.title}</span>
        </div>
    )
}

export default Amenity
