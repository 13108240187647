import { TableColumn } from 'react-data-table-component'
import { DataRow, DataRowContent } from '../account-basic-info/inreface'
import Icon from '../../components/icon'
import DropdownAction from '../../components/dropdown-action'
import React from 'react'

export const columns: TableColumn<DataRow>[] = [
    {
        name: 'FILE NAME',
        width: '150px',
        selector: (row: any) => (row.name ? row.name : row.category || 'Additional document'),
    },
    {
        name: 'CATEGORY',
        selector: (row) => (row.category ? row.category : 'Additional document'),
    },
    {
        name: 'LAST MODIFIED',
        center: true,

        selector: (row) => row.lastModified,
    },
    {
        name: 'PUBLISHED',
        center: true,
        selector: (row) => row.published,
        // cell: () => <Icon name={'tick'}/>
    },
    {
        name: 'ACTION',
        center: true,

        selector: (row) => row.action,
    },
]

export const tableData = (docsList: any) => {
    return docsList?.results?.map((item: DataRowContent) => ({
        id: item.id,
        name: (
            <div style={{ maxWidth: '118px' }}>
                <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {item.file_name || item.category.title}
                </div>
                {item.category.issue_expiry_dates && (
                    <div className={'d-flex align-items-center'} style={{ gap: 5 }}>
                        <p className="insurance-date">{item?.issue_date || 'unset'}</p>
                        <p className="insurance-date">-</p>
                        <p className="insurance-date">{item?.expiry_date || 'unset'}</p>
                    </div>
                )}
            </div>
        ),
        category: item.category?.title,
        lastModified: item.last_modified,
        published: item.published ? (
            <Icon name={'tick'} className={'tick'} />
        ) : (
            <Icon name={'minus'} className={'minus'} />
        ),
        action: <DropdownAction data={item} />,
    }))
}
