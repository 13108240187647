import React from 'react';

function WorksIcon() {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.78571 8.57164H18.2143C18.8205 8.57164 19.4019 8.81245 19.8305 9.24111C20.2592 9.66976 20.5 10.2511 20.5 10.8574V16.5716C20.5 17.1778 20.2592 17.7592 19.8305 18.1879C19.4019 18.6165 18.8205 18.8574 18.2143 18.8574H6.78571C6.17951 18.8574 5.59812 18.6165 5.16947 18.1879C4.74082 17.7592 4.5 17.1778 4.5 16.5716V10.8574C4.5 10.2511 4.74082 9.66976 5.16947 9.24111C5.59812 8.81245 6.17951 8.57164 6.78571 8.57164V8.57164ZM11.3571 5.14307H13.6429C14.2491 5.14307 14.8304 5.38388 15.2591 5.81254C15.6878 6.24119 15.9286 6.82257 15.9286 7.42878V8.57164H9.07143V7.42878C9.07143 6.82257 9.31224 6.24119 9.7409 5.81254C10.1696 5.38388 10.7509 5.14307 11.3571 5.14307Z" stroke="currentColor" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>



    )
}

export default WorksIcon
