import { makeAutoObservable, observable } from 'mobx'

class UserPositionStore {
    userPosition: any

    constructor() {
        makeAutoObservable(this, {
            userPosition: observable,
        })
    }

    setUserPosition = (position: any) => {
        this.userPosition = position
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new UserPositionStore()
