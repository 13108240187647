import { makeAutoObservable, observable } from 'mobx'

class BlockedTimeModalStore {
    isShown = false
    currentTime = null as any

    constructor() {
        makeAutoObservable(this, {
            isShown: observable,
            currentTime: observable,
        })
    }

    setShown = (isShown: boolean, blockedTime?: any | null | undefined) => {
        this.isShown = isShown
        if (blockedTime) {
            this.currentTime = blockedTime
        } else {
            this.currentTime = null
        }
    }
}

export default new BlockedTimeModalStore()
