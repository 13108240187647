import './styles.scss'
import Icon from '../../components/icon'
import { FC } from 'react'

interface prop {
    navState?: boolean
    setNav?: () => void
}
type Props = prop
const OnboardingNav: FC<Props> = ({ navState, setNav }) => {
    return (
        <div className="process-box-full">
            <div className="process-box">
                <div>{navState && <Icon name={'arrow-back'} onClick={setNav} />}</div>

                <div>
                    <div className=" process-navigation">
                        <div className="step">
                            <div className="active">
                                {navState ? <Icon name={'checked-white'} /> : <span>1</span>}
                            </div>
                        </div>
                        <div className="line">
                            <div className={navState ? 'fill-after' : 'fill'} />
                        </div>
                        <div className="step">
                            <div className={navState ? 'active' : 'process-elem'}>
                                <span>2</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="process-nav-txt">
                <h4>Business information</h4>
                <h4>Upload Documents</h4>
            </div>
        </div>
    )
}
export default OnboardingNav
