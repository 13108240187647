import { makeAutoObservable, observable } from 'mobx'

class Review {
    count = 0
    text = ''

    constructor() {
        makeAutoObservable(this, {
            count: observable,
            text: observable,
        })
    }

    setReview(review: number) {
        this.count = review
    }

    setText(message: string) {
        this.text = message
    }
}

export default new Review()
