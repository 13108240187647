import './styles.scss'
import { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { getClient } from '../../api/api'
import { ClientProps } from './interface'
import Loader from '../../components/loader/loader'
import ModalBookingsComp from '../../components/modal-bookings'
import ModalReviewsComp from '../../components/modal-reviews'
import ClientListModalUp from '../../components/client-list-modal-up'
import ClientListModalDown from '../../components/client-list-modal-down'
import Icon from '../../components/icon'

type Props = ClientProps

const ClientModal: FC<Props> = ({ active, setActive, userId }) => {
    const [getStars, setGetStars] = useState(false)
    const [modalState, setModalState] = useState('BOOKINGS')
    const [filter, setFilter] = useState({
        status: null,
        stars: 'null,null,null,null,null',
    })

    const { data, isLoading } = useQuery(
        ['client', filter.status, getStars],
        () => getClient(userId, filter),
        {
            cacheTime: 0,
            enabled: active,
        }
    )

    return active ? (
        <div className={active ? 'modal active' : 'modal'} onClick={() => setActive(false)}>
            <div
                className={active ? 'modal__content active' : 'modal__content'}
                onClick={(e) => e.stopPropagation()}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="modal__content-left ">
                            <Icon
                                onClick={() => setActive(false)}
                                className="modal__content-left__img"
                                name="x"
                                width={30}
                                hover
                            />
                            <div className="modal__content-left__up">
                                <ClientListModalUp
                                    userData={data}
                                    toggle={setModalState}
                                    modalState={modalState}
                                />
                            </div>
                            <div className="modal__content-left__down">
                                <ClientListModalDown userData={data} />
                            </div>
                        </div>
                        <div className="modal__content-right">
                            {modalState === 'BOOKINGS' ? (
                                <ModalBookingsComp
                                    setFilter={(e: any) => setFilter(e)}
                                    filter={filter}
                                    userData={data}
                                />
                            ) : (
                                <ModalReviewsComp
                                    setFilter={setFilter}
                                    filter={filter}
                                    userData={data}
                                    setGetStars={setGetStars}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    ) : null
}

export default ClientModal
