import './styles.scss'
import noClientsSvg from '../../assets/images/not-found.svg'

const NoClients = (props: any) => {
    return (
        <>
            <h2>{props.name}</h2>
            <div className="noClients__Wrapper container">
                <div className="noClientsDiv">
                    <div>
                        <img src={noClientsSvg} alt="noClientsSvg" />
                    </div>
                    <p>{props.warning}</p>
                </div>
            </div>
        </>
    )
}
export default NoClients
