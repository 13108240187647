import './styles.scss'
import { StatisticsProps } from './interface'
import { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { getDashboardMain } from '../../../api/dashboard'
import moment from 'moment'
import { getNextMonth, getStartMonth } from '../../../utils/format-date'
import FromToDatepicker from '../../../components/from-to-datepicker'
import Icon from '../../../components/icon'
import LoaderDots from '../../../components/loader-dots'
type Props = StatisticsProps

// function abbreviateNumber(num: number) {
//     return new Intl.NumberFormat('en-US', {
//         maximumFractionDigits: 1,
//         notation: "compact",
//         compactDisplay: "short"
//     }).format(num)
// }
const dataKeys = ['sales', 'clients', 'services', 'revenue']
const Statistics: FC<Props> = () => {
    const isRoomPage = localStorage.userType === 'room'

    const [filters, setFilters] = useState({
        from: moment(getStartMonth()).format('YYYY-MM-DD'),
        to: moment(getNextMonth()).format('YYYY-MM-DD'),
    })
    const { data, isFetching } = useQuery(['dashboard-statistics', filters], () => getDashboardMain(filters))

    return (
        <div className="statistic white-card">
            <div className="statistic-header">
                <h4>Statistics</h4>
                <FromToDatepicker setFilters={setFilters} />
            </div>

            <div className="statistic-info">
                {dataKeys?.map((key: string) => (
                    <div key={key} className="info-block">
                        <Icon name={key} />
                        <div className="numbers">
                            <div className={'number'}>
                                {isFetching ? (
                                    <LoaderDots purple={true} relative />
                                ) : (
                                    <>
                                        {key === 'revenue' && '£'}
                                        {data?.main_statistics[key]}
                                    </>
                                )}
                            </div>

                            <span>
                                {key === 'clients'
                                    ? `Customers/Clients`
                                    : key === 'services' && isRoomPage
                                    ? 'Treatment rooms'
                                    : key}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Statistics
