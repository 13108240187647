export const statuses = [
    {
        color: '#F4C038',
        text: 'pending',
    },
    {
        color: '#28C76F',
        text: 'active',
    },
    {
        color: '#F26022',
        text: 'disabled',
    },
]
