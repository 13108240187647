import { FC } from 'react'
import { BackButtonProps } from './interface'
import './styles.scss'
import Icon from '../icon'
import { useNavigate } from 'react-router-dom'


type Props = BackButtonProps
const BackButton: FC<Props> = ({children, path= -1}) => {
    const navigate = useNavigate()
    return <div className={'backButton'}
    onClick={() => navigate(path)}
    >
    <Icon name={'arrow-back'} width={32} />
        {children}
    </div>
}
export  default  BackButton