import OnboardingProcess from '../pages/onboarding-process'
import LoginPage from '../pages/sign-up'
import PageNotFound from '../components/not-found'
import AccountVerification from '../pages/account-verification'
import ForgotPasswordPage from '../pages/forgot-password'
import OnboardingVerification from '../pages/onboarding-verification'

export const routingNoLayout = {
    login: {
        path: '/login',
        element: <LoginPage loginPage room/>,
        protected: false,
        role: 'all',
    },
    loginRoom: {
        path: '/login-room',
        element: <LoginPage loginPage room />,
        protected: false,
        role: 'all',
    },
    signup: {
        path: '/auth',
        element: <LoginPage />,
        protected: false,
        role: 'all',
    },
    signupRoom: {
        path: '/auth-room',
        element: <LoginPage room />,
        protected: false,
        role: 'all',
    },

    onboardingProcess: {
        path: '/onboarding-process',
        element: <OnboardingProcess />,
        protected: true,
        role: 'prof',
    },

    // onboardingProcessRoom: {
    //     path: '/room/onboarding-process',
    //     element: <OnboardingProcessRoom />,
    //     protected: true,
    //     role: 'room',
    // },

    notFound: {
        path: '*',
        element: <PageNotFound />,
        protected: false,
        role: 'all',
    },
    accountVerification: {
        path: '/account-verification/:userID/:token',
        element: <AccountVerification />,
        protected: false,
        role: 'all',
    },
    changePassword: {
        path: '/password-reset-verification/:uid/:token',
        element: <ForgotPasswordPage />,
        protected: false,
        role: 'all',
    },
    invitation: {
        path: '/invitation_link/:email/:uid/:token',
        element: <LoginPage team />,
        protected: false,
        role: 'all',
    },
    documentVerificationProf: {
        path: '/professional-approve/:uid/:token',
        element: <OnboardingVerification />,
        protected: false,
        role: 'all',
    },
    documentVerificationRoom: {
        path: '/room-approve/:uid/:token',
        element: <OnboardingVerification forRoom />,
        protected: false,
        role: 'all',
    },
}
