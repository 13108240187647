import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { deleteService } from '../../api/treatments-and-tariffs'
import trigger from '../../store/trigger'
import { formatMinutes } from '../../utils/timeToHours'
import { showToast } from '../../utils/toast-function'
import Button from '../button'
import Icon from '../icon'
import ModalWrapper from '../modal-wrapper'
import './styles.scss'
import { user_role } from '../../utils/user-type'
import Tooltip from '../tooltip'

const AccordionTreatments = (props: any) => {
    const [activeAccordion, setActiveAccordion] = useState(0)
    const navigate = useNavigate()
    const isRoom = user_role === 'room'
    const [isSending, setIsSending] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const [treatmentId, setTreatmentId] = useState(0)

    const deleteTreatmentFn = async () => {
        let res
        setIsSending(true)
        try {
            res = await deleteService(treatmentId)
            showToast('success', 'Treatment is deleted succesfully.')
            setModalOpen(false)
            trigger.setToggle()
            return res
        } catch (error) {
            showToast('danger', 'SomeThing went wrong. Try again later.')
        } finally {
            setIsSending(false)
        }
    }

    return (
        <>
            <ModalWrapper isShown={isModalOpen} setIsShown={setModalOpen}>
                <div className="deleteModal">
                    <h2>Are you sure?</h2>
                    <p>This action cannot be undone</p>
                    <div className="delete-btns">
                        <Button
                            text={'cancel'}
                            onClick={() => setModalOpen(false)}
                            loading={isSending}
                        />
                        <Button
                            text={'delete'}
                            outlined
                            onClick={deleteTreatmentFn}
                            loading={isSending}
                        />
                    </div>
                </div>
            </ModalWrapper>
            <div className="treatment_accordion">
                {props.name.results.map((elem: any, index: number) => (
                    <div key={elem.id} className="treatment_accordion_element">
                        <div
                            className={`element_title ${
                                index !== activeAccordion && 'cursor-pointer'
                            }`}
                            onClick={() => setActiveAccordion(index)}
                        >
                            <p
                                className={
                                    index === activeAccordion
                                        ? 'clients__title__active'
                                        : 'clients__title'
                                }
                            >
                                {elem.subcategory?.title || elem.name || elem.type?.title}
                            </p>
                            <Icon
                                width={24}
                                className={activeAccordion === index ? 'icon-rotate' : ''}
                                name={`chevron-down${
                                    activeAccordion === index ? '-black' : '-grey'
                                }`}
                            />
                        </div>
                        <div
                            className={`treatment_accordion_info ${
                                activeAccordion === index ? 'active-accordion' : ''
                            }`}
                        >
                            <div className="treatmentList_price">
                                {!!elem?.categories?.length && (
                                    <div className={' py-10 ml-10 gap-5 d-flex flex-wrap'}>
                                        {elem.categories.map((cat: any) => (
                                            <div className="icon-container" key={cat.id}>
                                                <Tooltip text={cat.title}>
                                                    <div
                                                        id={cat.title}
                                                        data-tip="Chair"
                                                        className="icon"
                                                    >
                                                        <Icon hover name={cat.icon} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {!!elem.duration && (
                                    <div className="clientsInfo">
                                        <p
                                            className="clientsInfo__header"
                                            style={{
                                                color: '#BBBBBB',
                                            }}
                                        >
                                            Duration
                                        </p>
                                        <div> {formatMinutes(elem.duration)}</div>
                                    </div>
                                )}
                                <div className="clientsInfo">
                                    <p
                                        className="clientsInfo__header"
                                        style={{
                                            color: '#BBBBBB',
                                        }}
                                    >
                                        Price
                                    </p>
                                    <div>£{elem.price}</div>
                                </div>
                                <div className="clientsInfo">
                                    <p
                                        className="clientsInfo__header"
                                        style={{
                                            color: '#BBBBBB',
                                        }}
                                    >
                                        Promo
                                    </p>
                                    <div>
                                        <Icon name={elem.deal ? 'check-mark-black' : 'minus'} />
                                    </div>
                                </div>
                            </div>

                            <div className="accButtons">
                                <Icon
                                    onClick={() =>
                                        navigate(
                                            `/edit_${isRoom ? 'tariff' : 'treatment'}/${elem.id}`
                                        )
                                    }
                                    name="pen-edit"
                                    width={24}
                                />
                                <Icon
                                    onClick={() => {
                                        setModalOpen(true)
                                        setTreatmentId(elem.id)
                                    }}
                                    name="trash"
                                    width={24}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default AccordionTreatments
