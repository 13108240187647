import './styles.scss'
import { FC } from 'react'
import { useState } from 'react'
import Icon from '../icon'
import { ModalBtn } from './interface'

type Props = ModalBtn

const ModalUpBtn: FC<Props> = ({ click, active, children, image }) => {
    const [onHover, SetOnHover] = useState(false)
    return (
        <div className="modalButtons">
            <div
                onClick={() => click(!active)}
                className={
                    active
                        ? 'toggle-btn-on toggle-btn'
                        : 'toggle-btn-off toggle-btn'
                }
                onMouseOver={() => SetOnHover(true)}
                onMouseOut={() => SetOnHover(false)}
            >
                <Icon
                    name={
                        active || onHover ? `${image}-white` : `${image}-black`
                    }
                    width={48}
                />
            </div>
            <p
                className={
                    active ? 'bookingsActive' : '' || onHover ? 'hover' : ''
                }
            >
                {children}
            </p>
        </div>
    )
}

export default ModalUpBtn
