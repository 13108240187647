import './styles.scss'
import { AvatarComponentProps } from './interface'
import { FC, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'
import Icon from '../icon'
import Button from '../button'
import {showToast} from "../../utils/toast-function";
import {updateProfessionalProfile, updateRoomProfile} from "../../api/profiles";

type Props = AvatarComponentProps

const AvatarComponent: FC<Props> = ({ closeFn, img, updatePhotoFn,userID, refetch, isRoom }) => {
    const editor = useRef() as any

    const photoChange = async() => {
        if (editor) {
            const canvasScaled = editor.current.getImageScaledToCanvas()
            const PHOTO = canvasScaled.toDataURL()
            try {
                isRoom ?
                    await  updateRoomProfile(userID, {
                    room_member_profile: { photo: PHOTO },
                }) :
                    await  updateProfessionalProfile(userID, {
                        professional_profile: { photo: PHOTO },
                    })
                showToast('success', 'Changes have been saved')
                closeFn()
                updatePhotoFn()
                refetch()
            } catch (error: any) {
                showToast('danger', 'Something went wrong, try again later')
            }
        }
    }

    return editor ? (
        <div className="avatarModal">
            <div className="avatarModal_container">
                <div className="avatarModal_title-and-close">
                    <h2>Upload a photo</h2>
                    <Icon name="close" hover onClick={closeFn} />
                </div>
                <p>Drag to adjust</p>

                <AvatarEditor
                    ref={editor}
                    border={20}
                    color={[0, 0, 0, 0.7]} // RGBA
                    scale={1.2}
                    borderRadius={2500}
                    width={300}
                    height={300}
                    image={img}
                />
                <div className="avatarModal_btns">
                    <Button text="save" onClick={photoChange} />
                    <Button text="cancel" outlined onClick={closeFn} />
                </div>
            </div>
        </div>
    ) : null
}

export default AvatarComponent
