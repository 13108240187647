import { FC } from 'react'
import Icon from '../icon'
import { ModalWrapperProps } from './interface'
import './styles.scss'
import LogoutModalStore from '../../store/logout-modal-trigger'
type Props = ModalWrapperProps

const ModalWrapper: FC<Props> = ({
    children,
    isShown,
    setIsShown,
    noClose,
    className,
    style,
    notClose,
}) => {
    const handleShow = () => {
        if (LogoutModalStore.isShowModal) {
            LogoutModalStore.setShowModal(false)
        }
        setIsShown(false)
    }
    const checkIfClickedOutside = (e: any) => {
        if (!noClose && e.target.className === 'modal-bg') {
            handleShow()
        }
    }

    const type = localStorage.getItem('onboarding')
    return type === 'true' ? null : (
        <div
            className={'modal-bg'}
            onClick={checkIfClickedOutside}
            style={isShown ? {} : { display: 'none' }}
        >
            <div className={`modal-wrapper ${className}`} style={style}>
                {!notClose && <Icon name="close" className="close" hover onClick={handleShow} />}
                {children}
            </div>
        </div>
    )
}

export default ModalWrapper
