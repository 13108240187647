import './styles.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React, { FC } from 'react'
import Slider from 'react-slick'
import Icon from '../icon'

import { SliderProps } from './interface'

type Props = SliderProps

function Arrow(props: any) {
    const { name, onClick } = props
    return (
        <div onClick={onClick} className={`custom-arrow custom-${name}`}>
            <Icon hover name={name} />
        </div>
    )
}

const RoomSlider: FC<Props> = ({ children }) => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        className: ' room-slider',
        centerMode: false,
        arrows: true,
        nextArrow: <Arrow name={'arrow-next'} />,
        prevArrow: <Arrow name={'arrow-prev'} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    swipeToSlide: true,
                    slidesToShow: 2,
                    infinite: true,
                    dots: true,
                    centerMode: false,
                    speed: 500,
                    arrows: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    swipeToSlide: true,
                    infinite: true,
                    dots: true,
                    centerMode: false,
                    speed: 500,
                    arrows: true,
                },
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1,
                    swipeToSlide: true,
                    infinite: true,
                    dots: true,
                    centerMode: false,
                    speed: 500,
                    arrows: false,
                },
            },
        ],
    }
    return <Slider {...settings}>{children}</Slider>
}

export default RoomSlider
