import { makeAutoObservable, observable } from "mobx";

class Sidebar{
    expander = true

    constructor() {
        makeAutoObservable(this,{
            expander: observable
        })
    }

    setExpander(isExpand:boolean){
        this.expander = isExpand

    }
}

export default new Sidebar()