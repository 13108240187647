import { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import Button from '../../components/button'
import DropDown from '../../components/drop-down'
import Input from '../../components/input'
import Loader from '../../components/loader/loader'
import MasterWorksCard from '../../components/master-works-card'
import NoResultFound from '../../components/no-result-found'
import PaginateComponent from '../../components/pagination'
import { getCategories, getProfessionalWorks } from '../../api/prof-works'
import './styles.scss'
import { useDebouncedCallback } from 'use-debounce'
import NoApprovePage from '../../components/no-approve-page'

const MyWorksPage: FC = () => {
    const [filters, setFilters] = useState({
        page: 1,
        treatment__category: null as any,
        search: null,
    })
    const [workListener, setWorkListener] = useState(false)
    const isApproved = JSON.parse(localStorage.getItem('approved') as string)
    const { data: categories, isLoading } = useQuery('categories', getCategories)
    const { data: works, isLoading: worksLoading } = useQuery(
        ['masterWorks', filters, workListener],
        () => getProfessionalWorks({ ...filters }),
        {
            cacheTime: 0,
        }
    )

    function filterWorks(filter: number | undefined) {
        setFilters({ ...filters, page: 1, treatment__category: filter })
    }

    const debounceValueFn = useDebouncedCallback((e) => {
        setFilters({ ...filters, page: 1, search: e.target.value })
    }, 600)

    useEffect(() => window.scrollTo(0, 0), [filters])
    return isApproved ? (
        <section className="myWorksPage container">
            <div className="title-and-btn">
                <h2>My works</h2>
                <Link to={'/add-work'}>
                    <Button text={'+ add new work'} />
                </Link>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="filters">
                        <p>Search by category</p>
                        <DropDown
                            categories={categories}
                            setCategory={filterWorks}
                            defaultName={<span style={{ color: 'grey' }}>All</span>}
                        />
                        <Input placeholder="Search by name" onChange={debounceValueFn} />
                    </div>
                    {worksLoading ? (
                        <Loader />
                    ) : works?.results?.length ? (
                        <>
                            <div className="works">
                                {works?.results?.map((work: any) => {
                                    return (
                                        <MasterWorksCard
                                            key={work.id}
                                            work={work}
                                            deleteWorkListener={setWorkListener}
                                        />
                                    )
                                })}
                            </div>
                            <PaginateComponent
                                onChange={(e: number) => setFilters({ ...filters, page: e })}
                                hidden={
                                    !works?.results?.length ||
                                    works?.total_count <= works?.page_size
                                }
                                total={works?.total_count}
                                currentPage={filters.page}
                                defaultCurrentPage={1}
                                defaultPageSize={works.page_size}
                            />
                        </>
                    ) : (
                        <NoResultFound forWorksPage />
                    )}
                </>
            )}
        </section>
    ) : (
        <NoApprovePage />
    )
}

export default MyWorksPage
