import TopBarStore from '../store/topbar'

export const handleClickOutside = () => {
    if (TopBarStore.userDropdown) {
        TopBarStore.setShowUserDropdown(false)
    }
    if (TopBarStore.notificationsDropdown) {
        TopBarStore.setShowNotificationsDropdown(false)
    }
}
