import './styles.scss'
import React, { FunctionComponent, useState } from 'react'

import { InputProps } from './interface'
import { importAll } from '../../utils/ImportIcon'
import Icon from '../icon'

type Props = InputProps

const images = importAll(
    // @ts-ignore
    require.context('../../assets/icons', false, /\.(png|jpe?g|svg)$/)
)

const Input: FunctionComponent<Props> = ({
    label,
    error,
    icon,
    type,
    useForm,
    clear,
    width = '100%',
    onClick,
    passwordEye = false,
    className,
    hint,
    style,
    ...props
}) => {
    const [isOpenEye, setIsOpenEye] = useState(false)

    const passwordType = passwordEye ? (isOpenEye ? 'text' : 'password') : null

    const styles = {
        width: '100%',
        backgroundPosition: '20px 50%',
        backgroundRepeat: 'no-repeat',
        ...style,
    }
    return (
        <div className="input-primary-wrap" style={{ width: width }}>
            {label ? <h4 className="input-primary-wrap_label">{label}</h4> : null}
            {hint ? <div className="hint">{hint}</div> : null}

            <input
                onWheel={(e: any) => e.target.blur()}
                style={
                    icon
                        ? {
                              backgroundImage: `url(${images[icon + '_active.svg']})`,
                              ...styles,
                          }
                        : styles
                }
                {...props}
                {...useForm}
                type={passwordType || type}
                className={` ${className} input-primary
                ${error ? 'input-primary__error' : ''}
                ${icon ? 'input-primary__withIcon' : ''}
                ${type === 'datetime-local' ? 'input-primary__datetime' : ''}`}
            />
            <span>{error}</span>
            {passwordEye ? (
                <Icon
                    name={isOpenEye ? 'eye_open' : 'eye_closed'}
                    hover
                    onClick={() => setIsOpenEye(!isOpenEye)}
                />
            ) : null}
            {clear && <Icon name="close" className="clear" onClick={onClick} />}
        </div>
    )
}

export default Input
