import './styles.scss'
import RoomSlider from '../../../components/room-slider'
import {ChangeEvent, FC, useState} from 'react'
import Icon from '../../../components/icon'
import { PhotoZone } from './interface'
import {createCardPhotosRoom, deleteCardPhoto, deleteCardPhotoRoom, editCardPhotoRoom} from '../../../api/profiles'
import Tooltip from '../../../components/tooltip'
import plus from '../../../assets/images/plus-img.png'
import Button from "../../../components/button";
import ModalWrapper from "../../../components/modal-wrapper";

type Props = PhotoZone
const DropzoneBusiness: FC<Props> = ({ roomData, refetch }) => {
    const [isSending, setIsSending] = useState(false)
    const [isShown, setShow] = useState(false)
    const [photoID, setId] = useState<any>(null)
    let loopNum = 5 - roomData?.photos?.length

    for (let i = 0; i < loopNum; i++) {
        roomData?.photos.push({ photo: '' })
    }

    //create function
    const handleSetWorkImage = async (e: ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        const file = e.target.files[0]
        if (file) {
            await handleSubmit(file)
        }
    }

    const handleSubmit = async (file: File) => {
        const formData = new FormData()
        formData.append('photo', file)
        try {
            await createCardPhotosRoom(formData)
            refetch()
        } catch (error: any) {
            throw new Error(error)
        }
    }

    //delete function

    const handleDeleteModal = (id: number) => {
        setShow(!isShown)
        setId(id)
    }

    const handleDeleteCardPhoto = async() => {
        await deleteCardPhotoRoom(photoID)
        refetch()
        setShow(false)
    }

    //update function
    const handleUpdateWorkImage = async (e: ChangeEvent<HTMLInputElement>, id: number) => {
        // @ts-ignore
        const file = e.target.files[0]
        if (file) {
            await handleEditCardPhoto(id, file)
            refetch()
        }
    }
    const handleEditCardPhoto = async (id: number, e: any) => {
        const formData = new FormData()
        formData.append('photo', e)
        try {
            await editCardPhotoRoom(id, formData)
            refetch()
        } catch (error: any) {
            throw new Error(error)
        } finally {
        }
    }

    let inputRef: HTMLInputElement | null

    const handleRefClick = () => {
        inputRef?.click()
    }

    return (
        <>
            <div className="container drop-zone">
                <div className="slider-dropzone">
                    <RoomSlider>
                        {roomData?.photos?.map((item: any, index: number) => (
                            <div className={'dropzone-container'} key={index}>
                                <input
                                    type={'file'}
                                    hidden
                                    id={'img-uploader' + index}
                                    accept={'image/*'}
                                    onChange={(e) => handleSetWorkImage(e)}/>
                                {item.photo ? (
                                    <div className="preview-wrapper">
                                        <img
                                            src={item.photo}
                                            alt={'worksPhoto'}
                                            className="preview-img "/>
                                        <div className="action-btn">
                                            <button
                                                className="delete-btn"
                                                onClick={() => handleDeleteModal(item.id)}
                                            >
                                                <Icon name={'delete-icon'} height={32} width={32}/>
                                            </button>
                                            <input
                                                hidden
                                                type="file"
                                                ref={(refParam) => (inputRef = refParam)}
                                                className="edit-btn"
                                                onChange={(e) => handleUpdateWorkImage(e, item.id)}/>
                                            <Icon
                                                name={'edit-icon'}
                                                onClick={handleRefClick}
                                                height={32}
                                                width={32}/>
                                        </div>
                                    </div>
                                ) : (
                                    <label
                                        htmlFor={'img-uploader' + index}
                                        className="img-uploader-zone-business"
                                    >
                                        <img src={plus} className="plus-img" alt={'plus'}/>
                                        <div className="inside-txt">
                                            <span>Click to add a photo</span>
                                            <Tooltip
                                                text={"This photo will be displayedon specific pages of clients' website. You can add maximum 5 photos "}
                                            >
                                                <Icon name={'get-info'} height={24} width={24}/>
                                            </Tooltip>
                                        </div>
                                    </label>
                                )}
                            </div>
                        ))}
                    </RoomSlider>
                </div>
            </div>
            <ModalWrapper isShown={isShown} setIsShown={setShow}>
                <div className={'delete-photo-confirmation'}>
                    <h4>Are you sure?</h4>
                    <p>Do you want to delete this file? This process cannot be undone if you delete</p>
                    <div className={'delete-photo-action'}>
                        <Button text={'CANCEL'} onClick={() => setShow(false)} loading={isSending}/>
                        <Button
                            text={'delete'}
                            outlined
                            className={'delete-photo-button'}
                            onClick={handleDeleteCardPhoto}
                            loading={isSending}/>
                    </div>
                </div>
            </ModalWrapper></>
    )
}
export default DropzoneBusiness
