import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../components/button'
import Icon from '../../components/icon'
import Input from '../../components/input'
import { sendEmail } from '../../api/auth'
import { ForgotPasswordProps } from './interface'
import './styles.scss'

type Props = ForgotPasswordProps

const ForgotPassword: FC<Props> = ({ setShowModal, isShownModal }) => {
    const [isSending, setIsSending] = useState(false)
    const [isOkEmail, setIsOkEmail] = useState(false)
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm()

    async function onSubmit(data: any) {
        let res
        setIsSending(true)
        try {
            res = await sendEmail({ ...data, current_url: process.env.REACT_APP_CURRENT_URL })
            if (res?.email) {
                setIsOkEmail(true)
            }
        } catch (error: any) {
            if (error.response.data.email?.length) {
                setError('email', {
                    type: 'custom',
                    message: error.response.data.email[0],
                })
            } else {
                setError('email', {
                    type: 'custom',
                    message: error.response.data.detail || error.response.data.error,
                })
            }
        } finally {
            setIsSending(false)
        }
    }

    useEffect(() => {
        setIsOkEmail(!isShownModal)
    }, [isShownModal])

    return (
        <div className="forgot-password-modal">
            <h2>{isOkEmail ? 'Please check your email' : 'Reset password'}</h2>
            {!isOkEmail ? (
                <>
                    <p>
                        Enter your email address and we will send you a link to reset your password
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            placeholder="Enter your e-mail address"
                            label="E-mail*"
                            required
                            type="email"
                            useForm={register('email', {
                                required: 'This field is required',
                            })}
                            error={errors?.email?.message}
                        />
                        <Button text={'send'} loading={isSending} />
                    </form>
                    <div className="back-btn" onClick={() => setShowModal(false)}>
                        <Icon name="arrow-back" />
                        <span>Back</span>
                    </div>
                </>
            ) : (
                <p className="isOkMessage">
                    A Reset Password link has been sent to your email account
                </p>
            )}
        </div>
    )
}

export default ForgotPassword
