import { user_role } from '../utils/user-type'
import { request } from './api'

export async function getCalendarBookings(params) {
    return request(`${user_role}/calendar/`, 'GET', null, null, params)
}

export async function getSchedule() {
    return request(`${user_role}/schedule/`, 'GET')
}
export async function updateSchedule(data) {
    return request(`${user_role}/schedule/create/`, 'POST', data)
}
export async function createBlockedTime(data) {
    return request(`${user_role}/blocked-time/create/`, 'POST', data)
}
export async function getBlockedTimeList() {
    return request(`${user_role}/blocked-time/`, 'GET')
}
export async function updateBlockedTime(id, data) {
    return request(`${user_role}/blocked-time/update/${id}/`, 'PUT', data)
}
export async function deleteBlockedTime(id) {
    return request(`${user_role}/blocked-time/delete/${id}/`, 'DELETE')
}
