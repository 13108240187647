import { user_role } from '../utils/user-type'
import { request } from './api'

export async function getNotificationsList(filter) {
    return request(`${user_role}/notifications/`, 'GET', null, null, filter)
}
export async function getNotificationDetails(id) {
    return request(`${user_role}/notifications/${id}/`, 'GET')
}
export async function getNotificationMark(id) {
    return request(`${user_role}/notifications/mark-as-read/${id}/`, 'GET')
}
export async function getNotificationMarkAll() {
    return request(`${user_role}/notifications/mark-all-as-read/`, 'GET')
}
export async  function getLastNotifications() {
    return request(`${user_role}/notifications/short-list/`, 'GET')
}