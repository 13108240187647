import React from 'react'

function Notification() {
    return (
        <>
            <div className="icon">
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18.9964 23.2641C18.2022 24.4547 17.2104 25.05 16.0198 25.05C14.8292 25.05 13.8374 24.4547 13.0432 23.2641M22.0741 21.4781H9.96547C9.61761 21.4783 9.27595 21.3861 8.9754 21.2109C8.67485 21.0358 8.4262 20.784 8.25486 20.4813C8.08351 20.1785 7.99562 19.8357 8.00017 19.4879C8.00471 19.1401 8.10153 18.7997 8.28073 18.5016C9.44925 16.554 10.0666 14.3255 10.0667 12.0543V10.7625C10.0667 9.49941 10.5684 8.28805 11.4616 7.3949C12.3547 6.50176 13.5661 6 14.8292 6H17.2104C18.4735 6 19.6849 6.50176 20.578 7.3949C21.4712 8.28805 21.9729 9.49941 21.9729 10.7625V12.0543C21.9729 14.3248 22.5897 16.5549 23.7589 18.5016C23.9381 18.7997 24.0349 19.1401 24.0394 19.4879C24.044 19.8357 23.9561 20.1785 23.7847 20.4813C23.6134 20.784 23.3647 21.0358 23.0642 21.2109C22.7636 21.3861 22.422 21.4783 22.0741 21.4781V21.4781Z"
                        stroke="currentColor"
                        strokeWidth="1.14286"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
            <div className="mobileIcon">
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.143 14.5716C10.5712 15.4288 9.85724 15.8574 9.00009 15.8574C8.14295 15.8574 7.42895 15.4288 6.85724 14.5716M13.3587 13.2859H4.64152C4.3911 13.286 4.14513 13.2197 3.92876 13.0936C3.7124 12.9675 3.53339 12.7862 3.41004 12.5683C3.28668 12.3503 3.22341 12.1036 3.22668 11.8532C3.22996 11.6028 3.29966 11.3577 3.42867 11.1431C4.26989 9.74101 4.7143 8.1367 4.71438 6.50164V5.57164C4.71438 4.66232 5.0756 3.79025 5.71858 3.14727C6.36157 2.50429 7.23364 2.14307 8.14295 2.14307H9.85724C10.7665 2.14307 11.6386 2.50429 12.2816 3.14727C12.9246 3.79025 13.2858 4.66232 13.2858 5.57164V6.50164C13.2858 8.13621 13.7298 9.74164 14.5715 11.1431C14.7005 11.3577 14.7702 11.6028 14.7735 11.8532C14.7768 12.1036 14.7135 12.3503 14.5902 12.5683C14.4668 12.7862 14.2878 12.9675 14.0714 13.0936C13.8551 13.2197 13.6091 13.286 13.3587 13.2859V13.2859Z"
                        stroke="currentColor"
                        strokeWidth="1.14286"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        </>
    )
}

export default Notification
