import { FunctionComponent, useState } from 'react'
import { changeWorkReaction } from '../../api/prof-works'
import Icon from '../icon'
import { ReactionsProps } from './interface'
import './styles.scss'

type Props = ReactionsProps

const Reactions: FunctionComponent<Props> = ({ data }) => {
    const [reactionId, setReactionId] = useState(data.my_reaction)
    const [work] = useState({ ...data })
    const currentReaction = work.reactions.find((reaction: any) => reaction.id === reactionId)
    function selectReaction(reaction: any) {
        let newReactionId
        if (reactionId === reaction.id) {
            newReactionId = null
            reaction.count--
            work.reactions_count--
        } else {
            newReactionId = reaction.id
            reaction.count++
            reactionId ? currentReaction.count-- : work.reactions_count++
        }
        changeWorkReaction(data.id, { reaction: reaction.id })
        setReactionId(newReactionId)
    }
    function reactionsCount(count: number | string) {
        let maxNumber = count === 'total' ? 10000 : 1000 //Максимальное число до сокращения количетва реакций
        let currentNumber = count === 'total' ? work.reactions_count : count // разбираемся это общее количество, или отдельное для каждой реакции
        return currentNumber < maxNumber ? currentNumber : `${currentNumber}`.slice(0, -3) + 'k' // сокращаем до 'k', если количество отдельной реакции больше 1000, и для общего количества 10000 соответственно

    }

    return (
        <div className="reactionsBtn">
            <div className="hover_container">
                <div className="reactions">
                    {work.reactions.map((reaction: any) => (
                        <div key={reaction.id} className="reaction" onClick={() => selectReaction(reaction)}>
                            <img src={reaction.icon} alt={reaction.title} />
                            <p>{reactionsCount(reaction.count)}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* currentReaction */}
            {reactionsCount('total') > 0 ? (
                <Icon name="heart_full" width={32} height={32} />
            ) : (
                // <img src={currentReaction.icon} alt="your-reaction" />
                <Icon name="heart_active" width={32} height={32} />
            )}
            <p>{reactionsCount('total')}</p>
        </div>
    )
}

export default Reactions
