import './styles.scss'
import Input from '../../../components/input'
import Button from '../../../components/button'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getRoomMemberProfile } from '../../../api/api'
import { updateRoomProfile } from '../../../api/profiles'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import Icon from '../../../components/icon'
import AvatarComponent from '../../../components/avatar-editor'
import { showToast } from '../../../utils/toast-function'
import Loader from '../../../components/loader/loader'
import 'react-datepicker/dist/react-datepicker.css'
import { observer } from 'mobx-react-lite'

const PersonalInfoForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
    } = useForm<any>()

    const navigate = useNavigate()
    const profileId = localStorage.userID && Number(localStorage.userID)

    const { data, refetch, isLoading } = useQuery(['roomMember'], () =>
        getRoomMemberProfile(profileId)
    )
    const USER = { ...data?.room_member_profile }
    delete USER.id
    delete USER.user
    USER.email = data?.email

    useEffect(() => {
        reset(USER)
    }, [data])

    const [isEditorOpen, setEditorOpen] = useState(false)
    const [image, setImage] = useState('')
    const [forUpdatePhoto, setUpdatePhoto] = useState(false)
    const [sending, setIsSending] = useState(false)
    const hiddenFileInput = useRef() as any

    const onSubmit = async (data: any) => {
        let res
        const treatment_cat = data?.treatment_cat?.map((category: any) => category.id)
        const newProfile = { email: data?.email, room_member_profile: {} }
        delete data.email
        delete data.photo
        newProfile.room_member_profile = { ...data, treatment_cat: treatment_cat }
        setIsSending(true)
        try {
            res = await updateRoomProfile(profileId, newProfile)
            showToast('success', 'Changes have been saved')
            setIsSending(false)
            return res
        } catch (error: any) {
            if (error.response.data.email?.length) {
                setError('email', {
                    type: 'custom',
                    message: error.response.data.email[0],
                })
            } else {
                setError('name', {
                    type: 'custom',
                    message: 'Something went wrong. Try again later',
                })
            }
        } finally {
            setIsSending(false)
        }
    }

    function handleClick() {
        hiddenFileInput.current.click()
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const fileUploaded = event.target.files && event.target.files[0]
        if (fileUploaded) {
            const imgURL = URL.createObjectURL(fileUploaded)
            setImage(imgURL)
            setEditorOpen(true)
        }
    }

    function closeAvatarEditor() {
        hiddenFileInput.current.type = 'text'
        hiddenFileInput.current.type = 'file'
        setEditorOpen(false)
    }

    useEffect(() => {
        const media = window.matchMedia('(max-width: 768px)')
        if (media.matches) {
            window.scrollTo(0, 60)
        } else {
            window.scrollTo(0, 90)
        }
    }, [])

    return (
        <div className="room-basic-info-wrapper">
            {isEditorOpen ? (
                <AvatarComponent
                    isRoom
                    refetch={refetch}
                    img={image}
                    closeFn={closeAvatarEditor}
                    userID={profileId}
                    updatePhotoFn={() => setUpdatePhoto(!forUpdatePhoto)}
                />
            ) : null}

            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <form className="container basic-info-room" onSubmit={handleSubmit(onSubmit)}>
                        <div className={'photo-zone-room'}>
                            <input
                                ref={hiddenFileInput}
                                type="file"
                                onChange={handleChange}
                                style={{ display: 'none' }}
                            />
                            <div className="userProfile_photo" onClick={handleClick}>
                                {USER.photo ? (
                                    <img src={USER.photo} alt="userImg" />
                                ) : (
                                    <Icon name="user-placeholder-2" width={64} height={64} />
                                )}
                                <div className="hover_info">
                                    <p>Upload photo</p>
                                </div>
                                <div className="icon_for_photo">
                                    <Icon
                                        name={
                                            // userPhoto
                                            //     ? 'close_in_circle_white'
                                            //     :
                                            'photo_white'
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="basic-info-room__grid-view">
                            <Input
                                label="First name*"
                                placeholder="Enter your first name"
                                className="basic-info-input"
                                useForm={register('name', {
                                    required: 'This field is required',
                                })}
                                error={errors.name?.message}
                            />
                            <Input
                                label="Position*"
                                placeholder="Enter your position"
                                className="basic-info-input"
                                useForm={register('position', {
                                    required: 'This field is required',
                                })}
                                error={errors.position?.message}
                            />
                            <Input
                                label="Surname*"
                                placeholder="Enter your last name"
                                className="basic-info-input"
                                useForm={register('surname', {
                                    required: 'This field is required',
                                })}
                                error={errors.surname?.message}
                            />
                            <Input
                                label="Email address*"
                                placeholder="Enter your email"
                                className="basic-info-input"
                                useForm={register('email', {
                                    required: 'This field is required',
                                })}
                                error={errors.email?.message}
                            />

                            <Input
                                label="Phone number"
                                placeholder="Enter your phone number"
                                className="basic-info-input"
                                type={'number'}
                                useForm={register('phone', { valueAsNumber: true })}
                                error={errors.phone?.message}
                            />
                        </div>
                        <Button text={'update'} loading={sending} />
                    </form>
                </>
            )}

            <div className="container">
                <button
                    className="delete-account"
                    onClick={() => {
                        navigate('/account/delete')
                    }}
                >
                    Delete my account
                </button>
            </div>
        </div>
    )
}
export default observer(PersonalInfoForm)
