import React, { FC, useState } from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import Icon from '../../components/icon'
import { getNotificationMark } from '../../api/notifications'
import moment from 'moment'
import { NotificationDropdownCardProps } from './interface'
import { notificationTypesData } from '../../utils/notification-types-data'
import NotificationsCountStore from '../../store/notifications'

type Props = NotificationDropdownCardProps

const NotificationDropdownCard: FC<Props> = ({ data }) => {
    const navigate = useNavigate()
    const [isRead, setIsRead] = useState(data.is_read)

    const markAsRead = (e: any) => {
        e.preventDefault()
           getNotificationMark(data?.id).then(() => NotificationsCountStore.triggerCount())

    }

    const goToDetailed = async (e: any) => {
        if (data?.type === 'message') {
            navigate(`/bookings/${data.booking}/?open-chat=true`)
        } else {
            navigate(`/notifications/${data.id}`)
        }
            await markAsRead(e)
    }

    return (
        <>
            <div onClick={(e: any) => goToDetailed(e)} >
                <div className="notification-dropdown-card">
                    <div className={'notification-dropdown-card__header'}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Icon name={`${data.type}-small`} width={22} height={22} />
                            <h6>{notificationTypesData[data.type].text}</h6>
                        </div>
                        <div className={`notification-mark ${(isRead || data.is_read) && 'is_read' }`} onClick={(e: any) => markAsRead(e)}>
                            <span>Mark as read</span>
                        </div>
                    </div>
                    <div className={'notification-dropdown-card__content'}>
                        <p>{data?.message}</p>
                        <span>{moment.utc(data?.created_at).fromNow()}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationDropdownCard
