import React, { useEffect, useState } from 'react'
import ModalWrapper from '../../components/modal-wrapper'
import ModalStore from '../../store/blocked-time-modal'
import Input from '../../components/input'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import Button from '../../components/button'
import { schedule_times } from '../../utils/schedule-times'
import DropDownTime from '../../components/drop-down-time'
import Switch from '../../components/switch-input'
import './styles.scss'
import CustomCheckbox from '../../components/custom-checkbox'
import { createBlockedTime, deleteBlockedTime, updateBlockedTime } from '../../api/calendar'
import { showToast } from '../../utils/toast-function'
import { user_role } from '../../utils/user-type'
import { useQuery } from 'react-query'
import { getServicesList } from '../../api/treatments-and-tariffs'
import DropDown from '../../components/drop-down'
import TextAreaCustom from '../../components/Textarea-custom/text-area-custom'
const BlockedTimeModal = () => {
    const forRoom = user_role === 'room'
    const [forOneDay, setForOneDay] = useState(true)
    const [blockDay, setBlockDay] = useState(false)
    const { isShown, setShown, currentTime } = ModalStore
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
    } = useForm()
    const date_now = moment().format('YYYY-MM-DD')
    const { data: tariffs } = useQuery(
        'tariffsList',
        () =>
            getServicesList({
                page: 1,
                page_size: 100000,
                search: '',
            }),
        {
            cacheTime: 0,
            enabled: forRoom,
        }
    )
    const start_time_index = schedule_times.findIndex((time) => time === watch('start_time'))
    const endSchedule = schedule_times.slice(start_time_index + 2, 100)
    const closeModal = () => {
        setShown(false, null)
        reset({})
    }
    const handleDeleteBlockedTime = () => {
        deleteBlockedTime(currentTime.id)
            .then(() => {
                showToast('success', 'Blocked time successfully deleted ')
                closeModal()
            })
            .catch(() => showToast('danger', 'Something went wrong'))
    }
    const onSubmit = (data: any) => {
        if (blockDay) {
            data.start_time = '00:00'
            data.end_time = '23:30'
        }
        if (forOneDay) {
            data.date_to = data.date_from
        }
        if (forRoom && !data.tariff) {
            data.tariff = 'all'
        }
        if (data.description.length > 100) {
            return null
        }
        ;(currentTime ? updateBlockedTime(currentTime?.id, data) : createBlockedTime(data))
            .then(() => {
                closeModal()
                showToast('success', 'Blocked time successfully created')
            })
            .catch(() => {
                showToast('danger', 'Something went wrong')
            })
    }

    useEffect(() => {
        if (currentTime && (!watch('id') || watch('id') !== currentTime.id)) {
            const newTime = {
                ...currentTime,
                date_from: moment(currentTime.date_from, 'DD/MM/yyyy').format('yyyy-MM-DD'),
                date_to: moment(currentTime.date_to, 'DD/MM/yyyy').format('yyyy-MM-DD'),
            }
            if (newTime.date_from !== newTime.date_to) {
                setForOneDay(false)
            } else {
                setForOneDay(true)
            }
            reset(newTime)
        } else {
            setValue('tariff', 'all')
        }
    }, [currentTime])
    const selectTariffs = tariffs?.results?.map((t: any) => ({ title: t.name, id: t.id }))

    return (
        <ModalWrapper isShown={isShown} setIsShown={closeModal}>
            {isShown && (
                <form className={'blocked-time'} onSubmit={handleSubmit(onSubmit)}>
                    <h4 style={{ paddingRight: 30 }}>Blocked time</h4>
                    {forRoom && (
                        <>
                            <p
                                style={{ paddingTop: 20, color: '#3A393A' }}
                                className="promo-inputs__label"
                            >
                                Tariff
                            </p>
                            {currentTime ? (
                                <p style={{ paddingTop: 10 }}>{currentTime?.tariff || 'Room 1'}</p>
                            ) : (
                                <DropDown
                                    zIndex={101}
                                    categories={selectTariffs}
                                    defaultCategory={'All'}
                                    setCategory={(e) => setValue('tariff', e || 'all')}
                                />
                            )}
                        </>
                    )}
                    <div className="blocked-time_days">
                        <div className={'blocked-time_date'}>
                            <p className="promo-inputs__label">
                                {forOneDay ? 'Date' : 'Start date'}
                            </p>
                            <Input
                                width="100%"
                                type="date"
                                min={'2023-01-01'}
                                defaultValue={date_now}
                                max="2030-12-31"
                                useForm={register('date_from', {
                                    required: 'This field is required',
                                })}
                                error={errors?.date_from?.message}
                            />
                        </div>
                        <div className={forOneDay ? 'd-none' : 'blocked-time_date'}>
                            <p className="promo-inputs__label">End date</p>
                            <Input
                                width="100%"
                                type="date"
                                min={forOneDay ? undefined : watch('date_from')}
                                defaultValue={date_now}
                                max="2030-12-31"
                                useForm={register('date_to', {
                                    required: 'This field is required',
                                })}
                                error={errors.date_to?.message}
                            />
                        </div>
                    </div>
                    {!currentTime && (
                        <div className="one-day-switch">
                            <Switch
                                defaultChecked={forOneDay}
                                onChange={(e) => {
                                    if (e) {
                                        setValue('date_to', watch('date_from'))
                                    }
                                    setForOneDay(e)
                                }}
                            />
                            <p>One day</p>
                        </div>
                    )}
                    <div className={`blocked-time_time ${blockDay && 'disabled'}`}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            {<p>Start time</p>}

                            <DropDownTime
                                schedule={schedule_times}
                                onChange={(value) => {
                                    setValue(`start_time`, value)
                                }}
                                selectKey={`1`}
                                zIndex={100}
                                key={`start`}
                                value={watch('start_time')}
                            />
                        </div>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <p>End time</p>

                            <DropDownTime
                                schedule={endSchedule}
                                onChange={(value) => setValue(`end_time`, value)}
                                selectKey={`2`}
                                zIndex={99}
                                key={`end`}
                                value={watch('end_time')}
                            />
                        </div>
                    </div>
                    <div className={'d-flex'}>
                        <div
                            className="block-all-day cursor-pointer"
                            onClick={() => setBlockDay(!blockDay)}
                        >
                            <div className="checkbox">
                                <CustomCheckbox checked={blockDay} width={24} />
                            </div>
                            <p>Block all day</p>
                        </div>
                    </div>
                    <div className="blocked-time_description">
                        <p>Description</p>
                        <p className="description_substring">Description can be seen only by you</p>
                        <TextAreaCustom
                            error={errors?.description}
                            defaultValue={currentTime?.description}
                            register={register}
                            register_key={'description'}
                        />
                    </div>
                    <div className="blocked-time-btns">
                        <Button
                            type={'button'}
                            danger={!!currentTime}
                            outlined
                            text={currentTime ? 'delete' : 'cancel'}
                            onClick={currentTime ? handleDeleteBlockedTime : closeModal}
                        />
                        <Button text={'save'} />
                    </div>
                </form>
            )}
        </ModalWrapper>
    )
}

export default BlockedTimeModal
