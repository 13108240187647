import { toast } from 'react-toastify'
import Icon from '../components/icon'

export const showToast = (name, text) => {
    toast(text, {
        position: toast.POSITION.TOP_RIGHT,
        icon: ({ theme, type }) => <Icon name={`toast-${name}`} />,
        className: 'custom-toast',
    })
}
