import moment from 'moment'
import { FC, useCallback, useMemo, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import './styles.scss'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useQuery } from 'react-query'
import { getCalendarBookings, getSchedule } from '../../api/calendar'
import NoApprovePage from '../../components/no-approve-page'
import Button from '../../components/button'
import { useNavigate } from 'react-router-dom'
import BlockedTimeModal from '../../modules/blocked-time-modal'
import ModalStore from '../../store/blocked-time-modal'
import { observer } from 'mobx-react-lite'
import Icon from '../../components/icon'
import { user_role } from '../../utils/user-type'
import { getServicesList } from '../../api/treatments-and-tariffs'
import Loader from '../../components/loader/loader'
import DropDown from '../../components/drop-down'
import CalendarEvent from '../../components/calendar-event/calendar-event'
import CalendarDayEvent from '../../components/calendar-event/calendar-day-event'
const localizer = momentLocalizer(moment)
const CalendarPage: FC = () => {
    const forRoom = user_role === 'room'
    const [params, setParams] = useState({ tariff: '' as any })
    const { isShown, setShown } = ModalStore
    const navigate = useNavigate()

    const { data } = useQuery(['calendar', isShown, params], () =>
        getCalendarBookings(forRoom ? params : null)
    )
    const { data: schedule, isLoading: scheduleLoading } = useQuery('schedule-calendar', () =>
        getSchedule().then((res) => {
            const sunday = res.pop()
            res.unshift(sunday)
            return res
        })
    )
    const { data: tariffs, isLoading: tariffLoading } = useQuery(
        'tariffsList',
        () =>
            getServicesList({
                page: 1,
                page_size: 100000,
                search: '',
            }),
        {
            cacheTime: 0,
            enabled: forRoom,
        }
    )
    const isApproved = JSON.parse(localStorage.getItem('approved') as string)

    const convertDataToCalendar = (data: any) => {
        return data?.map((event: any) => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end),
        }))
    }

    const formats = {
        timeGutterFormat: (date: any, culture: any, localizer: any) =>
            localizer.format(date, 'HH:mm', 'en-GB'),

        dayFormat: (date: any, culture: any, localizer: any) =>
            localizer.format(date, 'DD ddd', culture),
        //@ts-ignore
        agendaHeaderFormat: ({ start, end }, culture: any, localizer: any) => {
            return (
                localizer.format(start, 'DD/MM', culture) +
                ' - ' +
                localizer.format(end, 'DD/MM | YYYY', culture)
            )
        },
    }

    const slotPropGetter = useCallback(
        (date) => ({
            className: 'slotDefault',
            ...(schedule[moment(date).day()].time_slots.some((time: any, index: number) => {
                const { time_slots, active } = schedule[moment(date).day()]
                const [start_hour, start_minutes] = time.start_time.split(':')
                const next_start_hours =
                    !!time_slots[index + 1] &&
                    Number(time_slots[index + 1].start_time.split(':')[0])
                const previous_end_hour =
                    index && Number(time_slots[index - 1].end_time.split(':')[0])
                const previous_end_minutes =
                    !!index && Number(time_slots[index - 1].end_time.split(':')[1])
                const [end_hour, end_minutes] = time.end_time.split(':')
                const hour = moment(date).hour()
                const minutes = moment(date).minutes()
                return (
                    !active ||
                    (!next_start_hours && !index
                        ? hour < Number(start_hour) ||
                          (hour === Number(start_hour) && minutes < Number(start_minutes)) ||
                          hour > Number(end_hour) ||
                          (hour === Number(end_hour) && minutes >= Number(end_minutes))
                        : ((hour < Number(start_hour) ||
                              (hour === Number(start_hour) && minutes < Number(start_minutes))) &&
                              (hour > previous_end_hour ||
                                  (hour === previous_end_hour &&
                                      minutes >= previous_end_minutes))) ||
                          (next_start_hours
                              ? false
                              : hour > Number(end_hour) ||
                                (hour === Number(end_hour) && minutes >= Number(end_minutes))))
                )
            }) && {
                style: {
                    backgroundColor: '#EFEFEF',
                    color: 'black',
                },
            }),
        }),
        [schedule]
    )
    const { components } = useMemo(
        () => ({
            components: {
                event: CalendarEvent,
                day: {
                    event: CalendarDayEvent,
                },
            },
        }),
        []
    )
    const newData = convertDataToCalendar(data)
    const selectTariffs = tariffs?.results?.map((t: any) => ({ title: t.name, id: t.id }))

    return isApproved ? (
        (forRoom && tariffLoading) || scheduleLoading ? (
            <Loader />
        ) : (
            <section className="calendarPage container">
                <div
                    className={`calendar_select-box flex-md-wrap justify-content-md-end  d-flex align-items-end gap-15-20 justify-content-${
                        forRoom ? 'between' : 'end'
                    }`}
                >
                    {forRoom && (
                        <div style={{ width: '100%' }}>
                            <p>Tariff</p>
                            <DropDown
                                zIndex={100}
                                defaultCategory={'All'}
                                categories={selectTariffs}
                                setCategory={(e) => setParams({ tariff: e })}
                            />
                        </div>
                    )}
                    <div className="calendar-btns">
                        <Button
                            className={'lg-btn'}
                            text={'blocked time'}
                            outlined
                            disabled={forRoom && !selectTariffs?.length}
                            onClick={() => setShown(true)}
                        />
                        <Button
                            className={'lg-btn'}
                            text={'working hours'}
                            outlined
                            onClick={() => navigate('working-hours/')}
                        />
                        <Button
                            className={'sm-btn'}
                            text={<Icon name={'clock_white'} />}
                            disabled={forRoom && !selectTariffs?.length}
                            onClick={() => setShown(true)}
                        />
                        <Button
                            className={'sm-btn'}
                            text={<Icon name={'calendar_white'} />}
                            onClick={() => navigate('working-hours/')}
                        />
                    </div>
                </div>
                <div className="calendarCard">
                    <Calendar
                        localizer={localizer}
                        startAccessor="start"
                        endAccessor="end"
                        defaultView={'week'}
                        className={'business_calendar'}
                        step={30}
                        showMultiDayTimes
                        components={components}
                        formats={formats}
                        min={new Date(2023, 0, 1, 6, 0, 0)}
                        max={new Date(2025, 0, 1, 23, 59, 59)}
                        events={newData || []}
                        timeslots={1}
                        slotPropGetter={slotPropGetter}
                    />
                </div>
                <BlockedTimeModal />
            </section>
        )
    ) : (
        <NoApprovePage />
    )
}

export default observer(CalendarPage)
