import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../components/button'
import Icon from '../../components/icon'
import Input from '../../components/input'
import PasswordInput from '../../components/sign-up-password-input'
import { roomMemberRegistration } from '../../api/auth'
import { SignUpFormProps } from './interface'
import CustomCheckbox from '../../components/custom-checkbox'
import { showToast } from '../../utils/toast-function'

type Props = SignUpFormProps

const SignUpFormTeam: FC<Props> = ({ setLogin, setOkRegister }) => {
    const { email, uid, token } = useParams()
    const [isGoodPassword, setIsGoodPassword] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [active, setActive] = useState(false)
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        setError,
        resetField,
        reset,
        formState: { errors },
    } = useForm()

    async function registerUser(data: any) {
        setIsSending(true)
        try {
            await roomMemberRegistration(email, uid, token, data)
            setLogin(true)
            setOkRegister(true)
            reset()
        } catch (error: any) {
            if (error.response.data.email?.length) {
                setError('email', {
                    type: 'custom',
                    message: error.response.data.email[0],
                })
            } else {
                showToast('danger', 'Something went wrong. Try again later')
            }
            throw new Error(error)
        } finally {
            setIsSending(false)
        }
    }
    const onSubmit = async (data: any) => {
        if (!isGoodPassword) {
            setError('password', {
                type: 'custom_one',
                message: 'The password does not meet the requirements',
            })
        } else if (data.password !== data.confirm_password) {
            resetField('confirm_password')
            setError('password', {
                type: 'custom',
                message: "Passwords don't match",
            })
            setError('confirm_password', {
                type: 'custom',
                message: ' ',
            })
        } else {
            await registerUser(data)
        }
    }

    return (
        <div className="signUp-container">
            <Icon className={'arrow-back'} name="arrow-back-login" onClick={() => navigate(-1)} />
            <div className="mobile_info">
                <h2>Sign up</h2>
                <p>
                    Already have an account? <span onClick={() => setLogin(true)}>Log in</span>
                </p>
            </div>
            <form className={`signUp ${isSending && 'sending'}`} onSubmit={handleSubmit(onSubmit)}>
                <Input
                    label="First name*"
                    placeholder="Enter your first name*"
                    useForm={register('name', {
                        required: 'This field is required',
                    })}
                    error={errors.name?.message}
                />
                <Input
                    label="Last name*"
                    placeholder="Enter your last name*"
                    useForm={register('surname', {
                        required: 'This field is required',
                    })}
                    error={errors.surname?.message}
                />
                <Input
                    label="E-mail*"
                    placeholder="Enter your e-mail address*"
                    useForm={register('email', {
                        required: 'This field is required',
                    })}
                    error={errors.email?.message}
                />
                <PasswordInput
                    label="Your password*"
                    placeholder="Enter your password*"
                    passwordEye
                    register={register}
                    setGoodPassword={setIsGoodPassword}
                    error={errors.password?.message}
                />
                <Input
                    label="Confirm password"
                    placeholder="Repeat your password"
                    passwordEye
                    useForm={register('confirm_password', {
                        required: 'This field is required',
                    })}
                    error={errors.confirm_password?.message}
                />
                <div className="submitTerms-block">
                    <div className="checkbox-container">
                        <input onChange={() => setActive(!active)} type="checkbox" required />
                        <CustomCheckbox width={25} active={active} />
                    </div>
                    <p className="submitTerms">
                        By submitting this form you agree to our <span>Terms and Conditions</span>
                    </p>
                </div>

                <Button text="sign up" loading={isSending} />
            </form>
        </div>
    )
}

export default SignUpFormTeam
