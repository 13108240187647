import { makeAutoObservable, observable } from 'mobx'

class NotificationsCountStore {
    notifications = 0
    refetchTrigger = false
    constructor() {
        makeAutoObservable(this, {
            notifications: observable,
            refetchTrigger: observable
        })
    }
    setCount  = (count:number) => {
        this.notifications = count
    }
    triggerCount = () => {
        this.refetchTrigger = !this.refetchTrigger
    }
}

export default new NotificationsCountStore()