import { user_role } from '../utils/user-type'
import { request } from './api'

export async function getServicesList(filters) {
    return request(`${user_role}/services/`, 'GET', null, null, filters)
}
export async function createService(data) {
    return request(`${user_role}/services/create/`, 'POST', data)
}
export async function getServiceDetail(id) {
    return request(`${user_role}/services/${id}/`, 'GET')
}
export async function deleteService(id) {
    return request(`${user_role}/services/delete/${id}/`, 'DELETE')
}
export async function updateService(id, data) {
    return request(`${user_role}/services/update/${id}/`, 'PUT', data)
}
export async function getTariffTypes() {
    return request(`categories/`, 'GET')
}
