import React from 'react';

function Arrow() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5725 9.71436L12.0011 14.2858L7.42969 9.71436" stroke="currentColor" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


    )
}

export default Arrow
