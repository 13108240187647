import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../components/button'
import Input from '../../components/input'
import PasswordInput from '../../components/sign-up-password-input'
import { signUp, signUpRoom } from '../../api/auth'
import { SignUpFormProps } from './interface'
import './styles.scss'
import ModalWrapper from '../../components/modal-wrapper'
import CustomCheckbox from '../../components/custom-checkbox'

type Props = SignUpFormProps

const SignUpForm: FC<Props> = ({ setLogin, setOkRegister, room }) => {
    const [isGoodPassword, setIsGoodPassword] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [active, setActive] = useState(false)
    const [toggleState, setToggleState] = useState(room ? 2 : 1)
    const toggleTab = (index: number) => {
        setToggleState(index)
    }

    const {
        register,
        handleSubmit,
        setError,
        resetField,
        reset,
        clearErrors,
        formState: { errors },
    } = useForm()
    async function registerUser(data: any) {
        if (!active) {
            setError('checkbox', {
                type: 'custom',
                message: 'You need to agree to Terms and Conditions to continue ',
            })

            return
        } else {
            let res
            setIsSending(true)
            try {
                toggleState === 2 ? (res = await signUpRoom(data)) : (res = await signUp(data))
                setLogin(true)
                setOkRegister(true)
                setShowModal(true)
                reset()
                return res
            } catch (error: any) {
                if (error.response.data.email?.length) {
                    setError('email', {
                        type: 'custom',
                        message: error.response.data.email[0],
                    })
                } else {
                    setError('name', {
                        type: 'custom',
                        message: 'Something went wrong. Try again later',
                    })
                }
                console.dir(error)
            } finally {
                setIsSending(false)
            }
        }
    }

    const onSubmit = async (data: any) => {
        if (!isGoodPassword) {
            setError('password', {
                type: 'custom_one',
                message: 'The password does not meet the requirements',
            })
        } else if (data.password !== data.confirm_password) {
            resetField('confirm_password')
            setError('password', {
                type: 'custom',
                message: "Passwords don't match",
            })
            setError('confirm_password', {
                type: 'custom',
                message: ' ',
            })
        } else {
            try {
                await registerUser(data)
            } catch (e: any) {
                throw new Error(e)
            }
        }
    }
    return (
        <>
            <ModalWrapper isShown={showModal} setIsShown={setShowModal}>
                <div className={'check-email'}>
                    <h3>Please check your email</h3>
                    <p>A verification link has been sent to your email account</p>
                </div>
            </ModalWrapper>
            <div className="signUp-container">
                {/*<Icon className={'arrow-back'} name="arrow-back-login" onClick={() => navigate(-1)} />*/}
                <div className="container">
                    <ul className="sign-up-tab">
                        {/* <li>
                            <button
                                className={toggleState === 1 ? 'active' : ''}
                                onClick={() => toggleTab(1)}
                            >
                                For Professional
                            </button>
                        </li> */}
                        <li>
                            <button
                                className={toggleState === 2 ? 'active' : ''}
                                onClick={() => toggleTab(2)}
                            >
                                For Treatment Room
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="mobile_info">
                    {toggleState === 1 ? (
                        <h2>Sign up as professional</h2>
                    ) : (
                        <h2>Sign Up and Create Listing</h2>
                    )}
                    <p>
                        Already have an account? <span onClick={() => setLogin(true)}>Log in</span>
                    </p>
                </div>
                <form
                    className={`signUp ${isSending && 'sending'}`}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Input
                        label="First name*"
                        placeholder="Enter your first name*"
                        useForm={register('name', {
                            required: 'This field is required',
                        })}
                        error={errors.name?.message}
                    />
                    <Input
                        label="Last name*"
                        placeholder="Enter your last name*"
                        useForm={register('surname', {
                            required: 'This field is required',
                        })}
                        error={errors.surname?.message}
                    />
                    <Input
                        label="E-mail*"
                        placeholder="Enter your e-mail address*"
                        useForm={register('email', {
                            required: 'This field is required',
                        })}
                        error={errors.email?.message}
                    />
                    <PasswordInput
                        label="Your password*"
                        placeholder="Enter your password*"
                        passwordEye
                        register={register}
                        setGoodPassword={setIsGoodPassword}
                        error={errors.password?.message}
                    />
                    <Input
                        label="Confirm password"
                        placeholder="Repeat your password"
                        passwordEye
                        useForm={register('confirm_password', {
                            required: 'This field is required',
                        })}
                        error={errors.confirm_password?.message}
                    />
                    <div className="submitTerms-block">
                        <div className="checkbox-container">
                            <input
                                onChange={() => {
                                    if (!active) {
                                        clearErrors('checkbox')
                                    }
                                    setActive(!active)
                                }}
                                type="checkbox"
                            />

                            <CustomCheckbox width={30} active={active} />
                            {!active && !!errors?.checkbox && (
                                <div className="check-box_required">
                                    {errors?.checkbox?.message}
                                </div>
                            )}
                        </div>
                        <p className="submitTerms">
                            By submitting this form you agree to our
                            <span>Terms and Conditions</span>
                        </p>
                    </div>
                    <Button text="sign up" loading={isSending} />
                </form>
            </div>
        </>
    )
}

export default SignUpForm
