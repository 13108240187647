import './styles.scss'
import Input from '../../components/input'
import { useForm } from 'react-hook-form'
import Button from '../../components/button'
import UploadInput from '../../components/upload-Input'
import Icon from '../../components/icon'
import { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { createDocument, getOnboardingDocsCategoryList } from '../../api/onboarding-documents'
import { showToast } from '../../utils/toast-function'
import { OnboardingDocs } from './interface'
import { useNavigate } from 'react-router-dom'
import { fileConverter } from '../../utils/file-converter-base64'

type Props = OnboardingDocs
const OnboardingProcessDocuments: FC<Props> = () => {
    const [documents, setDocuments] = useState<any>([])
    const [sending, setIsSending] = useState(false)

    const navigate = useNavigate()

    const { data } = useQuery('documentCat', getOnboardingDocsCategoryList)

    useEffect(() => {
        setDocuments(data)
    }, [!!data])

    const {
        register,
        handleSubmit,
        formState: { errors },
        unregister,
        watch,
        setValue,
    } = useForm()

    const onSubmit = async (data: any) => {
        setIsSending(true)
        const promises = data.array
            .filter((e: any) => !!e.document.length)
            .map((elem: any) =>
                fileConverter(elem.document[0]).then((res) => ({
                    ...elem,
                    document: res,
                }))
            )
        Promise.all(promises).then(async (results) => {
            try {
                await createDocument(results)
                showToast('success', 'Documents has been created successfully')
                localStorage.setItem('completed_onboarding', String(true))
                navigate('/')
            } catch (error: any) {
                showToast('danger', 'Something went wrong. Try again later')
                throw new Error()
            } finally {
                setIsSending(false)
            }
        })
    }

    const handleAddQualification = (id: number) => {
        setDocuments([...documents, { id }])
    }

    const handleDelete = (i: number) => {
        unregister(`array.${i}`)
        const newDocuments = [...documents]
        newDocuments.splice(i, 1)
        setDocuments(newDocuments)
    }

    return (
        <>
            <div className=" ">
                <h1>Please provide the scanned documents </h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={'document-form'}>
                        {data?.map((cat: any, i: number) => (
                            <div key={i} className="upload-document-input">
                                <div>
                                    {cat.title && (
                                        <h6>
                                            {i + 1}. {cat.title} *
                                        </h6>
                                    )}
                                    <UploadInput
                                        useForm={register(`array.${i}.document`, {
                                            required: 'This field is required',
                                            onChange: () => {
                                                setValue(`array.${i}.category`, cat.id)
                                            },
                                        })}
                                        index={i}
                                        fileTxt={
                                            !!watch(`array`)?.length &&
                                            !!watch(`array`)[i]?.document?.length &&
                                            watch(`array`)[i]?.document[0].name
                                        }
                                        explanation={`Attach the scanned copy of your ${
                                            cat.title ? cat.title : 'document'
                                        }`}
                                        error={
                                            !!errors?.array && errors?.array[i]?.document?.message
                                        }
                                        fileType={`image/jpeg, image/PNG${
                                            cat?.jpeg_only ? '' : ', application/pdf'
                                        }`}
                                    />
                                </div>
                                {cat.issue_expiry_dates && (
                                    <div className="date">
                                        <Input
                                            placeholder="Date of issue"
                                            label={'Date of issue'}
                                            type="date"
                                            error={
                                                !!errors?.array &&
                                                errors?.array[i]?.issue_date?.message
                                            }
                                            // name={'issue_date'}
                                            useForm={register(`array[${i}].issue_date`, {
                                                required: 'This field is required',
                                            })}
                                        />
                                        <Input
                                            placeholder="Date of expiry"
                                            label={'Date of expiry'}
                                            type="date"
                                            min={watch(`array[${i}].issue_date`)}
                                            // name={'expire_date'}
                                            error={
                                                !!errors?.array &&
                                                errors.array[i]?.expiry_date?.message
                                            }
                                            useForm={register(`array[${i}].expiry_date`, {
                                                required: 'This field is required',
                                            })}
                                        />
                                    </div>
                                )}
                                {cat.multiple_files &&
                                    documents?.map((field: any, j: number) => {
                                        if (j > 2) {
                                            return (
                                                <UploadInput
                                                    key={j}
                                                    clearClick={
                                                        j === documents.length - 1 &&
                                                        (() => handleDelete(j))
                                                    }
                                                    useForm={register(`array[${j}].document`, {
                                                        required: false,
                                                        onChange: () =>
                                                            setValue(
                                                                `array[${j}].category`,
                                                                cat.id
                                                            ),
                                                    })}
                                                    index={j}
                                                    style={{ paddingTop: '15px' }}
                                                    fileTxt={
                                                        !!watch(`array`)?.length &&
                                                        !!watch(`array`)[j]?.document?.length &&
                                                        watch(`array`)[j]?.document[0].name
                                                    }
                                                    // onchange={(
                                                    //     e: ChangeEvent<HTMLInputElement>
                                                    // ) => setValue(`[${j}].category`, cat.id)}
                                                    explanation={`Attach the scanned copy of your ${
                                                        cat.title ? cat.title : 'document'
                                                    }`}
                                                    error={
                                                        !!errors?.array &&
                                                        errors?.array[j]?.document?.message
                                                    }
                                                    fileType={`image/jpeg, image/PNG${
                                                        cat?.jpeg_only ? '' : ', application/pdf'
                                                    }`}
                                                />
                                            )
                                        }
                                    })}

                                {documents?.length <= 6 && cat.multiple_files && (
                                    <button
                                        className="add-more-btn"
                                        onClick={() => handleAddQualification(cat.id)}
                                        type={'button'}
                                    >
                                        <Icon name={'plus-violet'} />
                                        <span>add more</span>
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className={'onboarding-group-btn'}>
                        <Button text={'cancel'} outlined loading={sending} />
                        <Button text={'confirm'} loading={sending} />
                    </div>
                </form>
            </div>
        </>
    )
}
export default OnboardingProcessDocuments
