export const filterNames = (tNames) => {
    if (!tNames) {
        return []
    } else {
        return tNames?.map((treatment) => ({
            title: treatment?.subcategory?.title || treatment?.title || treatment?.name,
            id: treatment?.id,
        }))
    }
}

export const filterTariffs = (tNames) => {
    if (!tNames) {
        return []
    } else {
        return tNames?.map((type) => ({
            title: type.title,
            id: type.id,
        }))
    }
}

export const setClearPhotos = (data) => data.map((image) => ({ id: image.id }))
