import './styles.scss'
import { FC, useState } from 'react'
import { ModalReviews } from './intarface'
import RatingComponent from '../rating'
import Icon from '../icon'
import notFound from '../../assets/images/not-found-reviews.svg'
import CustomCheckbox from '../custom-checkbox'
import moment from 'moment'
import { Link } from 'react-router-dom'

type Props = ModalReviews

const ModalReviewsComp: FC<Props> = ({ userData, setFilter, filter, setGetStars }) => {
    const [activeBtn, setActiveBtn] = useState(false)
    const starsMap = ['1', '2', '3', '4', '5']
    const newFilter = filter.stars.split(',')

    function setStar(index: any) {
        if (newFilter[index] === 'null') {
            newFilter[index] = index + 1
        } else {
            newFilter[index] = 'null'
        }
        setFilter({
            ...filter,
            stars: newFilter.join(','),
        })
    }

    const [isChecked, setIsChecked] = useState(false)

    return (
        <div className="modalReviews">
            <div className="header">
                <h1>Reviews</h1>
                <button className="filter" onClick={() => setActiveBtn(!activeBtn)}>
                    <Icon name={'modal-filter-icon'} />
                    FILTER
                </button>
                {activeBtn ? (
                    <div className="filter-content">
                        {starsMap.map((star, i) => {
                            return (
                                <div
                                    className="filter-content__star"
                                    key={i}
                                    onClick={() => {
                                        setStar(i)
                                        setIsChecked(!isChecked)
                                    }}
                                >
                                    <div className="checkbox">
                                        <CustomCheckbox
                                            checked={newFilter[i] !== 'null'}
                                            width={24}
                                        />
                                    </div>
                                    <span>{star} Stars</span>
                                </div>
                            )
                        })}
                        <div
                            className="applyFilters"
                            onClick={() => setGetStars((prev: boolean) => !prev)}
                        >
                            Apply filters
                        </div>
                    </div>
                ) : null}
            </div>
            {userData.reviews.length !== 0 ? (
                <div className="card-container">
                    {userData.reviews.map((card: any) => (
                        <Link to={`/reviews/${card.id}`}>
                            <div key={card.id} className="card">
                                <div className="sectionOne">
                                    <div className="sectionImgName ">
                                        {card.room ? (
                                            ''
                                        ) : (
                                            <img
                                                style={{ width: '30px' }}
                                                src={card.professional?.photo}
                                                alt=""
                                            />
                                        )}
                                        {card.room?.title ||
                                            card.professional?.name +
                                                '' +
                                                card.professional?.surname}
                                    </div>
                                    <RatingComponent stars={card?.rating} width={17} />
                                </div>
                                <div className="sectionTwo">
                                    <p>{card.text}</p>
                                    <div className="sectionTwoText">
                                        <div className="sectionTwoText__left">
                                            <p> {moment(card?.creation_date).format('MM.DD.YY')}</p>

                                            <p>{card.replies_count} replies</p>
                                        </div>

                                        <p className="published">
                                            {card.published === true ? 'PUBLISHED' : 'OPEN'}{' '}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            ) : (
                <div className="background404">
                    <div>
                        <img src={notFound} alt="notFound" />
                    </div>
                    <p>No reviews yet</p>
                </div>
            )}
        </div>
    )
}

export default ModalReviewsComp
