import { makeAutoObservable, observable } from 'mobx'

class ChatStore {
    isShownChat = false

    constructor() {
        makeAutoObservable(this, {
            isShownChat: observable,
        })
    }
    setShowChat = (bool: boolean) => {
        this.isShownChat = bool
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ChatStore()
