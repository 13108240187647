import React from 'react'

function CalendarIcon() {
    return (
        <>
            <div className="icon">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.85547 7.42836H21.1412M5.14118 2.85693H18.8555C19.4617 2.85693 20.0431 3.09775 20.4717 3.5264C20.9004 3.95506 21.1412 4.53644 21.1412 5.14265V18.8569C21.1412 19.4631 20.9004 20.0445 20.4717 20.4732C20.0431 20.9018 19.4617 21.1426 18.8555 21.1426H5.14118C4.53497 21.1426 3.95359 20.9018 3.52494 20.4732C3.09628 20.0445 2.85547 19.4631 2.85547 18.8569V5.14265C2.85547 4.53644 3.09628 3.95506 3.52494 3.5264C3.95359 3.09775 4.53497 2.85693 5.14118 2.85693V2.85693Z"
                        stroke="currentColor"
                        strokeWidth="1.14286"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
            <div className="mobileIcon">
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.14258 5.57164H15.8569M3.85686 2.14307H14.1426C14.5972 2.14307 15.0333 2.32368 15.3548 2.64517C15.6763 2.96666 15.8569 3.4027 15.8569 3.85735V14.1431C15.8569 14.5977 15.6763 15.0338 15.3548 15.3553C15.0333 15.6767 14.5972 15.8574 14.1426 15.8574H3.85686C3.40221 15.8574 2.96617 15.6767 2.64468 15.3553C2.32319 15.0338 2.14258 14.5977 2.14258 14.1431V3.85735C2.14258 3.4027 2.32319 2.96666 2.64468 2.64517C2.96617 2.32368 3.40221 2.14307 3.85686 2.14307V2.14307Z"
                        stroke="currentColor"
                        strokeWidth="1.14286"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        </>
    )
}

export default CalendarIcon
