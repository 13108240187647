import { user_role } from '../../utils/user-type'
import React from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import ModalStore from '../../store/blocked-time-modal'
import { Tooltip } from 'react-tooltip'
import Icon from '../icon'
import moment from 'moment'
import { formatMinutes } from '../../utils/timeToHours'
const CalendarDayEvent = (props: any) => {
    const navigate = useNavigate()
    const { event } = props
    const { object_data } = event
    const isBlockedTime = event.object_type === 'blocked_time'

    const { setShown } = ModalStore

    const start_time = `${event.start.getHours()}:${event.start.getMinutes()}${
        event.start.getMinutes() ? '' : '0'
    }`
    const end_time = `${event.end.getHours()}:${event.end.getMinutes()}${
        event.end.getMinutes() ? '' : '0'
    }`

    return (
        <>
            <div
                className={`calendar-event calendar-day-event ${
                    isBlockedTime && 'event-blocked-time'
                }`}
                id={`${event.object_type}${event.object_data?.id}`}
                onClick={() =>
                    isBlockedTime
                        ? setShown(true, object_data)
                        : navigate(`/bookings/${object_data.id}`)
                }
            >
                <div className="left-border" />
                <p className={'event-time'}>
                    {start_time} - {end_time}
                </p>
                {!isBlockedTime && (
                    <p className="event-client">
                        {event?.object_data?.client?.name} {event?.object_data?.client?.surname}
                    </p>
                )}
                {user_role === 'room' && event.object_type === 'booking' && (
                    <p className="event-room">{object_data.room_tariffs?.name}</p>
                )}
                {isBlockedTime && (
                    <>
                        {!!event.object_data.tariff && (
                            <p style={{ fontSize: 14, color: 'var(--palette-dark-violet-300)' }}>
                                {event.object_data.tariff}
                            </p>
                        )}
                        <p className="event-description">{object_data.description}</p>
                    </>
                )}
                {user_role === 'prof' && event.object_type === 'booking' && (
                    <p className="event-treatments">
                        {event?.object_data?.treatments?.map(
                            (treatment: any, index: number, arr: any) => {
                                return (
                                    treatment.treatment_name + (index < arr.length - 1 ? ' + ' : '')
                                )
                            }
                        )}
                    </p>
                )}
            </div>
            {!isBlockedTime && (
                <Tooltip
                    anchorId={`${event.object_type}${event.object_data?.id}`}
                    className={'event-tooltip'}
                >
                    <div>
                        <div className="tooltip_title">
                            <div className="client-profile">
                                <img
                                    className="clientsPhoto"
                                    style={{ width: '30px' }}
                                    src={
                                        object_data?.client?.photo ||
                                        require('../../assets/images/default_user.png')
                                    }
                                    alt={'client-avatar'}
                                />
                                <p>
                                    {object_data?.client?.name} {object_data?.client?.surname}
                                </p>
                            </div>

                            <div className={`tooltip-badge ${object_data.status}`}>
                                {object_data.status}
                            </div>
                        </div>
                        <div className="tooltip-info_container">
                            {!!object_data.room_tariffs && <p>{object_data.room_tariffs?.name}</p>}
                            <div className="tooltip-info info_date">
                                <Icon name={'calendar'} width={20} height={20} />
                                <p>{moment(event.start).format('ddd DD MMM')}</p>
                            </div>
                            <div className="tooltip-info info_time">
                                <Icon name={'clock'} width={20} height={20} />
                                <p>{moment(event.start).format('HH:mm')}</p>
                            </div>
                            <div className="tooltip-info info_duration">
                                <Icon name={'hourglass'} width={20} height={20} />
                                <p>{formatMinutes(object_data.total_duration)}</p>
                            </div>
                            {user_role === 'prof' && (
                                <div className="tooltip-info info_address">
                                    <Icon name={'location_active'} width={20} height={20} />
                                    <p>{object_data?.address || 'No address'}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="tooltip-price">
                        <p>Total</p>
                        <p>£{object_data.total_price || 0}</p>
                    </div>
                </Tooltip>
            )}
        </>
    )
}

export default CalendarDayEvent
