import './styles.scss'
import Input from '../../../components/input'
import Button from '../../../components/button'
import { useQuery } from 'react-query'
import {
    createDocument,
    editDocument,
    getOnboardingDocsCategoryList,
} from '../../../api/onboarding-documents'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { showToast } from '../../../utils/toast-function'
import ModalWrapper from '../../../components/modal-wrapper'
import DocsModalStore from '../../../store/prof-docs-modals'
import { observer } from 'mobx-react-lite'

const AddDocument: FC = () => {
    const { setShownCreateModal, isShownCreateModal, currentDocument } = DocsModalStore
    const [isLoading, setLoading] = useState(false)
    const { data } = useQuery(['documentCat'], getOnboardingDocsCategoryList)
    const [isOpen, setIsOpen] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        watch,
        trigger,
        setValue,
        formState: { errors },
    } = useForm<any>()

    //dropdown function
    const toggling = () => {
        setIsOpen(!isOpen)
    }

    const handleReset = () => {
        setShownCreateModal(false)
        reset({})
    }
    const onSubmit = async (data: any) => {
        setLoading(true)
        const formData = new FormData()
        formData.append('file_name', data.file_name)
        if (!currentDocument) {
            formData.append('category', data.category.id)
            formData.append('document', data.document[0])
            formData.append('file_name', data.file_name)
            if (data.category.issue_expiry_dates) {
                formData.append('issue_date', data.issue_date)
                formData.append('expiry_date', data.expiry_date)
            }
        } else {
            if (data?.document?.length) {
                formData.append('document', data.document[0])
                if (data.category.issue_expiry_dates) {
                    formData.append('issue_date', data.issue_date)
                    formData.append('expiry_date', data.expiry_date)
                }
            }
        }
        try {
            currentDocument
                ? await editDocument(currentDocument.id, formData)
                : await createDocument(formData)
            showToast('success', 'Document has been created successfully')
            handleReset()
        } catch (e: any) {
            if (e?.response?.data?.category) {
                showToast('danger', e?.response?.data?.category)
            } else {
                showToast('danger', 'Something went wrong. Try again later')
            }
            throw new Error(e)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (currentDocument) {
            reset({ ...currentDocument, document: null, expiry_date: null, issue_date: null })
        } else {
            reset({})
        }
    }, [currentDocument])
    return (
        <ModalWrapper
            isShown={isShownCreateModal}
            setIsShown={handleReset}
            className="add-doc-modal"
        >
            {isShownCreateModal && (
                <div className={'add-modal-container'} onClick={() => isOpen && setIsOpen(false)}>
                    <h5 className={'modal-header'} style={{ paddingRight: 30 }}>
                        {currentDocument?.id ? 'Edit Document' : 'Add New Document'}
                    </h5>
                    <form className="add-docs-form" onSubmit={handleSubmit(onSubmit)}>
                        {currentDocument ? (
                            <div>
                                <span className={'document-category-label'}>
                                    Category of the file:
                                </span>
                                <p className={'document_title'}>{currentDocument.category.title}</p>
                            </div>
                        ) : (
                            <div className="select-box">
                                <Input
                                    label={'Choose category of the file*'}
                                    readOnly
                                    id={'category.title'}
                                    placeholder={'Choose category from the list'}
                                    className="input-select-cat"
                                    icon={'arrow-dropdown'}
                                    onClick={toggling}
                                    useForm={register('category.title', {
                                        required: 'This field is required.',
                                    })}
                                    error={errors?.category?.title?.message}
                                />
                                {isOpen && (
                                    <div className="select-option-container">
                                        {data?.map((cat: any) => (
                                            <div
                                                className="select-option py-2"
                                                key={cat.id}
                                                onClick={async () => {
                                                    setValue('category', cat)
                                                    await trigger('category.title')
                                                }}
                                            >
                                                {cat.title}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="upload-doc-wrapper">
                            <div className="d-flex align-items-center gap-10">
                                <h4 className="upload-doc-label">
                                    {currentDocument && !watch('document')?.length
                                        ? 'Uploaded document:'
                                        : 'Upload Document*'}
                                </h4>
                                {currentDocument?.category?.issue_expiry_dates &&
                                    !watch('document')?.length && (
                                        <label
                                            form={'document'}
                                            className="refresh-input primary-button"
                                        >
                                            REFRESH
                                            <input
                                                type={'file'}
                                                {...register('document')}
                                                hidden
                                                id={'document'}
                                                accept={`image/jpeg, image/PNG${
                                                    watch('category')?.jpeg_only
                                                        ? ''
                                                        : ', application/pdf'
                                                }`}
                                            />
                                        </label>
                                    )}
                            </div>
                            {currentDocument && !watch('document')?.length ? (
                                <p className={'document_title'}>
                                    {currentDocument.document.split('/').at(-1)}
                                </p>
                            ) : (
                                <>
                                    <label form={'document'} className="doc-upload">
                                        <span className="upload-placeholder">
                                            {watch('document')?.length
                                                ? watch('document')[0]?.name
                                                : ''}
                                        </span>
                                        {!watch('document')?.length && (
                                            <span className={'upload-txt'}>upload</span>
                                        )}
                                        <input
                                            type={'file'}
                                            {...register('document')}
                                            hidden
                                            id={'document'}
                                            accept={`image/jpeg, image/PNG${
                                                watch('category')?.jpeg_only
                                                    ? ''
                                                    : ', application/pdf'
                                            }`}
                                        />
                                    </label>
                                    <span>{errors.document?.message}</span>
                                </>
                            )}
                        </div>
                        {watch('category')?.issue_expiry_dates && !!watch('document') && (
                            <div className={'expiration-date'}>
                                <Input
                                    placeholder="Date of issue"
                                    useForm={register('issue_date', {
                                        required: 'This field is required',
                                    })}
                                    id={'issue_date'}
                                    label={'Date of issue'}
                                    type="date"
                                    error={errors?.issue_date?.message}
                                />
                                <Input
                                    placeholder="Date of expiry"
                                    id={'expiry_date'}
                                    min={watch('issue_date')}
                                    useForm={register('expiry_date', {
                                        required: 'This field is required',
                                    })}
                                    label={'Date of expiry'}
                                    type="date"
                                    error={errors?.expiry_date?.message}
                                />
                            </div>
                        )}
                        <Input
                            label={'Enter the document’s official name* '}
                            placeholder={'Enter document’s official name'}
                            maxLength={50}
                            id={'file_name'}
                            useForm={register('file_name', {
                                required: 'This field is required',
                            })}
                            error={errors.file_name?.message}
                        />

                        <div className="docs-btn">
                            <Button
                                text={'CANCEL'}
                                outlined
                                type={'button'}
                                onClick={handleReset}
                                loading={isLoading}
                            />
                            <Button
                                text={'SAVE'}
                                disabled={!currentDocument && !watch('document')?.length}
                                loading={isLoading}
                            />
                        </div>
                    </form>
                </div>
            )}
        </ModalWrapper>
    )
}

export default observer(AddDocument)
