import React from 'react';

function AccountIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2.85693C12.9093 2.85693 13.7814 3.21816 14.4244 3.86114C15.0673 4.50412 15.4286 5.37619 15.4286 6.28551V8.57122C15.4286 9.48053 15.0673 10.3526 14.4244 10.9956C13.7814 11.6386 12.9093 11.9998 12 11.9998C11.0907 11.9998 10.2186 11.6386 9.57563 10.9956C8.93265 10.3526 8.57143 9.48053 8.57143 8.57122V6.28551C8.57143 5.37619 8.93265 4.50412 9.57563 3.86114C10.2186 3.21816 11.0907 2.85693 12 2.85693V2.85693ZM20 18.8569V18.0249C20 14.3826 15.7874 11.9998 12 11.9998C8.21257 11.9998 4 14.3826 4 18.0249V18.8569C4 19.16 4.12041 19.4507 4.33474 19.6651C4.54906 19.8794 4.83975 19.9998 5.14286 19.9998H18.8571C19.1602 19.9998 19.4509 19.8794 19.6653 19.6651C19.8796 19.4507 20 19.16 20 18.8569Z" stroke="currentColor" strokeWidth="1.14286" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


    )
}

export default AccountIcon
