import React, { FC, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import DropDown from '../../components/drop-down'
import Loader from '../../components/loader/loader'
import { getAllCategories, getTreatmentAvailable } from '../../api/api'
import { updateService, getServiceDetail, createService } from '../../api/treatments-and-tariffs'
import './styles.scss'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../components/button'
import { filterNames } from '../../utils/for-add-work'
import Icon from '../../components/icon'
import Input from '../../components/input'
import { duration } from '../../utils/duration-time'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { showToast } from '../../utils/toast-function'
import TextareaCustom from '../../components/Textarea-custom/text-area-custom'
import Tooltip from '../../components/tooltip'

const durationData = duration

const AddTreatment: FC = () => {
    const [percents, setPercents] = useState(0)
    const [active, setActive] = useState(false)
    const [currentCategory, setCurrentCategory] = useState(null) as any
    const [sending, setIsSending] = useState(false)

    const { treatmentId } = useParams()
    const navigate = useNavigate()
    const date_now = moment().format('YYYY-MM-DD')
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
        getValues,
        reset,
        trigger,
        watch,
        clearErrors,
    } = useForm()
    register('category')
    register('subcategory')
    register('duration')
    const { data: categories, isLoading: categoryLoading } = useQuery(['categories'], () =>
        getAllCategories()
    )
    const { data: treatmentDetail, isLoading } = useQuery(
        ['treatment_detail', treatmentId],
        () => getServiceDetail(treatmentId),
        { enabled: !!treatmentId, cacheTime: 0 }
    )

    const { data: treatmentNames, isLoading: treatmentNameLoading } = useQuery(
        ['treatment_names', currentCategory],
        () => getTreatmentAvailable({ category: currentCategory }),

        { enabled: !!currentCategory || !!treatmentDetail }
    )
    const subcategories = useMemo(() => {
        if (treatmentNames && currentCategory === treatmentDetail?.subcategory?.category?.id) {
            return [...treatmentNames, treatmentDetail?.subcategory]
        } else {
            return treatmentNames
        }
    }, [currentCategory, !!treatmentNames])
    async function onSubmit(data: any) {
        data.category = currentCategory
        if (!data.subcategory && !data.name) {
            setError('name', {
                type: 'custom',
                message: 'Select the name or enter the new name.',
            })
            return null
        }
        data.deal.start_date = date_now

        setIsSending(true)

        if (data?.name?.length) {
            data.subcategory = null
        } else {
            data.name = null
        }
        if (!data.deal) {
            data.deal = null
        }

        if (treatmentDetail?.deal && !active) {
            data.deal.active = false
        }
        if (active) {
            data.deal.active = active
        }

        try {
            treatmentId ? await updateService(treatmentId, data) : await createService(data)
            setTimeout(() => {
                showToast('success', 'Saved successfully!')
            }, 50)
            navigate(-1)
        } catch (e: any) {
            console.dir(e)
            if (e.response?.data) {
                Object.keys(e.response?.data).forEach((item: any) => {
                    return setError(item, {
                        message: e.response?.data[item][0],
                    })
                })
            }
            if (e.response?.data?.category) {
                showToast('danger', 'Field category is required!')
            }
            if (e.response?.data?.duration) {
                showToast('danger', 'Field duration is required!')
            }
        } finally {
            setIsSending(false)
        }
    }
    function getPercents(e: any) {
        const price = Number(getValues('price'))
        const discount = Number(e.target.value)
        const percent = Math.floor(100 - (discount / price) * 100)
        if (percent >= 0) {
            setPercents(percent)
            clearErrors('deal.discount_price')
        } else {
            setPercents(0)
            setError('deal.discount_price', {
                type: 'custom',
                message: 'Discount price should be less than original price.',
            })
        }
    }

    useEffect(() => {
        if (treatmentDetail) {
            reset(treatmentDetail)
            setCurrentCategory(treatmentDetail.category)
            setValue('subcategory', treatmentDetail.subcategory?.id)

            if (treatmentDetail.deal) {
                const end = moment(treatmentDetail.deal.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
                setValue('deal.end_date', end)
                setPercents(
                    Math.floor(
                        100 - (treatmentDetail.deal.discount_price / treatmentDetail.price) * 100
                    )
                )
            }
            setActive(!!treatmentDetail.deal?.active)
            trigger()
        }
    }, [treatmentId, treatmentDetail?.id])

    return (
        <>
            {(categoryLoading && treatmentNameLoading) || isLoading ? (
                <Loader />
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section className="add-treatment container">
                        <h2>{treatmentId ? 'Edit' : 'Add'} Treatment</h2>
                        <div className="add-treatment_template">
                            <div className="filters">
                                <div className="category">
                                    <h4>Treatment Category*</h4>
                                    <span>Choose the Category from the list </span>
                                    <DropDown
                                        forAddWork
                                        categories={filterNames(categories)}
                                        setCategory={(filter: number | undefined) =>
                                            setCurrentCategory(filter)
                                        }
                                        defaultCategory={
                                            filterNames(categories).find((tr: any) => {
                                                return tr.id === treatmentDetail?.category
                                            })?.title
                                        }
                                        zIndex={3}
                                        defaultName={
                                            <span style={{ color: 'grey' }}>
                                                Choose the category
                                            </span>
                                        }
                                    />
                                </div>
                            </div>
                            <div className="treatment-name-container">
                                <h4 className="treatment-name">Treatment Name*</h4>
                                <div className="filters">
                                    <div
                                        className={`category ${
                                            watch('name')?.length && 'disabled'
                                        }`}
                                    >
                                        <span>Choose a name from subcategory from the list </span>
                                        <DropDown
                                            forAddWork
                                            categories={filterNames(subcategories)}
                                            setCategory={(filter: number | undefined) =>
                                                setValue('subcategory', filter)
                                            }
                                            defaultCategory={
                                                treatmentDetail?.subcategory?.title || (
                                                    <span style={{ color: 'grey' }}>
                                                        Choose subcategory
                                                    </span>
                                                )
                                            }
                                            zIndex={2}
                                        />
                                    </div>
                                    <div className="or">or</div>
                                    <div className="category">
                                        <span>Type a new one</span>
                                        <Input
                                            placeholder="Type a name"
                                            useForm={register('name')}
                                            error={errors.name?.message}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sub-filters">
                                <div className="sub-category">
                                    <h4>Duration*</h4>
                                    <DropDown
                                        forAddWork
                                        categories={durationData}
                                        setCategory={(filter: number | undefined) =>
                                            setValue('duration', filter)
                                        }
                                        defaultCategory={
                                            treatmentDetail
                                                ? durationData?.find(
                                                      (tr: any) =>
                                                          tr.id === treatmentDetail.duration
                                                  )?.title
                                                : ''
                                        }
                                        zIndex={1}
                                        defaultName={
                                            <span style={{ color: 'grey' }}>Choose duration</span>
                                        }
                                    />
                                </div>
                                <div className="sub-category">
                                    <h4>Price*</h4>
                                    <div className="price-input-container">
                                        <div className="price-input">
                                            <div className="price-input__icon">
                                                <Icon name="input-price" />
                                            </div>
                                        </div>
                                        <Input
                                            placeholder="Select price"
                                            type="number"
                                            useForm={register('price', {
                                                required: 'This field is required.',
                                            })}
                                            error={errors.price?.message}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="add-treatment container">
                        <div className="add-treatment_template">
                            <h4>Promo action</h4>
                            <div className="promo-switch">
                                <Icon
                                    onClick={() => setActive(!active)}
                                    name={!active ? 'Switch-off' : 'Switch-on'}
                                />
                                <span>Publish as a Promo action</span>
                            </div>
                            {active ? (
                                <div className="promo-hidden">
                                    <div className="filters">
                                        <div className="category">
                                            <Input
                                                placeholder="Enter a name of deal"
                                                useForm={register('deal.deal_title', {
                                                    required: 'This field is required.',
                                                })}
                                                label={'Deal’s name*'}
                                                error={errors.deal?.deal_title?.message}
                                            />
                                        </div>
                                    </div>
                                    <div className="description">
                                        <h4>Description*</h4>
                                        <TextareaCustom
                                            defaultValue={treatmentDetail?.deal?.deal_description}
                                            error={errors?.deal?.deal_description?.message}
                                            required={'Description is required'}
                                            register={register}
                                            register_key={'deal.deal_description'}
                                        />
                                    </div>
                                    <div className="promo-inputs">
                                        <div>
                                            <h4>Special price*</h4>
                                            <div className="promo-inputs__left">
                                                <div>
                                                    <p className="promo-inputs__label">New Price</p>
                                                    <div className="price-input-container">
                                                        <div className="price-input">
                                                            <div className="price-input__icon">
                                                                <Icon name="input-price" />
                                                            </div>
                                                        </div>

                                                        <Input
                                                            width="180px"
                                                            type={'number'}
                                                            min={1}
                                                            useForm={register(
                                                                'deal.discount_price',
                                                                {
                                                                    required:
                                                                        'This field is required',
                                                                    onChange: (e) => getPercents(e),
                                                                }
                                                            )}
                                                            error={
                                                                errors.deal?.discount_price?.message
                                                            }
                                                            placeholder="New price"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="line"> = </div>
                                                <div>
                                                    <p className="promo-inputs__label">Percent</p>
                                                    <div className="promo-percent">{percents}%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="tooltip">
                                                <h4>Promo Action Dates*</h4>
                                                <Tooltip
                                                    text={
                                                        'When creating the promo action, the start day will be automatically set to the current date.'
                                                    }
                                                >
                                                    <Icon name={'get-info'} width={19} />
                                                </Tooltip>
                                            </div>
                                            <div className="promo-inputs__rigth ">
                                                <div>
                                                    <p className="promo-inputs__label">End date</p>
                                                    <Input
                                                        width="100%"
                                                        type="date"
                                                        min={watch(date_now)}
                                                        defaultValue={date_now}
                                                        useForm={register('deal.end_date', {
                                                            required: 'This field is required',
                                                        })}
                                                        error={errors.deal?.end_date?.message}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="buttons">
                            <Button
                                text={'cancel'}
                                type="button"
                                outlined
                                loading={sending}
                                onClick={() => navigate(-1)}
                            />
                            <Button text={treatmentId ? 'SAVE' : 'ADD'} loading={sending} />
                        </div>
                    </section>
                </form>
            )}
        </>
    )
}

export default AddTreatment
