import './styles.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import React, { FunctionComponent } from 'react'
import { ReviewReply } from './interface'
import defaultAvatar from '../../assets/images/default_user.png'
import moment from 'moment'

type Props = ReviewReply

const ReplyCard: FunctionComponent<Props> = ({ data }) => {
    return (
        <div className={`reply-card ${data?.client && 'client-reply'}`}>
            <div className="reply-card_line" />
            <div>
                <div className="reply-title">
                    {data?.client && (
                        <img
                            className="reply-img"
                            src={data?.client?.photo ? data?.client?.photo : defaultAvatar}
                            alt="client-photo3"
                        />
                    )}
                    <h4 className={data?.client ? '' : 'my-reply-title'}>
                        {data?.client
                            ? `${data?.client?.name} ${data?.client?.surname}`
                            : 'My reply'}
                    </h4>
                </div>
                <div className="reply-padding">
                    <span className="reply-text">{data?.message}</span>
                    <div className="reply-footer">
                        <span>{moment.utc(data?.creation_date, 'DD/MM/YYYY hh:mm').fromNow()}</span>
                        <span className="status">{data?.client ? 'open' : ''}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReplyCard
