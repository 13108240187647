export const formatMinutes = (timeInMinutes) => {
    if(!timeInMinutes){
        return '0 mins'
    }
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;

    if (hours === 1 && !minutes) return hours + ' hour';
    if (hours === 1 && minutes) return hours + ' hour ' + minutes + ' mins';
    if (hours > 1 && !minutes) return hours + ' hours';
    if (hours > 1 && minutes) return hours + ' hours ' + minutes + ' mins';
    return minutes + ' mins';

}