import './styles.scss'

import React, { FC, useState } from 'react'

import { IconProps } from './interface'
import { importAll } from '../../utils/ImportIcon'

type Props = IconProps

const images = importAll(
    // @ts-ignore
    require.context('../../assets/icons', false, /\.(png|jpe?g|svg)$/));

const Icon: FC<Props> = (props) => {
    const { name, height, width, hover, style, className, onClick, onMouseCustom } = props

    const [over, setOver] = useState(false)

    const handleSetOver = () =>{
        setOver(true)
    }
    if (!images[name + '.svg' || '_active.svg']) return <span>none*</span>

    return (
        <div
            className={`icon ${className}`}
            onMouseOver={ onMouseCustom || handleSetOver}
            onMouseOut={() => setOver(false)}
            style={style}
            onClick={onClick}
        >
            <img
                width={width}
                height={height}
                src={
                    images[over && hover ? name + '_active.svg' : name + '.svg']
                }
                alt={name}
            />
        </div>
    )
}

export default Icon
