import { FC, useState } from 'react'
import PhotoInput from '../../components/work-photo-input'
import { PhotoPickerProps } from './interface'
import './styles.scss'

type Props = PhotoPickerProps

const PhotoPicker: FC<Props> = ({ photos = [], dataPhotos, setDataPhotos }) => {
    const [images, setImages] = useState<any[]>(photos)
    const mediaQuery = window.matchMedia('(max-width: 950px)').matches
    return (
        <div className="photo_template">
            {Array.from(Array(mediaQuery ? images.length + 1 : 5)).map(
                (item, index) => {
                    return (
                        <PhotoInput
                            accept="image/png, image/gif, image/jpeg"
                            key={index}
                            index={index}
                            images={images}
                            setImages={setImages}
                            workData={dataPhotos}
                            setWorkData={setDataPhotos}
                        />
                    )
                }
            )}
        </div>
    )
}
export default PhotoPicker
