import { makeAutoObservable, observable } from 'mobx'

class WorkingHoursSelect {
    workingHoursSelect = ''

    constructor() {
        makeAutoObservable(this, {
            workingHoursSelect: observable,
        })
    }

    setCurrentSelect = (selectKey: string) => {
        this.workingHoursSelect = selectKey
    }
}

export default new WorkingHoursSelect()
