import { ChangeEvent, FC, useRef, useState } from 'react'
import Icon from '../icon'
import { PhotoInputProps } from './interface'
import './styles.scss'
import ViewAll from '../view-all-btn'
import Button from '../button'
import ModalWrapper from '../modal-wrapper'

type Props = PhotoInputProps

const PhotoInput: FC<Props> = ({ images = [], setImages, index, workData, setWorkData, accept }) => {
    const hiddenFileInput = useRef() as any
    const [isShown, setShow] = useState(false)

    function handleClick() {
        hiddenFileInput.current.click()
    }

    const image = images[index]?.photo

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const fileUploaded = event.target.files && event.target.files[0]

        if (fileUploaded) {
            const imgURL = URL.createObjectURL(fileUploaded)
            images[index] ? (images[index].photo = imgURL) : images.push({ photo: imgURL })
            setImages([...images])

            const reader = new FileReader()
            reader.readAsDataURL(fileUploaded)
            reader.onloadend = () => {
                workData.photos.length > index
                    ? (workData.photos[index].photo = reader.result)
                    : workData.photos.push({ photo: reader.result })
                setWorkData({ ...workData })
            }
        }
    }

    function deleteImage() {
        images.splice(index, 1)
        workData.photos.splice(index, 1)
        setWorkData({ ...workData })
        setImages([...images])
        setShow(false)
    }

    const mediaQuery = window.matchMedia('(max-width: 950px)').matches
    return (
        <>
            <div style={{ width: '100%' }} className="photo-input-wrapper">
                <input
                    ref={hiddenFileInput}
                    type="file"
                    accept={accept}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                />
                {images.length > index ? (
                    <div className="work-photo">
                        <img className="work-photo_big" src={image} alt="workPhotoBig" />
                        <img className="work-photo_main" src={image} alt="workPhoto" />
                        <div className="change-modal">
                            <Icon
                                name="delete-icon"
                                width={mediaQuery ? 40 : 30}
                                height={mediaQuery ? 40 : 30}
                                onClick={setShow}
                            />
                            <Icon
                                name="edit-icon"
                                width={mediaQuery ? 40 : 30}
                                height={mediaQuery ? 40 : 30}
                                onClick={handleClick}
                            />
                        </div>
                    </div>
                ) : mediaQuery && index ? (
                    <div className={`more-photos ${index === images.length && 'active'}`}>
                        <ViewAll onClick={handleClick}>Add more photos</ViewAll>
                    </div>
                ) : (
                    <div
                        className={`photo-input ${index === images.length && 'active'} ${
                            index === 0 && 'first-mobile'
                        }`}
                        onClick={handleClick}
                    >
                        <Icon name="plus-for-works-photo" width={30} height={30} />
                        <p>Click to add a photo</p>
                    </div>
                )}
            </div>
            <ModalWrapper isShown={isShown} setIsShown={setShow}>
                <div className={'delete-photo-confirmation'}>
                    <h4>Are you sure?</h4>
                    <p>
                        Do you want to delete this photo? This process cannot be undone if you
                        delete
                    </p>
                    <div className={'delete-photo-action'}>
                        <Button type={'button'} text={'CANCEL'} onClick={() => setShow(false)} />
                        <Button
                            text={'delete'}
                            outlined
                            type={'button'}
                            className={'delete-photo-button'}
                            onClick={deleteImage}
                        />
                    </div>
                </div>
            </ModalWrapper>
        </>
    )
}

export default PhotoInput
