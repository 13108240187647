import './styles.scss'
import { FC, useState } from 'react'
import Icon from './../icon/index'
import { ViewAllProps } from './interface'

type Props = ViewAllProps

const ViewAll: FC<Props> = ({ children, onClick }) => {
    const [over, setOver] = useState(false)
    return (
        <div
            className="viewAll"
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
            onClick={onClick}
        >
            <p>{children}</p>
            <Icon name={`arrow-viewAll${over ? '_active' : ''}`} />
        </div>
    )
}

export default ViewAll
