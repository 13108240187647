import './styles.scss'
import { FunctionComponent } from 'react'

import { ButtonProps } from './interface'
import LoaderDots from '../loader-dots'

type Props = ButtonProps

const Button: FunctionComponent<Props> = ({
    text,
    danger,
    disabled,
    loading,
    type,
    className,
    outlined = false,
    ...props
}) => {
    return (
        <button
            type={type}
            disabled={disabled || loading}
            className={`primary-button ${outlined && 'primary-button_outlined'} ${
                loading && 'button_loading'
            } ${danger && 'danger_button'} ${className}`}
            {...props}
        >
            {text}
            {loading ? <LoaderDots purple={outlined} /> : null}
        </button>
    )
}

export default Button
