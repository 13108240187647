import { FC, useEffect, useState } from 'react'
import { passwordFormats } from '../../utils/password-formats'
import Icon from '../icon'
import Input from '../input'
import { PasswordInputProps } from './interface'
import './styles.scss'

type Props = PasswordInputProps

const PasswordInput: FC<Props> = ({
    forChangePassword = false,
    setGoodPassword,
    initValue = false,
    register,
    ...props
}) => {
    const [inputValue, setInputValue] = useState('')
    const [isFocused, setFocused] = useState(false)

    const formats = passwordFormats(inputValue)

    const allOk = formats.every((format) => format.isOkFormat === true)

    useEffect(() => {
        setGoodPassword(allOk)
    }, [allOk])

    useEffect(() => {
        setInputValue('')
    }, [initValue])

    return (
        <div className="passwordComponent" style={{ width: '100%' }}>
            <Input
                value={inputValue}
                onFocus={() => {
                    setFocused(true)
                }}
                useForm={register(
                    forChangePassword ? 'new_password' : 'password',
                    {
                        required: 'This field is required',
                        onChange: (e: any) => setInputValue(e.target.value),
                        onBlur: () => setFocused(false),
                    }
                )}
                {...props}
            />
            <div className={`formats ${isFocused && 'focused'}`}>
                {formats.map((format, i) => (
                    <div key={i + 1} className="formatElement">
                        <Icon
                            name={format.isOkFormat ? 'check' : 'not-check'}
                        />
                        <span>{format.title}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PasswordInput
