import './styles.scss'
import { FC } from 'react'
import defaultImg from '../../assets/images/default_user.png'
import { ClientModalUp } from './intarface'
import ModalUpBtn from '../modal-btn'

type Props = ClientModalUp

const ClientListModalUp: FC<Props> = ({ userData, toggle, modalState }) => {
    return (
        <div className="modalUpWrapper">
            <div className="nameImg">
                {!userData.photo ? (
                    <img className="nameImg__userPhoto" src={defaultImg} alt="defaultImg" />
                ) : (
                    <img className="nameImg__userPhoto" src={userData.photo} alt="defaultImg" />
                )}
                <h3>{`${userData.name}  ${userData.surname}`} </h3>
            </div>
            <div className="buttons">
                <ModalUpBtn
                    active={modalState === 'BOOKINGS'}
                    click={() => toggle('BOOKINGS')}
                    children={'Bookings'}
                    image={'bookings'}
                />
                <ModalUpBtn
                    active={modalState === 'REVIEWS'}
                    click={() => toggle('REVIEWS')}
                    children={'Reviews'}
                    image={'reviews'}
                />
            </div>
        </div>
    )
}

export default ClientListModalUp
