import React, { FunctionComponent, useState } from 'react'
import './styles.scss'
import Icon from '../../components/icon'

import { useNavigate } from 'react-router-dom'
import { propsNav } from './interface'
import ScrollToTop from 'react-scroll-up'
import { observer } from 'mobx-react-lite'
import Sidebar from '../../store/sidebar'
import ModalWrapper from '../../components/modal-wrapper'
import LogOutModal from '../../components/log-out-modal'
import LogoutModalStore from '../../store/logout-modal-trigger'
import SideBarLinks from '../../components/sidebar-links'
import Cookie from '../../components/cookie'
type Props = propsNav

const Header: FunctionComponent<Props> = () => {
    const [hamburger, setHamburger] = useState(false)
    const [cookie, setCookie] = useState(true)
    const toggleHamburger = () => {
        setHamburger(!hamburger)
    }
    const styles = {
        position: 'fixed',
        bottom: 70,
        right: 20,
        cursor: 'pointer',
        transitionDuration: '0.2s',
        transitionTimingFunction: 'linear',
        transitionDelay: '0s',
        zIndex: 10000,
    }

    const navigate = useNavigate()
    const isCookie = !!JSON.parse(localStorage.getItem('cookie') as string)
    return (
        <>
            <ModalWrapper
                isShown={LogoutModalStore.isShowModal}
                setIsShown={() => LogoutModalStore.setShowModal(false)}
            >
                <LogOutModal
                    setShow={() => {
                        LogoutModalStore.setShowModal(false)
                    }}
                />
            </ModalWrapper>

            <ScrollToTop showUnder={600} style={styles}>
                <Icon name="go-up" className="scroll-top" />
            </ScrollToTop>
            <div className="burger" onClick={toggleHamburger}>
                <Icon name="burger-icon" />
            </div>
            <div className="sidebar-for-sticky">
                <div
                    className={
                        Sidebar.expander ? 'sidebar-container expander' : 'sidebar-container'
                    }
                >
                    <div className="logo-block logo-block__expand">
                        <Icon name={'logo-business'} onClick={() => navigate('/')} />
                        <Icon
                            name={'arrow-back'}
                            className="rollup"
                            onClick={() => Sidebar.setExpander(false)}
                            hover
                        />
                        <Icon
                            name={'arrow-reverse'}
                            onClick={() => Sidebar.setExpander(true)}
                            className={Sidebar.expander ? 'rollup-reverse-none' : 'rollup-reverse'}
                            hover
                        />
                        <Icon
                            name={'close'}
                            width={24}
                            height={24}
                            className="close-sidebar"
                            onClick={toggleHamburger}
                        />
                    </div>
                    <SideBarLinks />
                </div>
            </div>

            {hamburger && (
                <div
                    className={hamburger ? 'backdrop' : ''}
                    onClick={(e: any) => {
                        if (e.target.className === 'backdrop') {
                            toggleHamburger()
                        }
                    }}
                >
                    <div className="sidebar-container-mobile expander">
                        <div className="logo-block logo-block__expand">
                            <Icon name={'logo-business'} onClick={() => navigate('/')} />
                            <Icon
                                name={'rollup'}
                                className="rollup"
                                onClick={() => Sidebar.setExpander(false)}
                            />
                            <Icon
                                name={'close'}
                                width={24}
                                height={24}
                                className="close-sidebar"
                                onClick={toggleHamburger}
                            />
                        </div>
                        <SideBarLinks toggleHamburger={toggleHamburger} />
                    </div>
                </div>
            )}
            {!isCookie && <Cookie isShown={cookie} setIsShown={setCookie} />}
        </>
    )
}

export default observer(Header)
