import { makeAutoObservable, observable } from 'mobx'

class ProfDocsModalStore {
    isShownCreateModal = false
    isShownDeleteModal = false
    isShownPublishModal = false
    currentDocument = null as any

    constructor() {
        makeAutoObservable(this, {
            isShownCreateModal: observable,
            isShownDeleteModal: observable,
            isShownPublishModal: observable,
            currentDocument: observable,
        })
    }

    setShownCreateModal = (isShown: boolean, document?: any | null | undefined) => {
        this.isShownCreateModal = isShown
        if (document && isShown) {
            this.currentDocument = document
        } else {
            this.currentDocument = null
        }
    }
    setShownDeleteModal = (isShown: boolean, document?: any | null | undefined) => {
        this.isShownDeleteModal = isShown
        if (document && isShown) {
            this.currentDocument = document
        } else {
            this.currentDocument = null
        }
    }
    setShownPublishModal = (isShown: boolean, document?: any | null | undefined) => {
        this.isShownPublishModal = isShown
        if (document && isShown) {
            this.currentDocument = document
        } else {
            this.currentDocument = null
        }
    }
}

export default new ProfDocsModalStore()
