import React, { FC } from 'react'
import Icon from '../icon'
import Button from '../button'
import { iProfDocAccordionElement } from './interface'
import DocsModalStore from '../../store/prof-docs-modals'
import './styles.scss'

type Props = iProfDocAccordionElement
const ProfDocAccordionElement: FC<Props> = ({ activeAccordion, setActiveAccordion, document }) => {
    const { setShownDeleteModal, setShownPublishModal, setShownCreateModal } = DocsModalStore

    return (
        <div
            className={`doc-accordion-element ${
                activeAccordion !== document.id && 'cursor-pointer closed'
            }`}
            onClick={() => {
                activeAccordion !== document.id && setActiveAccordion(document.id)
            }}
        >
            <div className="accordion-title">
                <p>{document.file_name || document.category?.title || 'Additional Document'}</p>
                <Icon
                    className={`flex-shrink-0 ${
                        activeAccordion === document.id ? 'icon-rotate' : ''
                    }`}
                    width={24}
                    height={24}
                    name={`chevron-down${activeAccordion === document.id ? '-black' : '-grey'}`}
                />
            </div>
            <div
                className={`accordion-content ${
                    activeAccordion === document.id ? 'active-accordion' : ''
                }`}
            >
                <div className="element">
                    <span>Category</span>
                    <p>{document?.category?.title || 'Additional Document'}</p>
                </div>
                <div className="element">
                    <span>Last modified</span>
                    <p>{document.last_modified}</p>
                </div>
                <div className="element">
                    <span>Published</span>
                    <Icon
                        name={document.published ? 'check-mark-black' : 'minus'}
                        className={'cursor-default'}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {document.category?.is_publishable ? (
                        <Button
                            text={document.published ? 'unpublish' : 'publish'}
                            onClick={() => setShownPublishModal(true, document)}
                        />
                    ) : (
                        <div className={'d-flex align-items-center'} style={{ gap: 5 }}>
                            {document.category.issue_expiry_dates && (
                                <>
                                    <p className="insurance-date">
                                        {document?.issue_date || 'unset'}
                                    </p>{' '}
                                    <p className="insurance-date">{` - `}</p>{' '}
                                    <p className="insurance-date">
                                        {document?.expiry_date || 'unset'}
                                    </p>
                                </>
                            )}
                        </div>
                    )}
                    <div className="accordion-action">
                        <div className="icon-wrapper">
                            <Icon
                                name="edit-black"
                                width={25}
                                height={25}
                                onClick={() => setShownCreateModal(true, document)}
                            />
                        </div>
                        {!document.category.issue_expiry_dates && (
                            <div className="icon-wrapper">
                                <Icon
                                    name={'delete-black'}
                                    width={25}
                                    height={25}
                                    onClick={() => setShownDeleteModal(true, document)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfDocAccordionElement
