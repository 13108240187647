import { useEffect, useState } from 'react'
import './styles.scss'
import BasicInfoForm from '../../modules/account-basic-info'
import ChangePasswordComponent from '../../modules/change-password'
import OnboardingDocs from '../../modules/account-onboarding-docs'
import NoApprovePage from '../../components/no-approve-page'

const Account = () => {
    const [toggleState, setToggleState] = useState(0)
    const isApproved = JSON.parse(localStorage.getItem('approved') as string)
    const toggleTab = (index: number) => {
        setToggleState(index)
    }
    useEffect(() => window.scrollTo(0, 0), [])
    const tabContent = [
        {
            title: 'Basic information',
            component: <BasicInfoForm />,
        },
        {
            title: 'Onboarding documents',
            component: <OnboardingDocs />,
        },
        {
            title: 'Change password',
            component: <ChangePasswordComponent />,
        },
    ]
    return isApproved ? (
        <>
            <div className="container">
                <ul className="account-tab">
                    {tabContent.map((tab, index) => (
                        <li key={index}>
                            <button
                                className={toggleState === index ? 'active' : ''}
                                onClick={() => toggleTab(index)}
                            >
                                {tab.title}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            {tabContent.map((tab, index) => (
                <div
                    key={index}
                    className={`tab-content ${toggleState === index && 'active-content'}`}
                >
                    {tab.component}
                </div>
            ))}
        </>
    ) : (
        <NoApprovePage />
    )
}
export default Account
