import { TableColumn } from 'react-data-table-component'
import Icon from '../../components/icon'
import TreatmentCrud from '../../components/treatment-crud'

import { formatMinutes } from '../../utils/timeToHours'

const columns: TableColumn<any>[] = [
    {
        name: 'TREATMENT',
        sortable: true,
        selector: (row) => row.subcategory?.title || row.name,
    },
    {
        name: 'DURATION',
        maxWidth: '150px',

        selector: (row) => formatMinutes(row.duration),
    },
    {
        name: 'PRICE',
        center: true,
        maxWidth: '100px',
        sortable: true,
        selector: (row) => `£${row.price}`,
    },
    {
        name: 'PROMO',
        maxWidth: '90px',
        center: true,
        cell: (row) => (
            <Icon style={{ cursor: 'default' }} name={row.deal ? 'check-mark-black' : 'minus'} />
        ),
    },
    {
        name: 'ACTION',
        maxWidth: '120px',
        center: true,
        cell: (row) => {
            return <TreatmentCrud row={row} />
        },
    },
]

export default columns
