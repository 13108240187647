import { makeAutoObservable, observable } from 'mobx'

class Trigger {
    toggleTrigger = true

    constructor() {
        makeAutoObservable(this, {
            toggleTrigger: observable,
        })
    }

    setToggle() {
        this.toggleTrigger = !this.toggleTrigger
    }
}

export default new Trigger()
