import './styles.scss'

import React, { FunctionComponent } from 'react'

import { TooltipProps } from './interface'

type Props = TooltipProps

const Tooltip: FunctionComponent<Props> = ({ text, children }) => {
    return (
        <div className="tooltip">
            {children}
            <span className="tooltiptext">{text}</span>
        </div>
    )
}

export default Tooltip
