import axios from 'axios'
import { user_role } from '../utils/user-type'

export const request = async (url, method, payload, formData, params) => {
    const token = localStorage.getItem('token')

    const api = process.env['REACT_APP_BASE_URL']

    try {
        const res = await axios({
            url: `${api}${url}`,
            headers: {
                ...(formData && {
                    'Content-Type': 'multipart/form-data',
                }),
                ...(token && {
                    Authorization: `JWT ${token}`,
                }),
            },
            method,
            data: payload,
            params: params,
        })

        if (res.data && res.data.access) {
            localStorage.setItem('token', res.data.access)
            localStorage.setItem('userID', res.data.user_id)
            localStorage.setItem('userType', res.data.user_type)
            localStorage.setItem('approved', res.data.approved)
            localStorage.setItem('completed_onboarding', res.data?.completed)
        }
        return res.data
    } catch (error) {
        const href = window.location.href
        if (error.response.status === 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('userID')
            localStorage.removeItem('userType')
            localStorage.removeItem('approved')
            if (!href.includes('/login') && !href.includes('/auth')) {
                // window.location.href = '/404'
            }
        }
        if (error.response.status === 404) {
            // window.location.href = '/'
        }
        console.dir(error)
        throw error
    }
}

//-------------------CLIENTS-------------------
export async function getClientsList(filters) {
    return request(`${user_role}/clients/`, 'GET', null, null, filters)
}
export async function getClient(id, filter) {
    return request(`${user_role}/clients/${id}/`, 'GET', null, null, filter)
}
export async function getBookingDetails(id) {
    return request(`${user_role}/clients/bookings/${id}/`, 'GET')
}

export async function declineBooking(id) {
    return request(`bookings/decline/${id}/`, 'GET')
}
//-------------------REVIEWS-------------------
export async function getReviewList(filters) {
    return request(`${user_role}/reviews/`, 'GET', null, null, filters)
}
export async function getReviewDetails(id) {
    return request(`${user_role}/reviews/${id}/`, 'GET')
}
export async function createReply(data) {
    return request(`${user_role}_review/reply/`, 'POST', data)
}

//-------------------ROOM MEMBERS-------------------
export async function getTeamList(filters) {
    return request(`room/team-members/`, 'GET', null, null, filters)
}
export async function updateStatus(id, data) {
    return request(`room/team-members/update-status/${id}/`, 'PUT', data)
}
export async function sendInvitation(data) {
    return request(`room/team-members/invite/`, 'POST', data)
}
export async function getRoomMemberProfile(id) {
    return request(`room/team-members/${id}/`, 'GET')
}

//-------------------UTILS-------------------
export async function getLanguages() {
    return request(`languages/`, 'GET')
}
export async function getTreatmentAvailable(filters) {
    return request(`available_subcategories/`, 'GET', null, null, filters)
}
export async function getAllCategories() {
    return request(`categories/`, 'GET')
}

//-------------------LOGOUT-------------------
export function LogOut() {
    localStorage.removeItem('token')
    localStorage.removeItem('userID')
    localStorage.removeItem('userType')
    localStorage.removeItem('approved')
    document.location.href = '/login'
}
