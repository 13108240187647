export const options = {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        // legend: {
        //   position: 'top',
        //   labels: {
        //     usePointStyle: true,
        //     padding: 25,
        //     boxWidth: 10
        //   }
        // },
        hover: {
            mode: 'label'
        },
        tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 10,
            shadowOffsetY: 10,
            shadowBlur: 8,
            shadowColor: 'black',
            backgroundColor: '#fff',
            titleFontColor: '#000',
            bodyFontColor: '#000'
        },

        scales: {
            xAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: true
                    },
                    gridLines: {
                        display: true,
                        color: 'transparent',
                        zeroLineColor: 'grey'
                    },
                    ticks: {
                        display: true,
                        fontColor: 'black'
                    }
                }
            ],
            yAxes: [
                {
                    display: true,
                    scaleLabel: {
                        display: false
                    },
                    ticks: {
                        step: 100,
                        min: 0,
                        display: false,
                    },
                    gridLines: {
                        display: true,
                        color: '#E5E9F2',
                        zeroLineColor: 'grey'
                    }
                }
            ]
        },
        legend: {
            position: 'top',
            align: 'start',
            labels: {
                usePointStyle: true,
                padding: 25,
                boxWidth: 9
            }
        }
    }


 export const dataChart = {
        labels: [],
        datasets: [
            {
                data: [],
                label: 'Gross Sales',
                borderColor: '#F4C038',
                lineTension: 0.4,
                pointStyle: 'circle',
                fill:false,
                backgroundColor: '#F4C038',
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 10,
                pointBorderColor: '#F4C038',
                pointHoverBorderColor: '#F4C038',
                pointHoverBackgroundColor: '#F4C038',
                pointShadowOffsetX: 10,
                pointShadowOffsetY: 10,
                pointShadowBlur: 5,
                pointShadowColor: '#F4C038'
            },
            {
                data: [],
                label: 'Net Sales',
                borderColor: '#582E90',
                lineTension: 0.4,
                pointStyle: 'circle',
                backgroundColor: '#582E90',
                fill: false,
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 10,
                pointBorderColor: '#582E90',
                pointHoverBorderColor: '#582E90',
                pointHoverBackgroundColor: '#582E90',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: '#582E90'
            },
            {
                data: [],
                label: 'Discounts',
                borderColor: '#F26022',
                lineTension: 0.4,
                pointStyle: 'circle',
                backgroundColor: '#F26022',
                fill: false,
                pointRadius: 3,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 10,
                pointBorderColor: '#F26022',
                pointHoverBorderColor: '#F26022',
                pointHoverBackgroundColor: '#F26022',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: '#F26022'
            }
        ]
    }

export const plugins = [
    {
        beforeInit(chart) {
            chart.legend.afterFit = function () {
                this.height += 20
            }
        }
    }
]

