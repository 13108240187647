import './styles.scss'
import { FC, useState } from 'react'
import Icon from '../../../components/icon'
import StatusDropDown from '../../../components/status-dropdown'

interface ITeam {
    data?: any
}
type Props = ITeam
const TeamAccordion: FC<Props> = ({ data }) => {
    const [activeAccordion, setActiveAccordion] = useState(0)
    const handleClick = (id: number) => {
        setActiveAccordion(activeAccordion === id ? 0 : id)
    }
    return (
        <>
            <div className="">
                {data?.results?.map((elem: any) => (
                    <div
                        className="team-accordion-element cursor-pointer"
                        key={elem.id}
                        onClick={() => handleClick(elem.id)}
                    >
                        <div className="accordion-title">
                            <p>
                                {elem.photo !== null ? (
                                    <img
                                        className="clientsPhoto"
                                        alt={'client-avatar'}
                                        src={elem.photo}
                                    />
                                ) : (
                                    <img
                                        className="defaultClientsPhoto"
                                        src={require('../../../assets/images/default_user.png')}
                                        alt="img"
                                    />
                                )}{' '}
                                {elem.name} {elem.surname}
                            </p>
                            <Icon
                                className={activeAccordion === elem.id ? 'icon-rotate' : ''}
                                name={`chevron-down${
                                    activeAccordion === elem.id ? '-black' : '-grey'
                                }`}
                            />
                        </div>
                        <div
                            className={`accordion-content ${
                                activeAccordion === elem.id ? 'active-accordion' : ''
                            }`}
                        >
                            <div className="team-info ">
                                <StatusDropDown data={elem} />

                                <div className="">
                                    <div className="element">
                                        <span
                                            className="clientsInfo__header"
                                            style={{
                                                color: '#BBBBBB',
                                            }}
                                        >
                                            Position
                                        </span>
                                        <p>{elem.position || '-'}</p>
                                    </div>
                                    <div className="element">
                                        <span
                                            className="clientsInfo__header"
                                            style={{
                                                color: '#BBBBBB',
                                            }}
                                        >
                                            {' '}
                                            Phone
                                        </span>
                                        <p>{elem.phone || '-'}</p>
                                    </div>
                                    <div className="element">
                                        <span
                                            className="clientsInfo__header"
                                            style={{
                                                color: '#BBBBBB',
                                            }}
                                        >
                                            {' '}
                                            Email
                                        </span>
                                        <p>{elem.user?.email}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default TeamAccordion
