import Account from '../pages/account'
import CalendarPage from '../pages/calendar'
import DeleteAccount from '../pages/delete-account-confirmation'
import ClientsListPage from '../pages/clients-list'
import MyWorksPage from '../pages/my-works'
import AddWork from '../pages/add-work'
import MyReviews from '../pages/reviews'
import MyReviewDetails from '../pages/review-details'
import TreatmentsListPage from '../pages/treatments-list'
import AddTreatment from '../pages/add-treatment'
import Notifications from '../pages/notifications'
import NotificationDetails from '../pages/notification-detail'
import RoomTariffsListPage from '../pages/tariffs-list'
import AddRoomType from '../pages/add-room-tariff'
import RoomAccount from '../pages/treatment-room/account'
import Team from '../pages/treatment-room/team'
import BookingDetails from '../pages/booking-detail'
import DashboardPage from '../pages/main'
import WorkingHoursPage from '../pages/working-hours'

export const routing = {
    main: {
        path: '/',
        element: <DashboardPage />,
        protected: true,
        role: 'all',
    },
    account: {
        path: '/account',
        element: <Account />,
        protected: true,
        role: 'prof',
    },
    calendar: {
        path: '/calendar',
        element: <CalendarPage />,
        protected: true,
        role: 'all',
    },
    workingHours: {
        path: '/calendar/working-hours',
        element: <WorkingHoursPage />,
        protected: true,
        role: 'all',
    },
    clientsList: {
        path: '/clients-list',
        element: <ClientsListPage />,
        protected: true,
        role: 'all',
    },
    bookingsDetails: {
        path: '/bookings/:booking_id',
        element: <BookingDetails />,
        protected: true,
        role: 'all',
    },

    treatmentsList: {
        path: '/treatments',
        element: <TreatmentsListPage />,
        protected: false,
        role: 'prof',
    },
    tariffsList: {
        path: '/tariffs',
        element: <RoomTariffsListPage />,
        protected: true,
        role: 'room',
    },
    addTreatment: {
        path: '/add-treatment',
        element: <AddTreatment />,
        protected: true,
        role: 'prof',
    },
    addTariffs: {
        path: '/add-tariff',
        element: <AddRoomType />,
        protected: true,
        role: 'room',
    },
    editTreatment: {
        path: '/edit_treatment/:treatmentId/',
        element: <AddTreatment />,
        protected: true,
        role: 'prof',
    },
    editTariff: {
        path: '/edit_tariff/:tariffId',
        element: <AddRoomType />,
        protected: true,
        role: 'all',
    },
    deleteAccount: {
        path: '/account/delete',
        element: <DeleteAccount />,
        protected: true,
        role: 'all',
    },
    myWorks: {
        path: '/my-works',
        element: <MyWorksPage />,
        protected: true,
        role: 'prof',
    },
    addWork: {
        path: '/add-work',
        element: <AddWork />,
        protected: true,
        role: 'prof',
    },
    editWork: {
        path: '/edit-work/:id',
        element: <AddWork />,
        protected: true,
        role: 'prof',
    },
    review: {
        path: '/reviews',
        element: <MyReviews />,
        protected: true,
        role: 'all',
    },
    reviewDetails: {
        path: '/reviews/:id',
        element: <MyReviewDetails />,
        protected: true,
        role: 'all',
    },

    notifications: {
        path: '/notifications',
        element: <Notifications />,
        protected: true,
        role: 'all',
    },
    notificationsDetails: {
        path: '/notifications/:id',
        element: <NotificationDetails />,
        protected: true,
        role: 'all',
    },
    roomAccount: {
        path: '/room/account',
        element: <RoomAccount />,
        protected: true,
        role: 'room',
    },
    team: {
        path: '/team',
        element: <Team />,
        protected: true,
        role: 'room',
    },
}
