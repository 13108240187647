import { user_role } from '../utils/user-type'
import { request } from './api'

export async function getDashboardMain(date_filters) {
    if (!user_role) return {}
    return request(`dashboard-main/${user_role}/`, 'GET', null, null, date_filters)
}
export async function getDashboardActiveBookings() {
    if (!user_role) return {}
    return request(`active-bookings/${user_role}/`, 'GET')
}
export async function getDashboardSalesSummary(date_filters) {
    if (!user_role) return {}
    return request(`sales-summary/${user_role}/`, 'GET', null, null, date_filters)
}
export async function getDashboardSalesByService(date_filters) {
    if (!user_role) return {}
    return request(`sales-by-service/${user_role}/`, 'GET', null, null, date_filters)
}
export async function getDashboardSalesByClient(date_filters) {
    if (!user_role) return {}
    return request(`sales-by-client/${user_role}/`, 'GET', null, null, date_filters)
}